import { BoxesDependencies, BoxesInterface } from '.';
import { $, formatNumber } from '../../helpers';

import { AnalyticsInterface, EventName } from '../Analytics';
import { IChestPrizesResponse, Prize } from '../API';
import { CouponsInterface } from '../Coupons';
import { GamedataInterface } from '../GameData';
import { Modal, ModalsInterface } from '../Modal';
import { UIInterface } from '../UI';
import { UserInterface } from '../User';

export class Boxes implements BoxesInterface {
	private static singleton: Boxes;
	private initialData: GamedataInterface['initialData'];
	private analytics: AnalyticsInterface;
	private modal: ModalsInterface;
	private user: UserInterface;
	private ui: UIInterface;
	private coupons: CouponsInterface;

	list: IChestPrizesResponse;
	selectedBoxes: Prize[];

	inject(deps: BoxesDependencies) {
		this.initialData = deps.gameData.initialData;
		this.analytics = deps.analytics;
		this.user = deps.user;
		this.modal = deps.modal;
		this.ui = deps.ui;
		this.coupons = deps.coupons;
	}

	public static getInstance(): Boxes {
		if (!Boxes.singleton) Boxes.singleton = new Boxes();

		return Boxes.singleton;
	}

	async build() {
		const it = this;

		const wrapper = $('.filter._boxes .filter__dropdown-list') as HTMLElement;
		wrapper.innerHTML = '';

		wrapper.append(it.getFilterButtonHTML('Все категории', 'Все категории'));

		it.list.prizeCategories.forEach((category) => {
			wrapper.append(it.getFilterButtonHTML(category.categoryName, category.categoryName));
		});

		it.filter(it.list.selectedCategory);

		$('.filter._boxes .js-filter-btn span').textContent = it.list.selectedCategory;
		it.buildList();
	}

	private getFilterButtonHTML(filter: string, title: string): HTMLButtonElement {
		const button = document.createElement('button');
		button.classList.add('js-filter-item');
		button.dataset.filter = filter;
		button.textContent = title;
		return button;
	}

	buildList() {
		const it = this;

		const boxesList = $('.boxes .boxes-list');
		boxesList.innerHTML = '';

		it.selectedBoxes.forEach((box) => {
			boxesList.append(it._getBoxHTML(box));
		});
	}

	private _getBoxHTML(box: Prize): HTMLDivElement {
		const it = this;

		const isEnough = it.user.status.receivedChestsCount >= box.price;
		const card = document.createElement('div');

		card.classList.add('box-card');
		!isEnough && card.classList.add('_locked');
		card.dataset.category = '';

		let price = '';

		for (let index = 1; index <= box.price; index++) {
			price += `<div class="${index > it.user.status.receivedChestsCount ? '_empty' : ''}"></div>`;
		}

		card.innerHTML = `
			<div class="box-card__head">
				<div>
					<h3>${box.sellerTitle}</h3>
					<div class="box-card__list">${price}</div>
				</div>
				<button class="js-box-price-btn btn" ${!isEnough && 'disabled'} data-id="${box.id}">${formatNumber(
			Math.floor(box.amount)
		)} ₽</button>
			</div>
			<div class="box-card__bottom">
				<div class="box-card__descr">${box.sellerSubtitle}</div>
				<div class="box-card__treshold">От ${formatNumber(Math.floor(box.threshold))} ₽</div>
			</div>
		`;

		return card;
	}

	filter(filter: string) {
		const it = this;

		// if (target.classList.contains('_active')) return;

		// [...$$('.js-boxes-category')]?.forEach((btn) => btn.classList.remove('_active'));
		// target.classList.add('_active');

		let filteredCoupons: Prize[] = [];
		if (filter === 'Все категории') {
			it.list.prizeCategories.forEach((category) => {
				filteredCoupons = [...filteredCoupons, ...category.prizes].sort((a, b) => a.amount - b.amount);
			});
			$('.filter._boxes .js-filter-btn span').textContent = 'Все категории';
		} else {
			filteredCoupons = it.list.prizeCategories
				.find((category) => category.categoryName === filter)
				.prizes.sort((a, b) => a.amount - b.amount);
			$('.filter._boxes .js-filter-btn span').textContent = filter;
		}

		it.selectedBoxes = filteredCoupons;

		$('.js-boxes-sort-btn').classList.remove('_reversed');
		it.buildList();
		it.ui.toggleDropdown($('.filter._boxes .js-filter-btn') as HTMLElement, 'close');
	}

	sort() {
		const it = this;

		if ($('.js-boxes-sort-btn').classList.contains('_reversed')) {
			it.selectedBoxes.sort((a, b) => a.amount - b.amount);
			$('.js-boxes-sort-btn').classList.remove('_reversed');
		} else {
			it.selectedBoxes.sort((a, b) => b.amount - a.amount);
			$('.js-boxes-sort-btn').classList.add('_reversed');
		}
		it.buildList();
	}

	async takeBox(boxId: number) {
		const it = this;

		it.user.currentPrize = it.selectedBoxes.find((item) => item.id === boxId);

		it.toggleSupercouponModal();
	}

	toggleSupercouponModal() {
		const it = this;

		if (it.modal.current === Modal.SUPERCOUPON) {
			it.modal.hide();
			return;
		}

		it.analytics.track(EventName.Page_Aliland_SuperboxCoupons_SelectCoupon);

		const coupon = it.user.currentPrize;
		const couponHTML = it.coupons.getHTML(coupon);

		($('.supercoupon .popup__logo-img') as HTMLElement).style.backgroundImage = `url('${coupon.imageUrl}')`;
		$('.supercoupon .popup__heading').textContent = `Купон от ${coupon.sellerTitle}`;
		$('.supercoupon .popup__text').textContent = coupon.title;
		$('.supercoupon .js-supercoupon-list').innerHTML = '';
		$('.supercoupon .js-supercoupon-list').append(couponHTML.cloneNode(true));

		($('.supercoupon-remind .popup__logo-img') as HTMLElement).style.backgroundImage = `url('${coupon.imageUrl}')`;
		$('.supercoupon-remind .js-supercoupon-list').innerHTML = '';
		$('.supercoupon-remind .js-supercoupon-list').append(couponHTML.cloneNode(true));

		$('.supercoupon-submit .js-supercoupon-list').innerHTML = '';
		$('.supercoupon-submit .js-supercoupon-list').append(couponHTML.cloneNode(true));

		it.modal.show(Modal.SUPERCOUPON);

		it.analytics.track(EventName.Page_Aliland_BrandPopup_Exposure, 'expose', {
			exp_page: 'aliland_dice',
			exp_page_area: 'brand_popup',
			exp_type: 'brand_popup',
			exp_attribute: JSON.stringify({
				type: 'supercoupon',
				brandname: coupon.sellerTitle,
				sellerSeq: coupon.sellerId,
			}),
			exp_condition: 'game_flow',
			'spm-cnt': 'a2g2l.aliland.dice.brand_popup',
		});
	}

	toggleBoxesModal() {
		const it = this;

		if (it.modal.current === Modal.BOX) {
			it.modal.hide(Modal.BOX);
			return;
		}

		it.analytics.track(EventName.Page_Aliland_Map_SuperboxCell_Click);

		it.modal.show(Modal.BOX);

		it.analytics.track(EventName.Page_Aliland_SuperboxPopup_Exposure, 'expose', {
			exp_page: 'aliland_map',
			exp_page_area: 'superbox_popup',
			exp_type: 'superbox_popup',
			exp_condition: 'out_of_game_flow',
			'spm-cnt': 'a2g2l.aliland.map.superbox_popup',
		});
	}

	toggleBoxesGainModal() {
		const it = this;

		if (it.modal.current === Modal.BOX_GAIN) {
			it.modal.hide(Modal.BOX_GAIN);
			return;
		}

		it.setUserBoxesCounter();
		it.modal.show(Modal.BOX_GAIN);

		it.analytics.track(EventName.Page_Aliland_SuperboxPopup_Exposure, 'expose', {
			exp_page: 'aliland_dice',
			exp_page_area: 'superbox_popup',
			exp_type: 'superbox_popup',
			exp_attribute: JSON.stringify({
				superbox_cnt: it.user.status.receivedChestsCount.toString(),
			}),
			exp_condition: 'game_flow',
			'spm-cnt': 'a2g2l.aliland.dice.superbox_popup',
		});
	}

	setUserBoxesCounter() {
		const it = this;

		$('.box-gain__counter b').textContent = it.user.status.receivedChestsCount.toString();
		$('.js-boxes-count').textContent = it.user.status.receivedChestsCount.toString();
		it.ui.setUserPrizes();
	}

	goToStore(target: HTMLElement) {
		const it = this;

		const couponId = target.dataset.id;
		const coupon = it.user.prizes.coupons.find((item) => item.id === +couponId);

		// it.analytics.track(EventName.Page_Aliland_CouponList_GotoShop_Click, 'click', {
		// 	ae_page_type: 'aliland_couponlist',
		// 	ae_button_type: 'gotoshop',
		// 	ae_object_value: JSON.stringify({
		// 		type: 'tir',
		// 		brandname: coupon.sellerTitle,
		// 		sellerSeq: coupon.sellerId,
		// 		action: coupon.link,
		// 	}),
		// 	ae_click_behavior: 'out_of_game_flow',
		// 	'spm-cnt': 'a2g2l.aliland.couponlist.gotoshop',
		// });

		it.initialData.dataProvider.goLink({ link: target.dataset.href });
	}

	async grab() {
		const it = this;

		const coupon = it.user.currentPrize;

		it.analytics.track(EventName.Page_Aliland_BrandPopup_TakeCoupon, 'click', {
			ae_page_type: 'aliland_dice',
			ae_page_area: 'brand_popup',
			ae_button_type: 'take_coupon',
			ae_object_value: JSON.stringify({
				type: 'supercoupon',
				brandname: coupon.sellerTitle,
				sellerSeq: coupon.sellerId,
			}),
			ae_click_behavior: 'game_flow',
			'spm-cnt': 'a2g2l.aliland.dice.brand_popup_take_coupon',
		});

		($('.js-supercoupon-submit-store') as HTMLElement).dataset.href = coupon.link;

		const isAssigned = await it.user.takePrize(coupon.id);
		if (!isAssigned) {
			it.modal.show(Modal.ERROR_GENERAL);
			return;
		}

		it.setUserBoxesCounter();
		it.toggleSupercouponSubmit(coupon);
	}

	leave() {
		const it = this;

		// await it.user.dismissPrize();
		it.modal.hide(Modal.SUPERCOUPON_REMIND);
	}

	toggleSupercouponSubmit(coupon?: Prize) {
		const it = this;

		if (it.modal.current === Modal.SUPERCOUPON_SUBMIT) {
			it.modal.hide(Modal.SUPERCOUPON_SUBMIT);
			return;
		}

		it.modal.show(Modal.SUPERCOUPON_SUBMIT);

		it.analytics.track(EventName.Page_Aliland_CongratulationPopup_Exposure, 'expose', {
			exp_page: 'aliland_dice',
			exp_page_area: 'congratulation_popup',
			exp_type: 'congratulation_popup',
			exp_attribute: JSON.stringify({
				type: 'supercoupon',
				brandname: coupon.sellerTitle,
				sellerSeq: coupon.sellerId,
			}),
			exp_condition: 'game_flow',
			'spm-cnt': 'a2g2l.aliland.dice.congratulation_popup',
		});
	}
}
