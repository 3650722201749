// @ts-ignore
import molnia from '../../../img/monuments/molnia.png';
// @ts-ignore
// import gasman from '../../../img/monuments/gasman.png';
// @ts-ignore
import newBalance from '../../../img/monuments/new_balance.png';
// @ts-ignore
// import anker from '../../../img/monuments/anker.png';
// @ts-ignore
// import deko from '../../../img/monuments/deko.png';
// @ts-ignore
import lego from '../../../img/monuments/lego.png';
// @ts-ignore
// import oneplus from '../../../img/monuments/oneplus.png';
// @ts-ignore
import primark from '../../../img/monuments/primark.png';
// @ts-ignore
import puma from '../../../img/monuments/puma.png';
// @ts-ignore
// import roborock from '../../../img/monuments/roborock.png';
// @ts-ignore
import smartlife from '../../../img/monuments/smartlife.png';
// @ts-ignore
import worx from '../../../img/monuments/worx.png';
// @ts-ignore
import zubr from '../../../img/monuments/zubr.png';

export const monumentsArr = [
	{
		position: { x: 3, y: 3 },
		link: {
			id: 1,
			title: 'Molnia',
			description: '',
			link: '',
			monumentImageUrl: molnia,
			imageUrl: '',
			contentImages: [],
		},
	},
	{
		position: { x: 3, y: 5 },
		link: {
			id: 2,
			title: 'Lego',
			description: '',
			link: '',
			monumentImageUrl: lego,
			imageUrl: '',
			contentImages: [],
		},
	},
	// {
	// 	position: { x: 2, y: 8 },
	// 	link: {
	// 		id: 3,
	// 		title: 'Oneplus',
	// 		description: '',
	// 		link: '',
	// 		monumentImageUrl: oneplus,
	// 		imageUrl: '',
	// 		contentImages: [],
	// 	},
	// },
	// {
	// 	position: { x: 2, y: 10 },
	// 	link: {
	// 		id: 4,
	// 		title: 'Deko',
	// 		description: '',
	// 		link: '',
	// 		monumentImageUrl: deko,
	// 		imageUrl: '',
	// 		contentImages: [],
	// 	},
	// },
	{
		position: { x: 3, y: 12 },
		link: {
			id: 5,
			title: 'Smart Life',
			description: '',
			link: '',
			monumentImageUrl: smartlife,
			imageUrl: '',
			contentImages: [],
		},
	},
	// {
	// 	position: { x: 5, y: 14 },
	// 	link: {
	// 		id: 6,
	// 		title: 'Roborock',
	// 		description: '',
	// 		link: '',
	// 		monumentImageUrl: roborock,
	// 		imageUrl: '',
	// 		contentImages: [],
	// 	},
	// },
	{
		position: { x: 10, y: 16 },
		link: {
			id: 7,
			title: 'New Balance',
			description: '',
			link: '',
			monumentImageUrl: newBalance,
			imageUrl: '',
			contentImages: [],
		},
	},
	// {
	// 	position: { x: 13, y: 13 },
	// 	link: {
	// 		id: 8,
	// 		title: 'Anker',
	// 		description: '',
	// 		link: '',
	// 		monumentImageUrl: anker,
	// 		imageUrl: '',
	// 		contentImages: [],
	// 	},
	// },
	{
		position: { x: 13, y: 10 },
		link: {
			id: 9,
			title: 'Primark',
			description: '',
			link: '',
			monumentImageUrl: primark,
			imageUrl: '',
			contentImages: [],
		},
	},
	{
		position: { x: 13, y: 7 },
		link: {
			id: 10,
			title: 'Worx',
			description: '',
			link: '',
			monumentImageUrl: worx,
			imageUrl: '',
			contentImages: [],
		},
	},
	{
		position: { x: 10, y: 7 },
		link: {
			id: 11,
			title: 'Zubr',
			description: '',
			link: '',
			monumentImageUrl: zubr,
			imageUrl: '',
			contentImages: [],
		},
	},
	{
		position: { x: 9, y: 4 },
		link: {
			id: 12,
			title: 'Puma',
			description: '',
			link: '',
			monumentImageUrl: puma,
			imageUrl: '',
			contentImages: [],
		},
	},
];
