import gsap from 'gsap';
import 'hammerjs';
import { Draggable } from 'gsap/Draggable';
import { $, $$, debounce } from '../../helpers';

import { Coordinates, GameMapDependencies, MapInterface } from '.';
import { AnalyticsInterface } from '../Analytics';
import { EventName } from '../Analytics';
import { ApiInterface, MapCell, MapCellType } from '../API';
import { CameraInterface } from '../Camera';
import { Cell } from '../Cell';
import { CellCategory, CellInterFace } from '../Cell';
import { BrandInterface } from '../Brand';
import { Modal, ModalsInterface } from '../Modal';
import { MonumentsInterface } from '../Monuments';
import { PrizesInterface } from '../Prizes';
import { BoxesInterface } from '../Boxes';
import { PointsInterface } from '../Points';

gsap.registerPlugin(Draggable);

export class GameMap implements MapInterface {
	private static singleton: GameMap;
	private analytics: AnalyticsInterface;
	private api: ApiInterface;
	private brand: BrandInterface;
	private camera: CameraInterface;
	private modal: ModalsInterface;
	private monuments: MonumentsInterface;
	private prizes: PrizesInterface;
	private boxes: BoxesInterface;
	private points: PointsInterface;

	private mapWrapper: HTMLElement = $('.map');
	private mapContainer: HTMLElement = $('.map__outer');
	private mapContent: HTMLElement = $('.map__layer._grid');
	private cellsXNum: number = 16;
	private cellsYNum: number = 16;
	private MAX_ZOOM_IN = 1;
	private MAX_ZOOM_OUT = 0.25;

	dragInstance: Draggable;
	readonly userPath = [
		9, 25, 24, 23, 22, 21, 37, 53, 69, 70, 71, 87, 103, 119, 118, 117, 116, 132, 148, 149, 165, 181, 182, 183, 184,
		168, 152, 153, 154, 155, 171, 187, 203, 219, 218, 217, 216, 215, 231, 247,
	];
	userCells: MapCell[] = [];
	cells: CellInterFace[] = [];
	readonly MAX_CELLS: number = 40;

	inject(deps: GameMapDependencies) {
		this.analytics = deps.analytics;
		this.api = deps.api;
		this.brand = deps.brand;
		this.camera = deps.camera;
		this.modal = deps.modal;
		this.modal = deps.modal;
		this.monuments = deps.monuments;
		this.prizes = deps.prizes;
		this.boxes = deps.boxes;
		this.points = deps.points;
	}

	static getInstance(): GameMap {
		if (!GameMap.singleton) GameMap.singleton = new GameMap();

		return GameMap.singleton;
	}

	async initMap() {
		const it = this;

		it.setViewport();
		await it.getMapData();
		it.buildMap();
	}

	initDrag() {
		const it = this;

		it.dragInstance = Draggable.create('.map__inner', {
			...it.camera.getDraggableVars(),
			onClick: debounce((e) => it.handleCellClick(e), 600),
		})[0];
	}

	private setViewport() {
		this.mapWrapper.style.height = window.innerHeight + 'px';
		this.mapContainer.style.height = window.innerHeight + 'px';
	}

	private buildMap() {
		const it = this;

		let cells = 0;
		let userCells = 0;

		for (let rowIndex = 1; rowIndex < it.cellsXNum + 1; rowIndex++) {
			const row = document.createElement('div');
			row.classList.add('map__row');

			for (let cellIndex = 1; cellIndex < it.cellsYNum + 1; cellIndex++) {
				const cell = new Cell({
					cellId: cells++,
					category: CellCategory.REGULAR,
				});

				it.cells.push(cell);

				const cellId = it.userPath.indexOf(cells) + 1;
				// const brandCell = it.userCells.find((cell) => cell.cellId === cellId);
				const brandCell = it.userCells[cellId - 1];
				if (it.userPath.includes(cells) && cellId) {
					row.append(cell.getHTML(cells, brandCell, cellId));
					userCells++;
				} else {
					row.append(cell.getHTML(cells, null, null));
				}
			}

			it.mapContent.append(row);
		}
	}

	getCellNumberByCoordinates(x: number, y: number) {
		const it = this;

		if (x > it.cellsXNum || y > it.cellsYNum) {
			console.warn('Неправильные координаты для монумента');
		}

		return (y - 1) * it.cellsYNum + x;
	}

	getCoordinates(pos: number): Coordinates {
		const it = this;

		let xPos = 0;
		let yPos = 0;
		let cellCounter = 0;

		for (let x = 0; x < it.cellsXNum; x++) {
			for (let y = 0; y < it.cellsYNum; y++) {
				if (cellCounter === pos) break;
				xPos = y;
				yPos = x;
				cellCounter++;
			}
		}

		let xCoordinate;
		if (yPos === 0) {
			xCoordinate = (Cell.sizeX * xPos) / 2 - (window.innerWidth - Cell.sizeX) / 2;
		}
		if (yPos > 0) {
			xCoordinate = (Cell.sizeX * xPos) / 2 + (Cell.sizeX / 2) * yPos - (window.innerWidth - Cell.sizeX) / 2;
		}

		let yCoordinate;
		yCoordinate =
			(it.cellsYNum - yPos) * (Cell.sizeY / 2) +
			(Cell.sizeY / 2) * xPos -
			(window.innerHeight + window.innerHeight / 3 - Cell.sizeY) / 2;

		return { x: -xCoordinate, y: -yCoordinate };
	}

	getCellNumber(cell) {
		return this.userPath[cell - 1];
	}

	async getMapData() {
		const it = this;

		it.userCells = (await it.api.getMap()).data.cells;
		// TODO: здесь мапим данные о карте к ячейкам всей карты
	}

	private handleCellClick(e) {
		const it = this;
		if (e.target?.classList.contains('_brand')) {
			const brandCell = it.userCells.find((cell) => cell.id === +e.target.dataset.cell);

			switch (brandCell?.type) {
				case MapCellType.seller:
					it.brand.toggleModal(brandCell);
					break;
				case MapCellType.superbox:
					it.boxes.toggleBoxesModal();
					break;
				// case MapCellType.loyalty_points:
				// 	it.points.togglePointsModal();
				// 	break;
			}
			// $('.wrapper').scrollTo(0, $('.wrapper').scrollHeight);
		} else if (e.target.classList.contains('_monument')) {
			it.monuments.toggleMonumentModal(e.target as HTMLElement);
			// $('.wrapper').scrollTo(0, $('.wrapper').scrollHeight);
		}
	}

	setInitialCell(position: number) {
		if ($(`.cell[data-cell="${position}"] .cell__fade`)) {
			gsap.set(`.cell[data-cell="${position}"] .cell__fade`, { opacity: 1 });
		}
		if ($(`.cell[data-cell="${position}"] .cell__brand`)) {
			gsap.set(`.cell[data-cell="${position}"] .cell__brand`, { opacity: 0 });
		}
	}

	enableZoom() {
		const it = this;

		var hammertime = new Hammer(it.mapWrapper, {});
		hammertime.get('pinch').set({
			enable: true,
		});

		var scale = 1,
			last_scale = 1,
			transform = '';

		// hammertime.on('pinch pinchend doubletap', function (ev) {
		hammertime.on('pinch pinchend', function (ev) {
			if (ev.type == 'pinch') {
				scale = Math.max(it.MAX_ZOOM_OUT, Math.min(last_scale * ev.scale, it.MAX_ZOOM_IN));
			}

			// if (ev.type == 'doubletap') {
			// 	console.log('ok');
			// 	transform = 'translate3d(0, 0, 0) ' + 'scale3d(.25, .25, 1) ';
			// }

			if (ev.type == 'pinchend') {
				last_scale = scale;
			}

			if (scale != 1) {
				transform = 'scale3d(' + scale + ', ' + scale + ', 1)';
			}

			if (transform) {
				it.mapWrapper.style.webkitTransform = transform;
			}
		});
	}
}
