import { GamedataInterface } from '../GameData';

export interface AnalyticsInterface {
	inject: (gameData: GamedataInterface) => void;
	track: (eventName: EventName, eventType?: 'click' | 'expose', params?: Record<string, any>) => void;
}

export enum EventName {
	Page_Aliland_Map_Screen,
	Page_Aliland_Map_SkipMapOverview_Click,
	Page_Aliland_Map_BrandCell_Click,
	Page_Aliland_Map_SuperboxCell_Click,
	Page_Aliland_SuperboxPopup_CouponList_Click,
	Page_Aliland_Map_LPCell_Click,
	Page_Aliland_LPPopup_Exposure,
	Page_Aliland_Map_Monument_Click,
	Page_Aliland_MonumentPopup_Exposure,
	Page_Aliland_Map_RollTheDice_Click,
	Page_Aliland_TIR2Coupons_Screen,
	Page_Aliland_TIR2Coupons_SelectCoupon,
	Page_Aliland_SuperboxCoupons_SelectCoupon,
	Page_Aliland_LPPopup_GoToAPP_Click,
	Page_Aliland_Map_GetTheMove_Click,
	Page_Aliland_Menu_GetTheMove_Click,
	Page_Aliland_TaskList_Screen,
	Page_Aliland_TaskList_MakeOrder,
	Page_Aliland_TaskList_Share_Click,
	Page_Aliland_Map_OpenMenu,
	Page_Aliland_Menu_Rewards_Click,
	Page_Aliland_Menu_RewardsCoupons_Click,
	Page_Aliland_Menu_RewardsSuperbox_Click,
	Page_Aliland_Menu_RewardsLP_Click,
	Page_Aliland_Menu_GameTerms_Click,
	Page_Aliland_Menu_GoToSale_Click,
	Page_Aliland_Menu_GoToSupport_Click,
	Page_Aliland_Menu_SupportFloatButton_Click,
	Page_Aliland_Map_BackToApp,
	Page_Aliland_Error_Screen,
	Page_Aliland_EndGamePopup_GoToSale,
	//
	Page_Aliland_EndGame_Screen,
	Page_Aliland_Menu_CouponList_Click,
	Page_Aliland_CongratulationPopup_GoToSale,
	Page_Aliland_EndGamePopup_TakeCoupon,

	Page_Aliland_BrandPopup_Exposure = 'Page_Aliland_BrandPopup_Exposure',
	Page_Aliland_BrandPopup_GotoShop_Click = 'Page_Aliland_BrandPopup_GotoShop_Click',
	Page_Aliland_SuperboxPopup_Exposure = 'Page_Aliland_SuperboxPopup_Exposure',
	Page_Aliland_SuperboxCoupons_Screen = 'Page_Aliland_SuperboxCoupons_Screen',
	Page_Aliland_MonumentPopup_GoToStore_Click = 'Page_Aliland_MonumentPopup_GoToStore_Click',
	Page_Aliland_BrandPopup_TakeCoupon = 'Page_Aliland_BrandPopup_TakeCoupon',
	Page_Aliland_CongratulationPopup_Exposure = 'Page_Aliland_CongratulationPopup_Exposure',
	Page_Aliland_CongratulationPopup_GoToShop = 'Page_Aliland_CongratulationPopup_GoToShop',
	Page_Aliland_BrandPopup_ChangeCoupon = 'Page_Aliland_BrandPopup_ChangeCoupon',
	Page_Aliland_CouponList_GotoShop_Click = 'Page_Aliland_CouponList_GotoShop_Click',
	Page_Aliland_SuperboxPopup_Exchange_Click = 'Page_Aliland_SuperboxPopup_Exchange_Click',
	Page_Aliland_TaskList_Task = 'Page_Aliland_TaskList_Task',
	Page_Aliland_EndGamePopup_Exposure = 'Page_Aliland_EndGamePopup_Exposure',
}
