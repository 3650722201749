import { CellCategory, CellData, CellInterFace } from '.';
import { baseUrl } from '../../data';
import { MapCell, MapCellType } from '../API';

export class Cell implements CellInterFace {
	readonly cellId: number;
	readonly category: CellCategory;
	static sizeX: number = 192;
	static sizeY: number = 110;

	constructor(data: CellData) {
		this.cellId = data.cellId;
		this.category = data.category;
	}

	getHTML(cellNum: number, mapCell: MapCell, cellId: number) {
		const cell = document.createElement('div');
		cell.classList.add('cell');

		if (cellId) {
			cell.dataset.cell = cellId.toString();
			cell.dataset.type = mapCell?.type;

			cell.innerHTML = `
				<div class="cell__clickable _brand ${!mapCell?.mapLogoURL ? '_no-logo' : ''}" data-cell="${mapCell?.id}"></div>
				<div class="cell__fade"></div>
				<div class="cell__counter">${cellId}</div>
				<div class="cell__brand">
					<div class="cell__logo _brand" data-cell="${
						mapCell?.id
					}" style="background-image: ${`url('${mapCell?.mapLogoURL}')`}"></div>
				</div>`;

			return cell;
		}

		if (mapCell) {
		}
		// cell.innerHTML = `<div class="cell__clickable">${cellNum}</div>`;
		cell.innerHTML = `<div class="cell__clickable"></div>`;

		return cell;
	}
}
