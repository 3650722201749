import { $ } from '../../helpers';
import { IMenuOptions } from '../../initialGameData';
import { AnalyticsInterface, EventName } from '../Analytics';
import { PrizeType, Status } from '../API';
import { BoxesInterface } from '../Boxes';
import { CameraInterface } from '../Camera';
import { CouponsInterface } from '../Coupons';
import { FigureInterface } from '../Figure';
import { GamedataInterface } from '../GameData';
import { MapInterface } from '../GameMap';
import { Modal, ModalsInterface } from '../Modal';
import { OtherCouponsInterface } from '../OtherCoupons';
import { PrizesInterface } from '../Prizes';
import { UIInterface } from '../UI';
import { UserInterface } from '../User';

import { EndgameDependencies, EndgameInterface } from './types';

export class Endgame implements EndgameInterface {
	private static singleton: Endgame;
	private initialData: GamedataInterface['initialData'];
	private analytics: AnalyticsInterface;
	private user: UserInterface;
	private modal: ModalsInterface;
	private map: MapInterface;
	private figure: FigureInterface;
	private camera: CameraInterface;
	private prizes: PrizesInterface;
	private coupons: CouponsInterface;
	private otherCoupons: OtherCouponsInterface;
	private boxes: BoxesInterface;
	private ui: UIInterface;

	inject(deps: EndgameDependencies) {
		this.initialData = deps.gameData.initialData;
		this.analytics = deps.analytics;
		this.user = deps.user;
		this.modal = deps.modal;
		this.map = deps.map;
		this.figure = deps.figure;
		this.camera = deps.camera;
		this.prizes = deps.prizes;
		this.coupons = deps.coupons;
		this.otherCoupons = deps.otherCoupons;
		this.boxes = deps.boxes;
		this.ui = deps.ui;
	}

	static getInstance(): Endgame {
		if (!Endgame.singleton) Endgame.singleton = new Endgame();

		return Endgame.singleton;
	}

	handleEndgame() {
		const it = this;

		if (it.user.currentState === Status.all_prizes_received) {
			it.toggleCompleteModal();
			return;
		}

		it.toggleBoxModal();
	}

	async restartGame() {
		const it = this;

		it.user.status.currentCellId = 1;
		it.figure.setTo(it.map.getCellNumber(1));
		it.camera.setTo(it.map.getCoordinates(it.map.getCellNumber(1)));
		it.ui.updateProgress();
	}

	toggleBoxModal() {
		const it = this;

		if (it.modal.current === Modal.ENDGAME_BOX) {
			it.restartGame();
			it.modal.hide();
			return;
		}

		$('.endgame-box .popup__heading').textContent = it.user.currentPrize.title;
		$('.endgame-box .popup__text').textContent = it.user.currentPrize.subtitle;
		$('.endgame-box__counter b').textContent = it.user.status.receivedChestsCount.toString();
		it.boxes.setUserBoxesCounter();
		it.modal.show(Modal.ENDGAME_BOX);

		it.analytics.track(EventName.Page_Aliland_SuperboxPopup_Exposure, 'expose', {
			exp_page: 'aliland_dice',
			exp_page_area: 'superbox_popup',
			exp_type: 'superbox_popup',
			exp_attribute: JSON.stringify({
				superbox_cnt: it.user.status.receivedChestsCount.toString(),
			}),
			exp_condition: 'game_flow',
			'spm-cnt': 'a2g2l.aliland.dice.superbox_popup',
		});
	}

	toggleCompleteModal() {
		const it = this;

		if (it.modal.current === Modal.ENDGAME_COMPLETE) {
			it.restartGame();
			it.modal.hide();
			return;
		}

		$('.endgame-complete .popup__heading').textContent = it.user.currentPrize.title;
		$('.endgame-complete .popup__text').textContent = it.user.currentPrize.subtitle;

		it.modal.show(Modal.ENDGAME_COMPLETE);

		it.analytics.track(EventName.Page_Aliland_EndGamePopup_Exposure, 'expose', {
			exp_page: 'aliland_dice',
			exp_page_area: 'endgame_popup',
			exp_type: 'endgame_popup',
			exp_attribute: 'aecoupon_not_available',
			exp_condition: 'game_flow',
			'spm-cnt': 'a2g2l.aliland.dice.endgame_popup',
		});
	}

	handleBoxesList() {
		const it = this;

		it.restartGame();
		it.prizes.togglePrizesBoxesScreen();
		// it.toggleBoxModal();
	}

	goToStore() {
		const it = this;

		it.analytics.track(EventName.Page_Aliland_EndGamePopup_GoToSale);
		it.initialData.dataProvider.open({ option: IMenuOptions.SALES_LINK });
	}

	gameOverGoToStore() {
		this.initialData.dataProvider.open({ option: IMenuOptions.SALES_LINK });
	}

	// handleEndgame() {
	// 	const it = this;

	// 	// it.togglePlatformCoupon();

	// 	// // Выиграл платформенный, но ещё не забрал
	// 	// if (!endgame.platformCouponReceived && endgame.availablePlatformCouponsInGame && endgame.availableMove) {
	// 	// 	it.togglePlatformCoupon();
	// 	// 	return;
	// 	// }
	// 	// // Платформенные кончились, но есть обычные
	// 	// if (
	// 	// 	!endgame.platformCouponReceived &&
	// 	// 	!endgame.availablePlatformCouponsInGame &&
	// 	// 	endgame.availableMove &&
	// 	// 	endgame.sellerCouponsAvailable
	// 	// ) {
	// 	// 	it.toggleEndgameNoPlatform();
	// 	// 	return;
	// 	// }
	// 	// // Уже выигрывал платформенный, но есть ещё обычные
	// 	// if (endgame.platformCouponReceived && endgame.sellerCouponsAvailable) {
	// 	// 	it.toggleEndgameHasPlatform();
	// 	// 	return;
	// 	// }
	// 	// if (!endgame.sellerCouponsAvailable) {
	// 	// 	it.toggleGameOver();
	// 	// 	return;
	// 	// }
	// }

	// toggleGameOver() {
	// 	const it = this;

	// 	if (it.modal.current) {
	// 		it.modal.hide();
	// 	}

	// 	// it.modal.show(Modal.ENDGAME_OVER);
	// 	it.analytics.track(EventName.Page_Aliland_EndGame_Screen);
	// }

	// private async toggleEmptyModal() {
	// 	const it = this;

	// 	it.modal.show(Modal.ENDGAME_SIGNIN_NO_COUPONS);
	// }

	// togglePlatformCoupon() {
	// 	const it = this;

	// 	const coupon = it.user.currentPrize;

	// 	// if (!it.user.currentPrize && it.otherCoupons.list) {
	// 	// 	it.otherCoupons.showList(false);
	// 	// 	return;
	// 	// }

	// 	// if (!it.user.currentPrize && !it.otherCoupons.list) {
	// 	if (!it.user.currentPrize) {
	// 		it.toggleEmptyModal();
	// 		return;
	// 	}

	// 	$('.endgame-coupon .js-coupon-list').innerHTML = '';
	// 	$('.endgame-coupon .js-coupon-list').append(it.coupons.getHTML(coupon));

	// 	// if (it.modal.current === Modal.COUPON) {
	// 	// 	it.modal.hide(Modal.COUPON);
	// 	// 	return;
	// 	// }

	// 	it.modal.show(Modal.ENDGAME_COUPON);

	// 	// it.analytics.track(EventName.Page_Aliland_EndGamePopup_Exposure, 'expose', {
	// 	// 	exp_page: 'aliland_dice',
	// 	// 	exp_page_area: 'endgame_popup',
	// 	// 	exp_type: 'endgame_popup',
	// 	// 	exp_attribute: 'aecoupon_available',
	// 	// 	exp_condition: 'game_flow',
	// 	// 	'spm-cnt': 'a2g2l.aliland.dice.endgame_popup',
	// 	// });
	// }

	// private toggleEndgameNoPlatform() {
	// 	const it = this;

	// 	it.modal.hide();
	// 	// it.modal.show(Modal.ENDGAME_NO_PLATFORM);

	// 	// it.analytics.track(EventName.Page_Aliland_EndGamePopup_Exposure, 'expose', {
	// 	// 	exp_page: 'aliland_dice',
	// 	// 	exp_page_area: 'endgame_popup',
	// 	// 	exp_type: 'endgame_popup',
	// 	// 	exp_attribute: 'aecoupon_not_available',
	// 	// 	exp_condition: 'game_flow',
	// 	// 	'spm-cnt': 'a2g2l.aliland.dice.endgame_popup',
	// 	// });
	// }

	// private toggleEndgameHasPlatform() {
	// 	const it = this;

	// 	if (this.modal.current) {
	// 		this.modal.hide();
	// 	}

	// 	// this.modal.show(Modal.ENDGAME_HAS_PLATFORM);
	// }

	// async grab() {
	// 	const it = this;

	// 	// it.analytics.track(EventName.Page_Aliland_BrandPopup_TakeCoupon, 'click', {
	// 	// 	ae_page_type: 'aliland_dice',
	// 	// 	ae_page_area: 'brand_popup',
	// 	// 	ae_button_type: 'take_coupon',
	// 	// 	ae_object_value: JSON.stringify({
	// 	// 		type: 'tir-1',
	// 	// 		brandname: it.user.currentPrize.sellerTitle,
	// 	// 		sellerSeq: it.user.currentPrize.sellerId,
	// 	// 	}),
	// 	// 	ae_click_behavior: 'game_flow',
	// 	// 	'spm-cnt': 'a2g2l.aliland.dice.brand_popup_take_coupon',
	// 	// });

	// 	($('.js-endgame-coupon-submit-store') as HTMLElement).dataset.href = it.user.currentPrize.link;

	// 	const isAssigned = await it.user.takePrize(it.user.currentPrize.id);
	// 	if (!isAssigned) {
	// 		it.modal.show(Modal.ERROR_GENERAL);
	// 		return;
	// 	}

	// 	it.toggleSubmit();
	// }

	// async leave() {
	// 	const it = this;

	// 	// await it.user.dismissPrize();
	// 	await it.restartGame();
	// 	it.modal.hide(Modal.ENDGAME_COUPON_REMIND);
	// }

	// toggleSubmit() {
	// 	const it = this;

	// 	if (it.modal.current === Modal.ENDGAME_COUPON_SUBMIT) {
	// 		it.modal.hide();
	// 		it.user.clearCurrentPrize();
	// 		it.restartGame();
	// 		return;
	// 	}

	// 	it.modal.show(Modal.ENDGAME_COUPON_SUBMIT);

	// 	// it.analytics.track(EventName.Page_Aliland_CongratulationPopup_Exposure, 'expose', {
	// 	// 	exp_page: 'aliland_dice',
	// 	// 	exp_page_area: 'congratulation_popup',
	// 	// 	exp_type: 'congratulation_popup',
	// 	// 	exp_attribute: JSON.stringify({
	// 	// 		type: it.user.assigningCouponTir === PrizeTir.TIR1 ? 'tir-1' : 'tir-2',
	// 	// 		brandname: coupon.sellerTitle,
	// 	// 		sellerSeq: coupon.sellerId,
	// 	// 	}),
	// 	// 	exp_condition: 'game_flow',
	// 	// 	'spm-cnt': 'a2g2l.aliland.dice.congratulation_popup',
	// 	// });
	// }
}
