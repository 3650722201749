import gsap from 'gsap';
import { $ } from '../../helpers';

import { UIDependencies, UIInterface } from '.';
import { Modal, ModalsInterface } from '../Modal';
import { GamedataInterface } from '../GameData';
import { AnalyticsInterface } from '../Analytics';
import { EventName } from '../Analytics';
import { CouponsInterface } from '../Coupons';
import { UserInterface } from '../User';
import { OtherCouponsInterface } from '../OtherCoupons';
import { EndgameInterface } from '../Endgame';
import { IMenuOptions } from '../../initialGameData';

export class UI implements UIInterface {
	private static singleton: UI;
	animationRunning: boolean = false;
	private initialData: GamedataInterface['initialData'];
	private analytics: AnalyticsInterface;
	private modal: ModalsInterface;
	private coupons: CouponsInterface;
	private user: UserInterface;
	private otherCoupons: OtherCouponsInterface;
	private endgame: EndgameInterface;

	inject(deps: UIDependencies) {
		this.initialData = deps.gameData.initialData;
		this.analytics = deps.analytics;
		this.modal = deps.modal;
		this.coupons = deps.coupons;
		this.user = deps.user;
		this.otherCoupons = deps.otherCoupons;
		this.endgame = deps.endgame;
	}

	public static getInstance(): UI {
		if (!UI.singleton) UI.singleton = new UI();

		return UI.singleton;
	}

	hideLoader() {
		const it = this;

		gsap.timeline().delay(0.5).to('.loader', { opacity: 0 }).to('.loader', { display: 'none' });
		it.analytics.track(EventName.Page_Aliland_Map_Screen);
	}

	hideLogin() {
		$('.login').classList.remove('_active');
	}

	handleErrorBtn() {
		gsap.timeline({
			onComplete: () => {
				window.location.reload();
			},
		})
			.to('.js-error-load', { scale: 0.95, duration: 0.05 })
			.to('.js-error-load', { scale: 1, duration: 0.05 });
	}

	showError() {
		$('.error-general')?.classList.add('_active');
		this.analytics.track(EventName.Page_Aliland_Error_Screen);
	}

	showAssignError() {
		$('.error-assign')?.classList.add('_active');
		this.analytics.track(EventName.Page_Aliland_Error_Screen);
	}

	showGameOver() {
		$('.error-over').classList.add('_active');
	}

	async handleBackBtn() {
		const it = this;

		// if (it.animationRunning && it.modal.current !== Modal.RULES) return;

		gsap.timeline({
			onComplete: async () => {
				// if (it.modal.current === Modal.ERROR_GENERAL) {
				// 	it.otherCoupons.build();
				// 	it.modal.handleReturn();
				// 	return;
				// }
				// if (it.modal.current === Modal.OTHER_COUPONS_ERROR) {
				// 	it.modal.handleReturn();
				// 	it.coupons.toggleModal();
				// 	return;
				// }
				// if (!it.user.status.hasPassedTutorial) {
				// 	gsap.set('.header', { y: '-100%' });
				// 	gsap.set('.dice', { y: '100%' });
				// }
				it.modal.handleReturn();
			},
		})
			.to('.js-back-app-btn', { scale: 0.95, duration: 0.05 })
			.to('.js-back-app-btn', { scale: 1, duration: 0.05 });
	}

	handleMenuBtn() {
		const it = this;

		if (it.animationRunning) return;

		it.analytics.track(EventName.Page_Aliland_Map_OpenMenu);

		gsap.timeline({
			onComplete: () => it.modal.handleMenu(),
		})
			.to('.js-menu-btn', { scale: 0.95, duration: 0.05 })
			.to('.js-menu-btn', { scale: 1, duration: 0.05 });
	}

	toggleRulesScreen(isTutorial: boolean = false) {
		const it = this;

		// if (isTutorial) {
		// 	it.modal.show(Modal.RULES_TUTORIAL);
		// 	return;
		// }
		// if (!it.user.status.hasPassedTutorial) {
		// 	if (it.modal.current === Modal.RULES) {
		// 		gsap.set('.header', { y: '-100%' });
		// 		gsap.set('.dice', { y: '100%' });
		// 	} else {
		// 		gsap.set('.header, .dice', { y: '0' });
		// 	}
		// }

		it.analytics.track(EventName.Page_Aliland_Menu_GameTerms_Click);
		// it.modal.show(Modal.RULES);
		it.initialData.dataProvider.open({ option: IMenuOptions.RULES_LINK });
	}

	menuSaleHandler(target: HTMLElement) {
		const it = this;

		it.analytics.track(EventName.Page_Aliland_Menu_GoToSale_Click);
		// it.initialData.dataProvider.goLink({ link: target.dataset.href });
		it.initialData.dataProvider.open({ option: IMenuOptions.SALES_LINK });
	}

	menuSupportHandler(target: HTMLElement) {
		const it = this;

		it.analytics.track(EventName.Page_Aliland_Menu_GoToSupport_Click);
		// it.initialData.dataProvider.goLink({ link: target.dataset.href });
		it.initialData.dataProvider.open({ option: IMenuOptions.SUPPORT_LINK });
	}

	setRulesData() {
		const rulesList = $('.rules__list');
		this.initialData.staticData.rulesText.forEach((rule) => {
			const ruleHTML = document.createElement('div');
			ruleHTML.classList.add('js-rule', 'rules__item');
			ruleHTML.innerHTML = `<div class="rules__head">
				<div class="rules__title">${rule.title}</div>
			</div>
			<div class="rules__content">
				<div class="rules__content-inner">${rule.text}</div>
			</div>`;
			rulesList.append(ruleHTML);
		});
	}

	toggleRulePane(target: HTMLElement) {
		if (!target.classList.contains('_active')) {
			target.classList.add('_active');
			gsap.to(target.lastElementChild, { height: 'auto' });
			return;
		}

		target.classList.remove('_active');
		gsap.to(target.lastElementChild, { height: 0 });
	}

	rulesSupportHandler(target: HTMLElement) {
		const it = this;

		this.analytics.track(EventName.Page_Aliland_Menu_SupportFloatButton_Click);
		this.initialData.dataProvider.goLink({ link: target.dataset.href });
	}

	toggleDropdown(target: HTMLElement, status?: 'open' | 'close') {
		if (status === 'open') {
			target.parentElement.classList.add('_active');
			return;
		}
		if (status === 'close') {
			target.parentElement.classList.remove('_active');
			return;
		}
		target.parentElement.classList.toggle('_active');
	}

	setUserPrizes() {
		const it = this;

		if (it.user.prizes?.coupons) {
			$('.js-my-coupons').textContent = `У вас уже ${it.user.prizes.coupons.length}`;
		} else {
			$('.js-my-coupons').innerHTML = `Пока нет&nbsp;&mdash; скорее <br/>соберите их&nbsp;в&nbsp;игре`;
		}

		if (it.user.status?.receivedChestsCount) {
			$('.js-my-boxes').textContent = `У вас уже ${it.user.status.receivedChestsCount}`;
		} else {
			$('.js-my-boxes').innerHTML = `Пока нет&nbsp;&mdash; скорее <br/>соберите их&nbsp;в&nbsp;игре`;
		}

		// if (it.user.prizes?.loyaltyPoints) {
		// 	$('.js-my-points').textContent = `У вас уже ${it.user.prizes.loyaltyPoints.length}`;
		// } else {
		// 	$('.js-my-points').innerHTML = `Пока нет&nbsp;&mdash; скорее <br/>соберите их&nbsp;в&nbsp;игре`;
		// }
	}

	updateProgress() {
		const it = this;
		($('.js-current-bar') as HTMLElement).style.width =
			(100 / it.user.status.totalCellsCount) * it.user.status.currentCellId + '%';
		$('.js-current-step').textContent = it.user.status.currentCellId.toString();
	}
}
