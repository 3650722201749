import { AnalyticsInterface } from '../Analytics';
import { CouponsInterface } from '../Coupons';
import { EndgameInterface } from '../Endgame';
import { GamedataInterface } from '../GameData';

export type ModalsDependencies = {
	gameData: GamedataInterface;
	analytics: AnalyticsInterface;
	coupons: CouponsInterface;
	endgame: EndgameInterface;
};

export interface ModalsInterface {
	inject: (deps: ModalsDependencies) => void;
	initListeners: () => void;
	current: Modal;
	setHeights: () => void;
	show: (modal: Modal) => void;
	hide: (modal?: Modal) => void;
	handleReturn: () => void;
	handleMenu: (restartHandler?) => void;
}

export enum BackBtnText {
	DEFAULT = 'AliExpress',
	MAP = 'На карту',
}

export enum Modal {
	LOGIN = 'LOGIN',
	LOADER = 'LOADER',
	ERROR_GENERAL = 'ERROR_GENERAL',
	ERROR_LOADING = 'ERROR_LOADING',
	GAMEOVER = 'GAMEOVER',
	MENU = 'MENU',
	GET_MOVE = 'GET_MOVE',
	ADD_MOVE = 'ADD_MOVE',
	BRAND = 'BRAND',
	MONUMENT = 'MONUMENT',
	// RULES = 'RULES',
	GIVEAWAY = 'GIVEAWAY',
	FORM = 'FORM',
	PRIZES = 'PRIZES',
	PRIZES_LIST = 'PRIZES_LIST',
	PRIZES_LIST_ENDGAME = 'PRIZES_LIST_ENDGAME',
	// POINTS = 'POINTS',
	// POINTS_GAIN = 'POINTS_GAIN',
	// POINTS_LIST = 'POINTS_LIST',
	BOX = 'BOX',
	BOX_GAIN = 'BOX_GAIN',
	COUPON = 'COUPON',
	COUPON_OTHER = 'COUPON_OTHER',
	COUPON_OTHER_EMPTY = 'COUPON_OTHER_EMPTY',
	COUPON_EMPTY = 'COUPON_EMPTY',
	COUPON_NO_COUPONS = 'COUPON_NO_COUPONS',
	COUPON_SUBMIT = 'COUPON_SUBMIT',
	COUPON_REMIND = 'COUPON_REMIND',
	// COUPON_REMIND_FROM_COUPON_OTHER = 'COUPON_REMIND_FROM_COUPON_OTHER',
	// COUPON_REMIND_FROM_OTHER_COUPONS = 'COUPON_REMIND_FROM_OTHER_COUPONS',
	COUPON_REMIND_EMPTY = 'COUPON_REMIND_EMPTY',
	// COUPON_REMIND_EMPTY_FROM_COUPON_OTHER_EMPTY = 'COUPON_REMIND_EMPTY_FROM_COUPON_OTHER_EMPTY',
	// COUPON_REMIND_EMPTY_FROM_OTHER_COUPONS_NO_COUPON = 'COUPON_REMIND_EMPTY_FROM_OTHER_COUPONS_NO_COUPON',
	OTHER_COUPONS = 'OTHER_COUPONS',
	OTHER_COUPONS_NO_COUPON = 'OTHER_COUPONS_NO_COUPON',
	OTHER_COUPONS_ERROR = 'OTHER_COUPONS_ERROR',
	SUPERCOUPON = 'SUPERCOUPON',
	SUPERCOUPON_SUBMIT = 'SUPERCOUPON_SUBMIT',
	SUPERCOUPON_REMIND = 'SUPERCOUPON_REMIND',
	// ENDGAME_COUPON = 'ENDGAME_COUPON',
	// ENDGAME_COUPON_SUBMIT = 'ENDGAME_COUPON_SUBMIT',
	// ENDGAME_COUPON_REMIND = 'ENDGAME_COUPON_REMIND',
	// ENDGAME_SIGNIN = 'ENDGAME_SIGNIN',
	// ENDGAME_SIGNIN_NO_COUPONS = 'ENDGAME_SIGNIN_NO_COUPONS',
	ENDGAME_COMPLETE = 'ENDGAME_COMPLETE',
	ENDGAME_BOX = 'ENDGAME_BOX',
}
