import { MapCell } from '../API';

export interface CellInterFace {
	cellId: number;
	category: CellCategory;
	getHTML: (cellNum: number, mapCell: MapCell, cellId: number) => HTMLDivElement;
}

export enum CellCategory {
	REGULAR,
	PATH,
}

export interface CellData {
	cellId: number;
	category: CellCategory;
}
