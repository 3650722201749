export const $ = document.querySelector.bind(document) as typeof document.querySelector;
export const $$ = document.querySelectorAll.bind(document) as typeof document.querySelectorAll;

export const debounce = (f, ms) => {
	let isCooldown = false;

	return function () {
		if (isCooldown) return;

		f.apply(this, arguments);

		isCooldown = true;

		setTimeout(() => (isCooldown = false), ms);
	};
};

export const formatNumber = (num: number): string => {
	return String(num).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const num2str = (n: number, text_forms: string[]): string => {
	n = Math.abs(n) % 100;
	var n1 = n % 10;
	if (n > 10 && n < 20) {
		return text_forms[2];
	}
	if (n1 > 1 && n1 < 5) {
		return text_forms[1];
	}
	if (n1 == 1) {
		return text_forms[0];
	}
	return text_forms[2];
};

export const formatDate = (input): string => {
	const date = new Date(input);
	const day = date.getDate();
	const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
	return day + '.' + month;
};
