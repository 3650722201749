import { baseUrl } from '../../data';
import { $ } from '../../helpers';

import { AnalyticsInterface, EventName } from '../Analytics';
import { MapCell } from '../API';
import { GamedataInterface } from '../GameData';
import { Modal, ModalsInterface } from '../Modal';
import { BrandInterface, BrandDependencies } from './types';

export class Brand implements BrandInterface {
	private static singleton: Brand;
	private currentCell: MapCell;
	private initialData: GamedataInterface['initialData'];
	private analytics: AnalyticsInterface;
	private modal: ModalsInterface;

	inject(deps: BrandDependencies) {
		this.initialData = deps.gameData.initialData;
		this.analytics = deps.analytics;
		this.modal = deps.modal;
	}

	public static getInstance(): Brand {
		if (!Brand.singleton) Brand.singleton = new Brand();

		return Brand.singleton;
	}

	toggleModal(cell: MapCell) {
		const it = this;

		it.analytics.track(EventName.Page_Aliland_Map_BrandCell_Click);

		($('.brand .popup__logo-img') as HTMLElement).style.backgroundImage = '';
		$('.brand .popup__heading').textContent = '';
		$('.brand .popup__text').textContent = '';
		($('.brand .brand__img._1') as HTMLElement).style.backgroundImage = '';
		($('.brand .brand__img._2') as HTMLElement).style.backgroundImage = '';

		($('.brand .popup__logo-img') as HTMLElement).style.backgroundImage = `url('${cell.logoUrl}')`;
		$('.brand .popup__heading').textContent = cell.sellerTitle;
		$('.brand .popup__text').textContent = cell.sellerSubtitle;
		if (cell.contentImages[0]) {
			($('.brand .brand__img._1') as HTMLElement).style.backgroundImage = `url('${cell.contentImages[0]}')`;
		}
		if (cell.contentImages[1]) {
			($('.brand .brand__img._2') as HTMLElement).style.backgroundImage = `url('${cell.contentImages[1]}')`;
		}
		($('.brand button') as HTMLElement).dataset.href = cell.link;

		it.currentCell = cell;
		it.modal.show(Modal.BRAND);

		it.analytics.track(EventName.Page_Aliland_BrandPopup_Exposure, 'expose', {
			exp_page: 'aliland_map',
			exp_page_area: 'brand_popup',
			exp_type: 'brand_popup',
			exp_attribute: JSON.stringify({
				type: 'no_coupon',
				brandname: cell.sellerTitle,
				sellerSeq: cell.sellerId,
			}),
			exp_condition: 'out_of_game_flow',
			'spm-cnt': 'a2g2l.aliland.map.brand_popup',
		});
	}

	goToStore() {
		const it = this;

		it.analytics.track(EventName.Page_Aliland_BrandPopup_GotoShop_Click, 'click', {
			ae_page_type: 'aliland_map',
			ae_page_area: 'brand_popup',
			ae_button_type: 'gotoshop',
			ae_object_value: JSON.stringify({
				type: 'no_coupon',
				brandname: it.currentCell.sellerTitle,
				sellerSeq: it.currentCell.sellerId,
				action: it.currentCell.link,
			}),
			ae_click_behavior: 'out_of_game_flow',
			'spm-cnt': 'a2g2l.aliland.map.brand_popup_gotoshop',
		});
		it.initialData.dataProvider.goLink({ link: it.currentCell.link });
	}
}
