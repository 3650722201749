import { GamedataInterface } from '.';
import { ETasks, IGameBackend, IInitialGameData, IInitialGameDataBackend } from '../../initialGameData';

export class GameData implements GamedataInterface {
	private static singleton: GameData;
	initialData: IInitialGameData;
	buyerId: IInitialGameDataBackend['buyerId'];
	shareLink: IGameBackend[ETasks.shareLink];
	apiCall: IGameBackend[ETasks.apiCall];

	static getInstance(): GameData {
		if (!GameData.singleton) GameData.singleton = new GameData();

		return GameData.singleton;
	}

	setData(initialData: IInitialGameData) {
		this.initialData = initialData;
		this.buyerId = initialData.buyerId;
		this.shareLink = initialData.dataProvider.shareLink;
		this.apiCall = initialData.dataProvider.apiCall;
	}
}
