import gsap from 'gsap';
import { $, formatDate, formatNumber } from '../../helpers';

import { CouponsDependencies, CouponsInterface } from '.';
import { MapCell, Prize, PrizeType } from '../API';
import { GamedataInterface } from '../GameData';
import { MapInterface } from '../GameMap';
import { UserInterface, PrizeTir } from '../User/types';
import { UIInterface } from '../UI';
import { AnalyticsInterface, EventName } from '../Analytics';
import { Modal, ModalsInterface } from '../Modal';
import { baseUrl } from '../../data';
import { EndgameInterface } from '../Endgame';
import { IMenuOptions } from '../../initialGameData';

export class Coupons implements CouponsInterface {
	private static singleton: Coupons;
	private initialData: GamedataInterface['initialData'];
	private map: MapInterface;
	private user: UserInterface;
	private ui: UIInterface;
	private analytics: AnalyticsInterface;
	private modal: ModalsInterface;
	private endgame: EndgameInterface;

	inject(deps: CouponsDependencies) {
		this.initialData = deps.gameData.initialData;
		this.map = deps.map;
		this.user = deps.user;
		this.ui = deps.ui;
		this.analytics = deps.analytics;
		this.modal = deps.modal;
		this.endgame = deps.endgame;
	}

	static getInstance(): Coupons {
		if (!Coupons.singleton) Coupons.singleton = new Coupons();

		return Coupons.singleton;
	}

	async updateList() {
		const it = this;

		// await it.user.getPrizes();

		const couponsList = $('.coupons-list__list');
		couponsList.innerHTML = '';

		if (!it.user.prizes?.coupons?.length) {
			$('.coupons-list').classList.add('_no-coupons');
		} else {
			$('.coupons-list').classList.remove('_no-coupons');
			it.user.prizes.coupons.forEach((coupon) => {
				couponsList.append(it.getHTML(coupon, true));
			});
		}

		const superCouponsList = $('.my-prizes__list');
		superCouponsList.innerHTML = '';

		if (!it.user.prizes?.superCoupons?.length) {
			if (it.user.status?.receivedChestsCount > 0) {
				$('.my-prizes').classList.add('_has-boxes');
			} else {
				$('.my-prizes').classList.add('_no-boxes');
			}
		} else {
			$('.my-prizes').classList.remove('_has-boxes', '_no-boxes');
			$('.my-prizes').classList.add('_prizes');

			it.user.prizes.superCoupons.forEach((coupon) => {
				superCouponsList.append(it.getHTML(coupon));
			});
		}
	}

	getHTML(coupon: Prize, addLink: boolean = false) {
		const it = this;

		const card = document.createElement('div');
		card.classList.add('coupon-card');

		if (coupon?.type === PrizeType.aer_coupon || coupon?.type === PrizeType.superbox) {
			card.classList.add('_supercoupon');
			card.innerHTML = `
				<div class="coupon-card__border"></div>
				<div class="coupon-card__head">
					<div class="coupon-card__head-left">
						<h2 class="js-coupon-go-store">${coupon.sellerTitle}</h2>
					</div>
					<div class="coupon-card__head-right">
						<h5><span>${formatNumber(Math.floor(coupon.amount))}</span> ₽</h5>
						<h6>На заказ от <span>${formatNumber(Math.floor(coupon.threshold))}</span> ₽</h6>
					</div>
				</div>
				<div class="coupon-card__body">
					<ul>
						<li><b>На что</b><span>все товары</span></li>
					</ul>
					<div class="coupon-card__checkin">Идёт проверка службы безопасности&nbsp;&mdash; это занимает 3&nbsp;недели. Если вы&nbsp;оформили заказ, но&nbsp;отменили его&nbsp;&mdash; суперкупон не&nbsp;начислится</div>
				</div>
			`;
			return card;
		}

		card.innerHTML = `
				<div class="coupon-card__border"></div>
				<div class="coupon-card__head">
					<div class="coupon-card__head-left">
						<h2 class="js-coupon-go-store">${coupon.sellerTitle}</h2>
					</div>
					<div class="coupon-card__head-right">
						<h5><span>${formatNumber(Math.floor(coupon.amount))}</span> ₽</h5>
						<h6>На заказ от <span>${formatNumber(Math.floor(coupon.threshold))}</span> ₽</h6>
					</div>
				</div>
				<div class="coupon-card__body">
					<ul>
						<li><b>Когда</b><span>${formatDate(coupon.validFrom)} - ${formatDate(coupon.validTill)}</span></li>
						<li><b>На что</b><span>${coupon.title}</span></li>
					</ul>
					${
						addLink
							? `<button class="js-coupon-go-store btn _orange" data-id="${coupon.id}" data-href="${coupon.link}">Перейти в&nbsp;магазин</button>`
							: ''
					}
				</div>
			`;

		return card;
	}

	toggleEmptyModal() {
		const it = this;

		if (it.modal.current === Modal.COUPON_EMPTY) {
			it.user.dismissPrize();
			it.modal.hide();
			return;
		}
		($('.coupon-empty .popup__logo-img') as HTMLElement).style.backgroundImage = '';
		const { logoUrl } = it.map.userCells.find(
			(cell) => +cell.id === (it.user.newCellId || it.user.status.currentCellId)
		);

		if (logoUrl) {
			($('.coupon-empty .popup__logo-img') as HTMLElement).style.backgroundImage = `url('${logoUrl}')`;
		}

		it.modal.show(Modal.COUPON_EMPTY);
	}

	toggleNoCouponsModal() {
		const it = this;

		if (it.modal.current === Modal.COUPON_NO_COUPONS) {
			// it.user.dismissPrize();
			it.modal.hide();
			return;
		}

		it.modal.show(Modal.COUPON_NO_COUPONS);
	}

	goToStore(target: HTMLElement) {
		const it = this;

		const couponId = target.dataset.id;
		const coupon = it.user.prizes.coupons.find((item) => item.id === +couponId);

		if (coupon.type === PrizeType.seller_coupon) {
			/* Если купон бренда */
			it.analytics.track(EventName.Page_Aliland_CouponList_GotoShop_Click, 'click', {
				ae_page_type: 'aliland_couponlist',
				ae_button_type: 'gotoshop',
				ae_object_value: JSON.stringify({
					type: 'tir',
					brandname: coupon.sellerTitle,
					sellerSeq: coupon.sellerId,
					action: coupon.link,
				}),
				ae_click_behavior: 'out_of_game_flow',
				'spm-cnt': 'a2g2l.aliland.couponlist.gotoshop',
			});
		}

		if (coupon.type === PrizeType.aer_coupon) {
			/* Если купон Алиэкспресс */
			it.analytics.track(EventName.Page_Aliland_CouponList_GotoShop_Click, 'click', {
				ae_page_type: 'aliland_couponlist',
				ae_button_type: 'gotosale',
				ae_click_behavior: 'out_of_game_flow',
				'spm-cnt': 'a2g2l.aliland.couponlist.gotosale',
			});
		}

		it.initialData.dataProvider.goLink({ link: target.dataset.href });
	}

	async grab() {
		const it = this;

		it.analytics.track(EventName.Page_Aliland_BrandPopup_TakeCoupon, 'click', {
			ae_page_type: 'aliland_dice',
			ae_page_area: 'brand_popup',
			ae_button_type: 'take_coupon',
			ae_object_value: JSON.stringify({
				type: 'tir-1',
				brandname: it.user.currentPrize.sellerTitle,
				sellerSeq: it.user.currentPrize.sellerId,
			}),
			ae_click_behavior: 'game_flow',
			'spm-cnt': 'a2g2l.aliland.dice.brand_popup_take_coupon',
		});

		($('.js-coupon-submit-store') as HTMLElement).dataset.href = it.user.currentPrize.link;
		it.user.assigningCouponTir = PrizeTir.TIR1;

		const isAssigned = await it.user.takePrize(it.user.currentPrize.id);
		if (!isAssigned) {
			it.modal.show(Modal.ERROR_GENERAL);
			return;
		}

		it.updateList();
		it.toggleSubmit(it.user.currentPrize);
	}

	async leave() {
		const it = this;

		await it.user.dismissPrize();
		it.modal.hide(Modal.COUPON_REMIND);
	}

	toggleSubmit(coupon?: Prize) {
		const it = this;

		if (it.modal.current === Modal.COUPON_SUBMIT) {
			it.modal.hide();
			it.user.clearCurrentPrize();
			// if (it.user.status.currentCellId >= it.user.status.totalCellsCount) {
			// 	it.endgame.restartGame();
			// }
			return;
		}

		it.modal.show(Modal.COUPON_SUBMIT);

		it.analytics.track(EventName.Page_Aliland_CongratulationPopup_Exposure, 'expose', {
			exp_page: 'aliland_dice',
			exp_page_area: 'congratulation_popup',
			exp_type: 'congratulation_popup',
			exp_attribute: JSON.stringify({
				type: 'tir',
				brandname: coupon.sellerTitle,
				sellerSeq: coupon.sellerId,
			}),
			exp_condition: 'game_flow',
			'spm-cnt': 'a2g2l.aliland.dice.congratulation_popup',
		});
	}

	toggleModal() {
		const it = this;

		const coupon = it.user.currentPrize;

		if (!it.user.currentPrize) {
			it.toggleEmptyModal();
			return;
		}

		($('.coupon .popup__logo-img') as HTMLElement).style.backgroundImage = `url('${coupon.imageUrl}')`;
		$('.coupon .popup__heading').textContent = `Купон от ${coupon.sellerTitle}`;
		$('.coupon .popup__text').textContent = coupon.title;
		$('.coupon .js-coupon-list').innerHTML = '';
		$('.coupon .js-coupon-list').append(it.getHTML(coupon));

		it.modal.show(Modal.COUPON);

		it.analytics.track(EventName.Page_Aliland_BrandPopup_Exposure, 'expose', {
			exp_page: 'aliland_map',
			exp_page_area: 'brand_popup',
			exp_type: 'brand_popup',
			exp_attribute: JSON.stringify({
				type: 'tir',
				brandname: coupon.sellerTitle,
				sellerSeq: coupon.sellerId,
			}),
			exp_condition: 'game_flow',
			'spm-cnt': 'a2g2l.aliland.dice.brand_popup',
		});
	}

	// async handleAssignError() {
	// 	const it = this;

	// 	$('.js-error-assign').setAttribute('disabled', 'true');
	// 	gsap.timeline({
	// 		onComplete: async () => {
	// 			if (it.user.assigningCouponType === CouponSeller.TIER1) {
	// 				const isAssigned = await it.user.takePrize(it.user.currentPrize.id);
	// 				if (isAssigned) {
	// 					it.modal.hide(Modal.ERROR_GENERAL);
	// 					return;
	// 				}
	// 			}
	// 			if (it.user.assigningCouponType === CouponSeller.TIER2) {
	// 				const isAssigned = await it.user.takePrize(it.user.currentAlternativeCoupon.id);
	// 				if (isAssigned) {
	// 					it.modal.hide(Modal.ERROR_GENERAL);
	// 					return;
	// 				}
	// 			}
	// 			$('.js-error-assign').removeAttribute('disabled');
	// 		},
	// 	})
	// 		.to('.js-error-assign', { scale: 0.95, duration: 0.05 })
	// 		.to('.js-error-assign', { scale: 1, duration: 0.05 })
	// 		.kill();
	// }

	// async grabMainCoupon() {
	// 	const it = this;

	// 	it.analytics.track(EventName.Page_Aliland_EndGamePopup_TakeCoupon);
	// 	await it.user.takePrize(it.user.endgame.id);

	// 	if (it.modal.current) {
	// 		it.modal.hide();
	// 	}

	// 	it.modal.show(Modal.ENDGAME_COUPON);

	// 	it.analytics.track(EventName.Page_Aliland_CongratulationPopup_Exposure, 'expose', {
	// 		exp_page: 'aliland_dice',
	// 		exp_page_area: 'congratulation_popup',
	// 		exp_type: 'congratulation_popup',
	// 		exp_attribute: `type:'aecoupon'`,
	// 		exp_condition: 'game_flow',
	// 		'spm-cnt': 'a2g2l.aliland.dice.congratulation_popup',
	// 	});
	// }

	handleStoreLink(target: HTMLElement) {
		const it = this;

		const coupon =
			it.user.assigningCouponTir === PrizeTir.TIR1 ? it.user.currentPrize : it.user.currentAlternativeCoupon;

		it.analytics.track(EventName.Page_Aliland_CongratulationPopup_GoToShop, 'click', {
			ae_page_type: 'aliland_dice',
			ae_page_area: 'congratulation_popup',
			ae_button_type: 'gotoshop',
			ae_object_value: JSON.stringify({
				type: 'tir',
				brandname: coupon.sellerTitle,
				sellerSeq: coupon.sellerId,
				action: coupon.link,
			}),
			ae_click_behavior: 'game_flow',
			'spm-cnt': 'a2g2l.aliland.dice.congratulation_popup_gotoshop',
		});
		it.initialData.dataProvider.goLink({ link: target.dataset.href });
	}

	handlePlatformStoreLink(target: HTMLElement) {
		const it = this;

		it.analytics.track(EventName.Page_Aliland_CongratulationPopup_GoToSale);
		it.initialData.dataProvider.goLink({ link: target.dataset.href });
	}

	handleNoCouponsSaleLink() {
		const it = this;

		// it.analytics.track(EventName.Page_Aliland_EndGamePopup_GoToSale);
		it.initialData.dataProvider.open({ option: IMenuOptions.SALES_LINK });
	}
}
