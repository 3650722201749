import { $, $$, formatNumber } from '../../helpers';

import { OtherCouponsDependencies, OtherCouponsInterface } from '.';
import { ApiInterface, IAlternativePrizes, Prize, PrizeType } from '../API';
import { baseUrl, otherCouponsCategories } from '../../data';
import { GamedataInterface } from '../GameData';
import { MapInterface } from '../GameMap';
import { PrizeTir, UserInterface } from '../User';
import { UIInterface } from '../UI';
import { AnalyticsInterface, EventName } from '../Analytics';
import { Modal, ModalsInterface } from '../Modal';
import { CouponsInterface } from '../Coupons';
import { EndgameInterface } from '../Endgame';
// @ts-ignore
import defaultLogo from '../../../img/other-coupon-logo.svg';

export class OtherCoupons implements OtherCouponsInterface {
	private static singleton: OtherCoupons;
	private initialData: GamedataInterface['initialData'];
	private api: ApiInterface;
	private map: MapInterface;
	private user: UserInterface;
	private ui: UIInterface;
	private analytics: AnalyticsInterface;
	private modal: ModalsInterface;
	private coupons: CouponsInterface;
	private endgame: EndgameInterface;
	list: IAlternativePrizes;
	private selectedCoupons: Prize[];

	inject(deps: OtherCouponsDependencies) {
		this.initialData = deps.gameData.initialData;
		this.api = deps.api;
		this.map = deps.map;
		this.user = deps.user;
		this.ui = deps.ui;
		this.analytics = deps.analytics;
		this.modal = deps.modal;
		this.coupons = deps.coupons;
		this.endgame = deps.endgame;
	}

	public static getInstance(): OtherCoupons {
		if (!OtherCoupons.singleton) OtherCoupons.singleton = new OtherCoupons();

		return OtherCoupons.singleton;
	}

	async build() {
		const it = this;

		const wrapper = $('.filter._other-coupons .filter__dropdown-list') as HTMLElement;
		wrapper.innerHTML = '';

		wrapper.append(it.getFilterButtonHTML('Все категории', 'Все категории'));

		it.list.prizeCategories.forEach((category) => {
			wrapper.append(it.getFilterButtonHTML(category.categoryName, category.categoryName));
		});

		it.filter(it.list.selectedCategory);

		$('.filter._other-coupons .js-filter-btn span').textContent = it.list.selectedCategory;
		it.buildList();
	}

	private getFilterButtonHTML(filter: string, title: string): HTMLButtonElement {
		const button = document.createElement('button');
		button.classList.add('js-filter-item');
		button.dataset.filter = filter;
		button.textContent = title;
		return button;
	}

	buildList() {
		const it = this;

		const couponsList = $('.other-coupons .other-coupons__list');
		couponsList.innerHTML = '';

		it.selectedCoupons.forEach((coupon) => {
			couponsList.append(it._getAlternativeCouponHTML(coupon));
		});
	}

	private _getAlternativeCouponHTML(coupon: Prize): HTMLDivElement {
		const card = document.createElement('div');
		card.classList.add('other-coupons__coupon');
		card.innerHTML = `
			<div class="other-coupons__coupon-inner">
				<span class="js-coupon-go-store">${coupon.sellerTitle}</span>
				<button class='js-other-coupons-coupon btn' data-coupon="${coupon.id}">
					${formatNumber(Math.floor(coupon.amount))} ₽
				</button>
			</div>
			<div class="other-coupons__coupon-desc">
				<div>${coupon.title}</div>
				<div>от ${formatNumber(Math.floor(coupon.threshold))}</span> ₽</div>
			</div>
		`;

		return card;
	}

	filter(filter: string) {
		const it = this;

		// if (target.classList.contains('_active')) return;

		// [...$$('.js-other-coupons-category')]?.forEach((btn) => btn.classList.remove('_active'));
		// target.classList.add('_active');

		let filteredCoupons: Prize[] = [];

		if (filter === 'Все категории') {
			it.list.prizeCategories.forEach((category) => {
				filteredCoupons = [...filteredCoupons, ...category.prizes].sort((a, b) => a.amount - b.amount);
			});
			$('.filter._other-coupons .js-filter-btn span').textContent = 'Все категории';
		} else {
			filteredCoupons = it.list.prizeCategories
				.find((category) => category.categoryName === filter)
				.prizes.sort((a, b) => a.amount - b.amount);
			$('.filter._other-coupons .js-filter-btn span').textContent = filter;
		}

		it.selectedCoupons = filteredCoupons;

		$('.other-coupons .js-sort-btn').classList.remove('_reversed');
		it.buildList();
		it.ui.toggleDropdown($('.filter._other-coupons .js-filter-btn') as HTMLElement, 'close');
	}

	async showList(fetchPrizes: boolean = true) {
		const it = this;

		if (fetchPrizes) {
			it.list = (await it.api.getAlternativePrizes()).data;
		}
		it.build();
		// if (!it.list?.prizeCategories) {
		// 	it.endgame.toggleGameOver();
		// 	return;
		// }

		if (it.user.currentPrize) {
			it.analytics.track(EventName.Page_Aliland_BrandPopup_ChangeCoupon, 'click', {
				ae_page_type: 'aliland_dice',
				ae_page_area: 'brand_popup',
				ae_button_type: 'change_coupon',
				ae_object_value: JSON.stringify({
					type: 'tir-1',
					brandname: it.user.currentPrize.sellerTitle,
					sellerSeq: it.user.currentPrize.sellerId,
				}),
				ae_click_behavior: 'game_flow',
				'spm-cnt': 'a2g2l.aliland.dice.brand_popup_change_coupon',
			});
		}

		it.modal.show(Modal.OTHER_COUPONS);

		it.analytics.track(EventName.Page_Aliland_TIR2Coupons_Screen);
	}

	sort() {
		const it = this;

		if ($('.other-coupons .js-sort-btn').classList.contains('_reversed')) {
			it.selectedCoupons.sort((a, b) => a.amount - b.amount);
			$('.other-coupons .js-sort-btn').classList.remove('_reversed');
		} else {
			it.selectedCoupons.sort((a, b) => b.amount - a.amount);
			$('.other-coupons .js-sort-btn').classList.add('_reversed');
		}
		it.buildList();
	}

	async grab() {
		const it = this;

		const coupon = it.user.currentAlternativeCoupon;

		it.analytics.track(EventName.Page_Aliland_BrandPopup_TakeCoupon, 'expose', {
			ae_page_type: 'aliland_dice',
			ae_page_area: 'brand_popup',
			ae_button_type: 'take_coupon',
			ae_object_value: JSON.stringify({
				type: 'tir-2',
				brandname: coupon.sellerTitle,
				sellerSeq: coupon.sellerId,
			}),
			ae_click_behavior: 'game_flow',
			'spm-cnt': 'a2g2l.aliland.dice.brand_popup_take_coupon',
		});

		($('.js-coupon-submit-store') as HTMLElement).dataset.href = coupon.link;
		it.user.assigningCouponTir = PrizeTir.TIR2;

		const isAssigned = await it.user.takePrize(coupon.id);
		if (!isAssigned) {
			it.modal.show(Modal.ERROR_GENERAL);
			return;
		}

		it.coupons.toggleSubmit(coupon);
	}

	toggleModal(target: HTMLElement) {
		const it = this;

		const coupon = it.selectedCoupons.find((coupon) => coupon.id === +target.dataset.coupon);
		it.user.currentAlternativeCoupon = coupon;

		it.modal.hide(Modal.OTHER_COUPONS);

		it.analytics.track(EventName.Page_Aliland_TIR2Coupons_SelectCoupon);

		(
			$('.coupon-other .popup__logo-img') as HTMLElement
		).style.backgroundImage = `url('${coupon.imageUrl}'), url('${defaultLogo}')`;
		$('.coupon-other .popup__heading').textContent = `Купон от ${coupon.sellerTitle}`;
		$('.coupon-other .popup__text').textContent = coupon.title;
		$('.coupon-other .js-coupon-list').innerHTML = '';
		$('.coupon-other .js-coupon-list').append(it.coupons.getHTML(coupon));

		it.modal.show(Modal.COUPON_OTHER);

		it.analytics.track(EventName.Page_Aliland_BrandPopup_Exposure, 'expose', {
			exp_page: 'aliland_dice',
			exp_page_area: 'brand_popup',
			exp_type: 'brand_popup',
			exp_attribute: JSON.stringify({
				type: 'tir',
				brandname: coupon.sellerTitle,
				sellerSeq: coupon.sellerId,
			}),
			exp_condition: 'game_flow',
			'spm-cnt': 'a2g2l.aliland.dice.brand_popup',
		});
	}
}
