import gsap from 'gsap';
import { $ } from '../../helpers';

import { CameraInterface } from '.';

export class Camera implements CameraInterface {
	private static singleton: Camera;
	private offsetX: number = 300;
	private offsetY: number = 500;
	private map: HTMLElement = $('.map__inner');

	public static getInstance(): Camera {
		if (!Camera.singleton) Camera.singleton = new Camera();

		return Camera.singleton;
	}

	getDraggableVars(): Draggable.Vars {
		const it = this;

		return {
			type: 'x,y',
			// edgeResistance: 0.65,
			bounds: {
				maxY: -(it.map.offsetHeight - window.innerHeight + it.offsetY),
				minY: it.offsetY,
				maxX: it.offsetX,
				minX: -(it.map.offsetWidth - window.innerWidth + it.offsetX),
				width: it.map.offsetWidth,
				height: it.map.offsetHeight,
			},
		};
	}

	setTo(coordinates) {
		gsap.set(this.map, coordinates);
	}

	moveTo(coordinates) {
		gsap.to(this.map, coordinates);
	}
}
