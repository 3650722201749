import gsap from 'gsap';
import { $ } from '../../helpers';

import { FigureDependencies, FigureInterface } from '.';
import { Cell } from '../Cell';
import { Coordinates, MapInterface } from '../GameMap';
import { DiceInterface } from '../Dice';
import { UserInterface } from '../User';
import { UIInterface } from '../UI';
import { CouponsInterface } from '../Coupons';
import { EndgameInterface } from '../Endgame';
import { MovesInterface } from '../Moves';

export class Figure implements FigureInterface {
	private static singleton: Figure;
	private dice: DiceInterface;
	private user: UserInterface;
	private moves: MovesInterface;
	private coupons: CouponsInterface;
	private endgame: EndgameInterface;
	private map: MapInterface;
	private ui: UIInterface;
	figureElem: HTMLElement = $('.figure');
	cellsXNum: number = 16;
	cellsYNum: number = 16;

	inject(deps: FigureDependencies) {
		this.dice = deps.dice;
		this.user = deps.user;
		this.moves = deps.moves;
		this.coupons = deps.coupons;
		this.endgame = deps.endgame;
		this.map = deps.map;
		this.ui = deps.ui;
	}

	public static getInstance(): Figure {
		if (!Figure.singleton) Figure.singleton = new Figure();

		return Figure.singleton;
	}

	getCoordinates(pos: number): Coordinates {
		const it = this;

		let xPos = 0;
		let yPos = 0;
		let cellCounter = 0;

		for (let x = 0; x < it.cellsXNum; x++) {
			for (let y = 0; y < it.cellsYNum; y++) {
				if (cellCounter === pos) break;
				xPos = y;
				yPos = x;
				cellCounter++;
			}
		}

		let xCoordinate;
		xCoordinate = (Cell.sizeX * xPos) / 2 + (Cell.sizeX / 2) * yPos;

		let yCoordinate;
		yCoordinate = (it.cellsYNum - yPos - 1) * (Cell.sizeY / 2) + (Cell.sizeY / 2) * xPos;

		return { x: xCoordinate, y: yCoordinate };
	}

	setTo(cellId: number) {
		gsap.set(this.figureElem, this.getCoordinates(cellId));
	}

	moveTo(cellId: number) {
		gsap.to(this.figureElem, this.getCoordinates(cellId));
	}

	move() {
		const it = this;

		it.ui.animationRunning = true;

		it.moves.setMovesCounter(--it.user.status.availableStepsCount);

		it.dice.hideDiceModal();

		const targetPosition =
			it.user.status.currentCellId + it.dice.lastDrop > it.map.MAX_CELLS
				? it.map.MAX_CELLS
				: it.user.status.currentCellId + it.dice.lastDrop;

		const tl = gsap.timeline({
			paused: true,
			onComplete: () => {
				it.ui.animationRunning = false;
				it.user.status.currentCellId = it.user.newCellId;
				it.user.newCellId = null;

				it.ui.updateProgress();

				if (it.user.status.currentCellId >= it.map.MAX_CELLS) {
					it.endgame.handleEndgame();
				} else {
					it.user.handleCurrentPrize();
				}
			},
		});

		tl.set(`.cell[data-cell] .cell__brand`, { rotate: 0, scale: 1, opacity: 1 });
		tl.set('.figure ._shine', { opacity: 0 });
		for (let cell = it.user.status.currentCellId; cell <= targetPosition - 1; cell++) {
			if ($(`.cell[data-cell="${cell}"] .cell__brand`)) {
				tl.set(`.cell[data-cell="${cell}"] .cell__brand`, { opacity: 0 });
			}
		}
		let counter = 1;
		for (let cell = it.user.status.currentCellId + 1; cell <= targetPosition; cell++) {
			if ($(`.cell[data-cell="${cell}"] .cell__counter`)) {
				$(`.cell[data-cell="${cell}"] .cell__counter`).textContent = counter.toString();
				counter++;
			}
		}
		for (let cell = it.user.status.currentCellId; cell <= targetPosition; cell++) {
			const cellNumber = it.map.getCellNumber(cell);
			const figureCoordinates = it.getCoordinates(cellNumber);
			const cameraCoordinates = it.map.getCoordinates(cellNumber);

			tl.addLabel(`a${cell}`, '-=.5');
			if ($(`.cell[data-cell="${cell - 1}"] .cell__fade`)) {
				tl.to(`.cell[data-cell="${cell - 1}"] .cell__fade`, { opacity: 0 }, `a${cell}`);
			}
			tl.to('.figure', figureCoordinates, `a${cell}`);
			tl.to('.map__inner', cameraCoordinates, `a${cell}`);
			if ($(`.cell[data-cell="${cell}"] .cell__fade`)) {
				tl.to(`.cell[data-cell="${cell}"] .cell__fade`, { opacity: 1 }, '-=.3');
			}
			if (cell !== it.user.status.currentCellId) {
				if ($(`.cell[data-cell="${cell}"] .cell__counter`)) {
					tl.set(`.cell[data-cell="${cell}"] .cell__counter`, { y: 0 });
					tl.to(`.cell[data-cell="${cell}"] .cell__counter`, { opacity: 1 }, `a${cell}`);
					tl.to(`.cell[data-cell="${cell}"] .cell__counter`, { opacity: 0, y: -30 });
				}
			}
		}
		if (`.cell[data-cell="${targetPosition}"] .cell__brand`) {
			tl.to(
				`.cell[data-cell="${targetPosition}"] .cell__brand`,
				{ rotate: -90, scale: 0.5, opacity: 0 },
				`a${targetPosition}`
			);
		}
		for (let cell = it.user.status.currentCellId; cell <= targetPosition - 1; cell++) {
			if ($(`.cell[data-cell="${cell}"] .cell__brand`)) {
				tl.set(`.cell[data-cell="${cell}"] .cell__brand`, { opacity: 1 });
			}
		}
		tl.to('.figure ._shine', { opacity: 1 });
		tl.play();
	}
}
