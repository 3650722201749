import { AnalyticsInterface, EventName } from './types';
import { GamedataInterface } from '../GameData';

export class Analytics implements AnalyticsInterface {
	private static singleton: Analytics;
	private trackEvent: GamedataInterface['initialData']['dataProvider']['trackEvent'];

	inject(gameData: GamedataInterface) {
		this.trackEvent = gameData.initialData.dataProvider.trackEvent;
	}

	public static getInstance(): Analytics {
		if (!Analytics.singleton) Analytics.singleton = new Analytics();

		return Analytics.singleton;
	}

	track(eventName: EventName, eventType?: 'click' | 'expose', params?: Record<string, any>) {
		const it = this;

		try {
			switch (eventName) {
				case EventName.Page_Aliland_Map_Screen:
					it.trackEvent({
						type: 'expose',
						eventName: 'Page_Aliland_Map_Screen',
						params: {
							exp_date: 'aliland_map',
							exp_type: 'map_screen',
							'spm-cnt': 'a2g2l.aliland.map.0',
						},
					});
					break;
				case EventName.Page_Aliland_Map_SkipMapOverview_Click:
					it.trackEvent({
						type: 'click',
						eventName: 'Page_Aliland_Map_SkipMapOverview_Click',
						params: {
							ae_page_type: 'aliland_map',
							ae_button_type: 'skip_map_overview',
							ae_click_behavior: 'out_of_game_flow',
							'spm-cnt': 'a2g2l.aliland.map.skip_map_overview',
						},
					});
					break;
				case EventName.Page_Aliland_Map_BrandCell_Click:
					it.trackEvent({
						type: 'click',
						eventName: 'Page_Aliland_Map_BrandCell_Click',
						params: {
							ae_page_type: 'aliland_map',
							ae_button_type: 'brandcell',
							ae_click_behavior: 'out_of_game_flow',
							'spm-cnt': 'a2g2l.aliland.map.brandcell',
						},
					});
					break;
				case EventName.Page_Aliland_Map_SuperboxCell_Click:
					it.trackEvent({
						type: 'click',
						eventName: 'Page_Aliland_Map_SuperboxCell_Click',
						params: {
							ae_page_type: 'aliland_map',
							ae_button_type: 'superboxcell',
							ae_click_behavior: 'out_of_game_flow',
							'spm-cnt': 'a2g2l.aliland.map.superboxcell',
						},
					});
					break;
				case EventName.Page_Aliland_SuperboxPopup_CouponList_Click:
					it.trackEvent({
						type: 'click',
						eventName: 'Page_Aliland_SuperboxPopup_CouponList_Click',
						params: {
							ae_page_type: 'aliland_map',
							ae_page_area: 'superbox_popup',
							ae_button_type: 'superbox_couponlist',
							ae_click_behavior: 'out_of_game_flow',
							'spm-cnt': 'a2g2l.aliland.map.superbox_popup_couponlist',
						},
					});
					break;
				case EventName.Page_Aliland_Map_LPCell_Click:
					it.trackEvent({
						type: 'click',
						eventName: 'Page_Aliland_Map_LPCell_Click',
						params: {
							ae_page_type: 'aliland_map',
							ae_button_type: 'lpcell',
							ae_click_behavior: 'out_of_game_flow',
							'spm-cnt': 'a2g2l.aliland.map.lpcell',
						},
					});
					break;
				case EventName.Page_Aliland_LPPopup_Exposure:
					it.trackEvent({
						type: 'expose',
						eventName: 'Page_Aliland_LPPopup_Exposure',
						params: {
							exp_page: 'aliland_map',
							exp_page_area: 'lp_popup',
							exp_type: 'lp_popup',
							exp_condition: 'out_of_game_flow',
							'spm-cnt': 'a2g2l.aliland.map.lp_popup',
						},
					});
					break;
				case EventName.Page_Aliland_Map_Monument_Click:
					it.trackEvent({
						type: 'click',
						eventName: 'Page_Aliland_Map_Monument_Click',
						params: {
							ae_page_type: 'aliland_map',
							ae_button_type: 'monument',
							ae_click_behavior: 'out_of_game_flow',
							'spm-cnt': 'a2g2l.aliland.map.monument',
						},
					});
					break;
				case EventName.Page_Aliland_MonumentPopup_Exposure:
					it.trackEvent({
						type: 'expose',
						eventName: 'Page_Aliland_MonumentPopup_Exposure',
						params: {
							exp_page: 'aliland_map',
							exp_page_area: 'monument_popup',
							exp_type: 'monument_popup',
							exp_condition: 'out_of_game_flow',
							'spm-cnt': 'a2g2l.aliland.map.monument_popup',
						},
					});
					break;
				case EventName.Page_Aliland_Map_RollTheDice_Click:
					it.trackEvent({
						type: 'click',
						eventName: 'Page_Aliland_Map_RollTheDice_Click',
						params: {
							ae_page_type: 'aliland_map',
							ae_button_type: 'roll_the_dice',
							ae_click_behavior: 'game_flow',
							'spm-cnt': 'a2g2l.aliland.map.dice',
						},
					});
					break;
				case EventName.Page_Aliland_TIR2Coupons_Screen:
					it.trackEvent({
						type: 'expose',
						eventName: 'Page_Aliland_TIR2Coupons_Screen',
						params: {
							exp_page: 'aliland_dice',
							exp_page_area: 'tir2_coupons',
							exp_type: 'tir2_coupons_list',
							exp_condition: 'game_flow',
							'spm-cnt': 'a2g2l.aliland.dice.tir2_coupons',
						},
					});
					break;
				case EventName.Page_Aliland_TIR2Coupons_SelectCoupon:
					it.trackEvent({
						type: 'click',
						eventName: 'Page_Aliland_TIR2Coupons_SelectCoupon',
						params: {
							ae_page_type: 'aliland_dice',
							ae_page_area: 'tir2_coupons',
							ae_button_type: 'change_coupon',
							ae_click_behavior: 'game_flow',
							'spm-cnt': 'a2g2l.aliland.dice.tir2_coupons_select_coupon',
						},
					});
					break;
				case EventName.Page_Aliland_SuperboxCoupons_SelectCoupon:
					it.trackEvent({
						type: 'click',
						eventName: 'Page_Aliland_SuperboxCoupons_SelectCoupon',
						params: {
							ae_page_type: 'aliland_superbox',
							ae_page_area: 'superbox_couponlist',
							ae_button_type: 'select_coupon',
							ae_click_behavior: 'game_flow',
							'spm-cnt': 'a2g2l.aliland.superbox.select_coupon',
						},
					});
					break;
				case EventName.Page_Aliland_LPPopup_GoToAPP_Click:
					it.trackEvent({
						type: 'click',
						eventName: 'Page_Aliland_LPPopup_GoToAPP_Click',
						params: {
							ae_page_type: 'aliland_dice',
							ae_page_area: 'lp_popup',
							ae_button_type: 'gotoapp',
							ae_click_behavior: 'game_flow',
							'spm-cnt': 'a2g2l.aliland.dice.lp_popup_gotoapp',
						},
					});
					break;
				case EventName.Page_Aliland_Map_GetTheMove_Click:
					it.trackEvent({
						eventName: 'Page_Aliland_Map_GetTheMove_Click',
						type: 'click',
						params: {
							ae_page_type: 'aliland_map',
							ae_button_type: 'get_the_move',
							ae_click_behavior: 'out_of_game_flow',
							'spm-cnt': 'a2g2l.aliland.map.getmove',
						},
					});
					break;
				case EventName.Page_Aliland_Menu_GetTheMove_Click:
					it.trackEvent({
						eventName: 'Page_Aliland_Menu_GetTheMove_Click',
						type: 'click',
						params: {
							ae_page_type: 'aliland_menu',
							ae_button_type: 'get_the_move',
							ae_click_behavior: 'out_of_game_flow',
							'spm-cnt': 'a2g2l.aliland.menu.getmove',
						},
					});
					break;
				case EventName.Page_Aliland_TaskList_Screen:
					it.trackEvent({
						eventName: 'Page_Aliland_TaskList_Screen',
						type: 'expose',
						params: {
							exp_page: 'aliland_tasklist',
							exp_type: 'tasklist_screen',
							exp_condition: 'out_of_game_flow',
							'spm-cnt': 'a2g2l.aliland.tasklist.0',
						},
					});
					break;
				case EventName.Page_Aliland_TaskList_MakeOrder:
					it.trackEvent({
						type: 'click',
						eventName: 'Page_Aliland_TaskList_MakeOrder',
						params: {
							ae_page_type: 'aliland_tasklist',
							ae_button_type: 'make_order',
							ae_click_behavior: 'out_of_game_flow',
							'spm-cnt': 'a2g2l.aliland.tasklist.make_order',
						},
					});
					break;
				case EventName.Page_Aliland_TaskList_Share_Click:
					it.trackEvent({
						type: 'click',
						eventName: 'Page_Aliland_TaskList_Share_Click',
						params: {
							ae_page_type: 'aliland_tasklist',
							ae_button_type: 'share_click',
							ae_click_behavior: 'out_of_game_flow',
							'spm-cnt': 'a2g2l.aliland.tasklist.share_click',
						},
					});
					break;
				case EventName.Page_Aliland_Map_OpenMenu:
					it.trackEvent({
						type: 'click',
						eventName: 'Page_Aliland_Map_OpenMenu',
						params: {
							ae_page_type: 'aliland_map',
							ae_button_type: 'open_menu',
							ae_click_behavior: 'out_of_game_flow',
							'spm-cnt': 'a2g2l.aliland.map.open_menu',
						},
					});
					break;
				case EventName.Page_Aliland_Menu_Rewards_Click:
					it.trackEvent({
						type: 'click',
						eventName: 'Page_Aliland_Menu_Rewards_Click',
						params: {
							ae_page_type: 'aliland_menu',
							ae_button_type: 'rewards',
							ae_click_behavior: 'out_of_game_flow',
							'spm-cnt': 'a2g2l.aliland.menu.rewards',
						},
					});
					break;
				case EventName.Page_Aliland_Menu_RewardsCoupons_Click:
					it.trackEvent({
						type: 'click',
						eventName: 'Page_Aliland_Menu_RewardsCoupons_Click',
						params: {
							ae_page_type: 'aliland_menu',
							ae_button_type: 'rewards_coupons',
							ae_click_behavior: 'out_of_game_flow',
							'spm-cnt': 'a2g2l.aliland.menu.rewards_coupons',
						},
					});
					break;
				case EventName.Page_Aliland_Menu_RewardsSuperbox_Click:
					it.trackEvent({
						type: 'click',
						eventName: 'Page_Aliland_Menu_RewardsSuperbox_Click',
						params: {
							ae_page_type: 'aliland_menu',
							ae_button_type: 'rewards_superbox',
							ae_click_behavior: 'out_of_game_flow',
							'spm-cnt': 'a2g2l.aliland.menu.rewards_superbox',
						},
					});
					break;
				case EventName.Page_Aliland_Menu_RewardsLP_Click:
					it.trackEvent({
						type: 'click',
						eventName: 'Page_Aliland_Menu_RewardsLP_Click',
						params: {
							ae_page_type: 'aliland_menu',
							ae_button_type: 'rewards_lp',
							ae_click_behavior: 'out_of_game_flow',
							'spm-cnt': 'a2g2l.aliland.menu.rewards_lp',
						},
					});
					break;
				case EventName.Page_Aliland_Menu_GameTerms_Click:
					it.trackEvent({
						type: 'click',
						eventName: 'Page_Aliland_Menu_GameTerms_Click',
						params: {
							ae_page_type: 'aliland_menu',
							ae_button_type: 'game_terms',
							ae_click_behavior: 'out_of_game_flow',
							'spm-cnt': 'a2g2l.aliland.menu.game_terms',
						},
					});
					break;
				case EventName.Page_Aliland_Menu_GoToSale_Click:
					it.trackEvent({
						type: 'click',
						eventName: 'Page_Aliland_Menu_GoToSale_Click',
						params: {
							ae_page_type: 'aliland_menu',
							ae_button_type: 'gotosale',
							ae_click_behavior: 'out_of_game_flow',
							'spm-cnt': 'a2g2l.aliland.menu.gotosale',
						},
					});
					break;
				case EventName.Page_Aliland_Menu_GoToSupport_Click:
					it.trackEvent({
						type: 'click',
						eventName: 'Page_Aliland_Menu_GoToSupport_Click',
						params: {
							ae_page_type: 'aliland_menu',
							ae_button_type: 'support',
							ae_click_behavior: 'out_of_game_flow',
							'spm-cnt': 'a2g2l.aliland.menu.support',
						},
					});
					break;
				case EventName.Page_Aliland_Menu_SupportFloatButton_Click:
					it.trackEvent({
						type: 'click',
						eventName: 'Page_Aliland_Menu_SupportFloatButton_Click',
						params: {
							ae_page_type: 'aliland_menu',
							ae_button_type: 'support_floatbutton',
							ae_click_behavior: 'out_of_game_flow',
							'spm-cnt': 'a2g2l.aliland.menu.support_floatbutton',
						},
					});
					break;
				case EventName.Page_Aliland_Map_BackToApp:
					it.trackEvent({
						type: 'click',
						eventName: 'Page_Aliland_Map_BackToApp',
						params: {
							ae_page_type: 'aliland_map',
							ae_button_type: 'backtoapp',
							ae_click_behavior: 'out_of_game_flow',
							'spm-cnt': 'a2g2l.aliland.map.backtoapp',
						},
					});
					break;
				case EventName.Page_Aliland_Error_Screen:
					it.trackEvent({
						eventName: 'Page_Aliland_Error_Screen',
						type: 'expose',
						params: {
							exp_page: 'aliland_error',
							exp_type: 'error',
							'spm-cnt': 'a2g2l.aliland.error.0',
						},
					});
					break;
				case EventName.Page_Aliland_EndGamePopup_GoToSale:
					it.trackEvent({
						type: 'click',
						eventName: 'Page_Aliland_EndGamePopup_GoToSale',
						params: {
							ae_page_type: 'aliland_dice',
							ae_page_area: 'endgame_popup',
							ae_button_type: 'gotosale',
							ae_object_value: 'aecoupon_not_available',
							ae_click_behavior: 'game_flow',
							'spm-cnt': 'a2g2l.aliland.dice.endgame_popup_gotosale',
						},
					});
					break;
				//
				case EventName.Page_Aliland_EndGame_Screen:
					it.trackEvent({
						eventName: 'Page_Aliland_EndGame_Screen',
						type: 'expose',
						params: {
							exp_page: 'aliland_endgame',
							exp_type: 'endgame_screen',
							'spm-cnt': 'a2g2l.aliland.endgame.0',
						},
					});
					break;
				case EventName.Page_Aliland_Menu_CouponList_Click:
					it.trackEvent({
						type: 'click',
						eventName: 'Page_Aliland_Menu_CouponList_Click',
						params: {
							ae_page_type: 'aliland_menu',
							ae_button_type: 'couponlist',
							ae_click_behavior: 'out_of_game_flow',
							'spm-cnt': 'a2g2l.aliland.menu.couponlist',
						},
					});
					break;
				case EventName.Page_Aliland_CongratulationPopup_GoToSale:
					it.trackEvent({
						type: 'click',
						eventName: 'Page_Aliland_CongratulationPopup_GoToSale',
						params: {
							ae_page_type: 'aliland_dice',
							ae_page_area: 'congratulation_popup',
							ae_button_type: 'gotosale',
							ae_click_behavior: 'game_flow',
							'spm-cnt': 'a2g2l.aliland.dice.congratulation_popup_gotosale',
						},
					});
					break;
				case EventName.Page_Aliland_EndGamePopup_TakeCoupon:
					it.trackEvent({
						eventName: 'Page_Aliland_EndGamePopup_TakeCoupon',
						type: 'click',
						params: {
							ae_page_type: 'aliland_dice',
							ae_page_area: 'endgame_popup',
							ae_button_type: 'take_coupon',
							ae_object_value: 'aecoupon_available',
							ae_click_behavior: 'game_flow',
							'spm-cnt': 'a2g2l.aliland.dice.endgame_popup_take_coupon',
						},
					});
					break;

				case EventName.Page_Aliland_BrandPopup_Exposure:
				case EventName.Page_Aliland_BrandPopup_GotoShop_Click:
				case EventName.Page_Aliland_SuperboxPopup_Exposure:
				case EventName.Page_Aliland_SuperboxCoupons_Screen:
				case EventName.Page_Aliland_MonumentPopup_GoToStore_Click:
				case EventName.Page_Aliland_BrandPopup_TakeCoupon:
				case EventName.Page_Aliland_CongratulationPopup_Exposure:
				case EventName.Page_Aliland_CongratulationPopup_GoToShop:
				case EventName.Page_Aliland_BrandPopup_ChangeCoupon:
				case EventName.Page_Aliland_CouponList_GotoShop_Click:
				case EventName.Page_Aliland_SuperboxPopup_Exchange_Click:
				case EventName.Page_Aliland_TaskList_Task:
				case EventName.Page_Aliland_EndGamePopup_Exposure:
					it.trackEvent({
						eventName,
						type: eventType,
						params,
					});
					break;
			}
		} catch (err) {
			console.error(err);
		}
	}
}
