import { $, $$ } from './helpers';

import { IInitialGameData, initialGameData } from './initialGameData';
import { initProxi } from './requestProxiModel';
import { UI, UIInterface } from './modules/UI';
import { Camera, CameraInterface } from './modules/Camera';
import { GameMap, MapInterface } from './modules/GameMap';
import { Dice, DiceInterface } from './modules/Dice';
import { Tutorial, TutorialInterface } from './modules/Tutorial';
import { Api, ApiInterface } from './modules/API';
import { User, UserInterface } from './modules/User';
import { Figure, FigureInterface } from './modules/Figure';
import { Modals, Modal, ModalsInterface } from './modules/Modal';
import { OtherCoupons, OtherCouponsInterface } from './modules/OtherCoupons';
import { Coupons, CouponsInterface } from './modules/Coupons';
import { Moves, MovesInterface } from './modules/Moves';
import { GameData, GamedataInterface } from './modules/GameData';
import { Analytics, AnalyticsInterface } from './modules/Analytics';
import { Endgame, EndgameInterface } from './modules/Endgame';
import { Brand, BrandInterface } from './modules/Brand';
import { Prizes, PrizesInterface } from './modules/Prizes';
import { Form, FormInterface } from './modules/Form.ts';
import { Monuments, MonumentsInterface } from './modules/Monuments';
import { Boxes, BoxesInterface } from './modules/Boxes';
import { Points, PointsInterface } from './modules/Points';

class Game {
	private gameData: GamedataInterface;
	private analytics: AnalyticsInterface;
	private api: ApiInterface;
	private user: UserInterface;
	private dice: DiceInterface;
	private map: MapInterface;
	private figure: FigureInterface;
	private camera: CameraInterface;
	private tutorial: TutorialInterface;
	private brand: BrandInterface;
	private coupons: CouponsInterface;
	private otherCoupons: OtherCouponsInterface;
	private moves: MovesInterface;
	private modal: ModalsInterface;
	private ui: UIInterface;
	private prizes: PrizesInterface;
	private endgame: EndgameInterface;
	// private form: FormInterface;
	private monuments: MonumentsInterface;
	private boxes: BoxesInterface;
	private points: PointsInterface;

	async init(initialData: IInitialGameData) {
		const it = this;

		it.createInstances(initialData);
		it.injectDependencies();

		it.modal.initListeners();

		await it.user.init();
		await it.map.initMap();
		it.moves.setUiLinks();
		it.handleErrorScreen();

		// const isGameOver = await it.api.isGameOver();
		// if (isGameOver) {
		// 	it.ui.showGameOver();
		// 	return;
		// }
		it.map.initDrag();
		it.map.enableZoom();
		it.moves.init();
		// it.ui.setRulesData();
		it.coupons.updateList();
		it.boxes.setUserBoxesCounter();
		it.monuments.setMonuments();
		// it.form.initForm();

		if (!it.user.status.hasPassedTutorial) {
			it.tutorial.init();
			it.tutorial.initListeners();
		}

		// if (it.user.status.currentPosition === it.map.MAX_CELLS) {
		// 	await it.user.goToFinish();
		// 	await it.endgame.handleEndgame();
		// }

		it.initEventListeners();
		it.setInitialPosition();
		it.ui.hideLoader();
	}

	private createInstances(initialData: IInitialGameData) {
		const it = this;

		it.gameData = GameData.getInstance();
		it.gameData.setData(initialData);

		it.analytics = Analytics.getInstance();
		it.api = Api.getInstance();
		it.user = User.getInstance();
		it.dice = Dice.getInstance();
		it.map = GameMap.getInstance();
		it.figure = Figure.getInstance();
		it.camera = Camera.getInstance();
		it.tutorial = Tutorial.getInstance();
		it.brand = Brand.getInstance();
		it.coupons = Coupons.getInstance();
		it.otherCoupons = OtherCoupons.getInstance();
		it.moves = Moves.getInstance();
		it.modal = Modals.getInstance();
		it.ui = UI.getInstance();
		it.prizes = Prizes.getInstance();
		it.endgame = Endgame.getInstance();
		// it.form = Form.getInstance();
		it.monuments = Monuments.getInstance();
		it.boxes = Boxes.getInstance();
		it.points = Points.getInstance();
	}

	private injectDependencies() {
		const it = this;

		it.analytics.inject(it.gameData);
		it.api.inject(it.gameData, it.ui);
		it.user.inject({
			api: it.api,
			dice: it.dice,
			ui: it.ui,
			coupons: it.coupons,
			otherCoupons: it.otherCoupons,
			boxes: it.boxes,
			points: it.points,
			moves: it.moves,
			endgame: it.endgame,
		});
		it.dice.inject({ analytics: it.analytics, user: it.user, map: it.map, moves: it.moves, ui: it.ui });
		it.map.inject({
			analytics: it.analytics,
			api: it.api,
			brand: it.brand,
			camera: it.camera,
			modal: it.modal,
			monuments: it.monuments,
			prizes: it.prizes,
			boxes: it.boxes,
			points: it.points,
		});
		it.figure.inject({
			dice: it.dice,
			user: it.user,
			moves: it.moves,
			coupons: it.coupons,
			endgame: it.endgame,
			map: it.map,
			ui: it.ui,
		});
		it.tutorial.inject({ api: it.api, analytics: it.analytics, map: it.map, ui: it.ui, user: it.user });
		it.brand.inject({ gameData: it.gameData, analytics: it.analytics, modal: it.modal });
		it.coupons.inject({
			gameData: it.gameData,
			map: it.map,
			user: it.user,
			ui: it.ui,
			analytics: it.analytics,
			modal: it.modal,
			endgame: it.endgame,
		});
		it.otherCoupons.inject({
			gameData: it.gameData,
			api: it.api,
			map: it.map,
			user: it.user,
			ui: it.ui,
			analytics: it.analytics,
			modal: it.modal,
			coupons: it.coupons,
			endgame: it.endgame,
		});
		it.moves.inject({
			gameData: it.gameData,
			api: it.api,
			analytics: it.analytics,
			modal: it.modal,
			user: it.user,
		});
		it.modal.inject({ gameData: it.gameData, analytics: it.analytics, coupons: it.coupons, endgame: it.endgame });
		it.ui.inject({
			gameData: it.gameData,
			analytics: it.analytics,
			modal: it.modal,
			coupons: it.coupons,
			user: it.user,
			otherCoupons: it.otherCoupons,
			endgame: it.endgame,
		});
		it.prizes.inject({
			analytics: it.analytics,
			gameData: it.gameData,
			api: it.api,
			modal: it.modal,
			ui: it.ui,
			user: it.user,
			coupons: it.coupons,
			boxes: it.boxes,
			points: it.points,
		});
		it.endgame.inject({
			gameData: it.gameData,
			analytics: it.analytics,
			user: it.user,
			modal: it.modal,
			map: it.map,
			figure: it.figure,
			camera: it.camera,
			prizes: it.prizes,
			coupons: it.coupons,
			otherCoupons: it.otherCoupons,
			boxes: it.boxes,
			ui: it.ui,
		});
		// it.form.inject({ gameData: it.gameData, api: it.api, modal: it.modal });
		it.monuments.inject({
			gameData: it.gameData,
			analytics: it.analytics,
			api: it.api,
			map: it.map,
			modal: it.modal,
		});
		it.boxes.inject({
			gameData: it.gameData,
			analytics: it.analytics,
			modal: it.modal,
			user: it.user,
			ui: it.ui,
			coupons: it.coupons,
		});
		it.points.inject({ analytics: it.analytics, modal: it.modal, user: it.user, gameData: it.gameData });
	}

	private initEventListeners() {
		const it = this;

		it.handleLoginScreen();
		it.handleMenu();
		it.handlePrizeMenu();
		it.handleBoxes();
		// it.handlePoints();
		it.handleDice();
		it.handleBrand();
		it.handleMonument();
		it.handleRules();
		it.handleMoves();
		it.handleCouponsList();
		it.handleCoupon();
		it.handleEndgame();
	}

	private setInitialPosition() {
		const it = this;

		const cellId = it.user.status.hasPassedTutorial ? it.user.status.currentCellId : it.user.status.totalCellsCount;

		it.figure.setTo(it.map.getCellNumber(it.user.status.currentCellId));
		it.map.setInitialCell(cellId);
		it.camera.setTo(it.map.getCoordinates(it.map.getCellNumber(cellId)));
	}

	private handleLoginScreen() {
		const it = this;

		$('.js-login-btn.btn').addEventListener('click', () => it.ui.hideLogin());
	}

	private handleMenu() {
		const it = this;

		$('.js-back-app-btn')?.addEventListener('click', async () => it.ui.handleBackBtn());
		$('.js-prizes-btn')?.addEventListener('click', () => it.prizes.togglePrizesScreen());
		$('.js-menu-btn')?.addEventListener('click', () => it.ui.handleMenuBtn());
		$('.js-menu-rules')?.addEventListener('click', () => it.ui.toggleRulesScreen());
		$('.js-menu-prizes')?.addEventListener('click', () => it.prizes.togglePrizesScreen());
		$('.js-menu-moves')?.addEventListener('click', () => it.moves.toggleGetMoveModal());
		[...$$('.js-menu-sale')]?.forEach((btn) => {
			btn.addEventListener('click', (e) => it.ui.menuSaleHandler(e.currentTarget as HTMLElement));
		});
		$('.js-menu-support')?.addEventListener('click', (e) =>
			it.ui.menuSupportHandler(e.currentTarget as HTMLElement)
		);
	}

	private handlePrizeMenu() {
		const it = this;

		// $('.js-prize-giveaway').addEventListener('click', () => it.prizes.toggleGiveaway());
		$('.js-prize-coupons')?.addEventListener('click', () => it.prizes.togglePrizesCouponsListScreen());
		$('.js-prize-boxes')?.addEventListener('click', () => it.prizes.togglePrizesBoxesScreen());
		// $('.js-prize-points')?.addEventListener('click', () => it.prizes.togglePrizesPointsScreen());

		$('.js-prize-tab-coupons').addEventListener('click', () => it.prizes.handlePrizesListTabs('coupons'));
		$('.js-prize-tab-boxes').addEventListener('click', () => it.prizes.handlePrizesListTabs('boxes'));
		$('.js-prize-tab-prizes').addEventListener('click', () => it.prizes.handlePrizesListTabs('prizes'));

		$('.js-prizes-list-close-coupons-list').addEventListener('click', () =>
			it.prizes.togglePrizesCouponsListScreen()
		);

		[...$$('.js-prizes-list-close-my-prizes')].forEach((button) =>
			button.addEventListener('click', () => it.prizes.togglePrizesCouponsListScreen())
		);
		$('.js-prizes-list-close-boxes').addEventListener('click', () => it.prizes.togglePrizesCouponsListScreen());
		$('.js-prizes-list-change-boxes').addEventListener('click', () => it.prizes.toggleEmptySupercouponsToBoxes());

		// $('.js-giveaway-return').addEventListener('click', () =>)
		// $('.js-giveaway-form').addEventListener('click', () => it.form.toggleForm());

		[...$$('.js-filter-btn')]?.forEach((btn) => {
			btn.addEventListener('click', (e) => {
				e.stopPropagation();
				it.ui.toggleDropdown(e.currentTarget as HTMLElement);
			});
		});
	}

	private handleBoxes() {
		const it = this;

		$('.js-box-change')?.addEventListener('click', () => it.prizes.togglePrizesBoxesScreen());
		$('.js-box-gain-change')?.addEventListener('click', () => it.prizes.togglePrizesBoxesScreen());

		$('.prizes-list')?.addEventListener('click', (e) => {
			const target = e.target as HTMLElement;
			if (!$('.prizes-list__section._boxes').classList.contains('_active')) {
				return;
			}
			if (target.classList.contains('js-box-price-btn')) {
				it.boxes.takeBox(+target.dataset.id);
			}
			it.ui.toggleDropdown($('.filter._boxes .js-filter-btn') as HTMLElement, 'close');
		});

		$('.filter._boxes')?.addEventListener('click', (e) => {
			const target = e.target as HTMLElement;
			if (target.classList.contains('js-filter-item')) {
				e.stopPropagation();
				it.boxes.filter(target.dataset.filter);
			}
		});
		$('.js-boxes-sort-btn')?.addEventListener('click', (e) => {
			e.stopPropagation();
			it.boxes.sort();
		});

		$('.js-supercoupon-grab')?.addEventListener('click', () => it.boxes.grab());
		$('.js-supercoupon-remind-grab')?.addEventListener('click', () => it.boxes.toggleSupercouponModal());
		$('.js-supercoupon-remind-leave')?.addEventListener('click', () => it.boxes.leave());
		$('.js-supercoupon-submit-store')?.addEventListener('click', (e) =>
			it.boxes.goToStore(e.target as HTMLElement)
		);
		$('.js-supercoupon-submit-return')?.addEventListener('click', () => it.boxes.toggleSupercouponSubmit());
	}

	private handlePoints() {
		const it = this;

		// $('.js-points-to-game')?.addEventListener('click', () => it.points.togglePointsModal());
		// $('.js-points-gain-open-app')?.addEventListener('click', () => it.points.goToApp());
		// $('.js-points-return')?.addEventListener('click', () => it.prizes.togglePrizesPointsScreen());
		// $('.js-points-app')?.addEventListener('click', () => it.points.handleListScreenAppBtn());

		// $('.js-points-return').addEventListener('click', () => it.prizes.togglePrizesPointsScreen());
	}

	private handleDice() {
		const it = this;

		[...$$('.js-throw-dice')]?.forEach((btn) => btn?.addEventListener('click', () => it.user.move()));
		$('.js-make-move')?.addEventListener('click', async () => it.figure.move());
	}

	private handleBrand() {
		const it = this;

		$('.js-brand-store-btn')?.addEventListener('click', () => it.brand.goToStore());
	}

	private handleMonument() {
		const it = this;

		$('.js-monument-store-btn')?.addEventListener('click', () => it.monuments.goToStore());
	}

	private handleRules() {
		const it = this;

		[...$$('.js-rule')]?.forEach((btn) =>
			btn.addEventListener('click', (e) => it.ui.toggleRulePane(e.currentTarget as HTMLElement))
		);
		$('.js-rules-support')?.addEventListener('click', (e) =>
			it.ui.rulesSupportHandler(e.currentTarget as HTMLElement)
		);
	}

	private handleMoves() {
		const it = this;

		// if (it.user.status.countExtraSteps && it.user.status.countExtraSteps > 0) {
		// 	it.moves.toggleAddMoveModal(it.user.status.countExtraSteps);
		// }

		$('.js-add-move-back')?.addEventListener('click', () => it.moves.toggleAddMoveModal(0));
		$('.get-move')?.addEventListener('click', (e) => {
			const target = e.target as HTMLElement;
			if (target.classList.contains('js-get-move-link')) {
				it.moves.handleGetMoveLink(target);
			}
		});
		$('.js-get-move-choose-product')?.addEventListener('click', (e) =>
			it.moves.handleChooseProductLink(e.currentTarget as HTMLElement)
		);
		$('.js-get-move-share')?.addEventListener('click', (e) =>
			it.moves.handleShareBtn((e.currentTarget as HTMLElement).dataset.href)
		);
	}

	private handleCouponsList() {
		const it = this;

		// $('.js-coupons-back')?.addEventListener('click', () => it.ui.toggleMenuCouponsScreen());
		$('.coupons-list').addEventListener('click', (e) => {
			const target = e.target as HTMLElement;
			if (!target.classList.contains('js-coupon-go-store')) return;
			it.coupons.goToStore(target);
		});
	}

	private handleCoupon() {
		const it = this;

		if (it.user.currentPrize) {
			it.user.handleCurrentPrize();
		}

		$('.js-coupon-remind-grab')?.addEventListener('click', () => it.coupons.toggleModal());
		$('.js-coupon-remind-leave')?.addEventListener('click', () => it.coupons.leave());
		$('.js-coupon-grab')?.addEventListener('click', async () => it.coupons.grab());
		$('.js-coupon-other-grab')?.addEventListener('click', async () => it.otherCoupons.grab());
		$('.js-coupon-submit-play')?.addEventListener('click', () => it.coupons.toggleSubmit());
		[...$$('.js-endgame-restart')]?.forEach((btn) => btn.addEventListener('click', () => it.endgame.restartGame()));
		$('.js-coupon-choose')?.addEventListener('click', async () => it.otherCoupons.showList(true));
		[...$$('.js-coupon-submit-store')]?.forEach((btn) =>
			btn.addEventListener('click', (e) => it.coupons.handleStoreLink(e.currentTarget as HTMLElement))
		);
		$('.other-coupons')?.addEventListener('click', (e) => {
			const target = e.target as HTMLElement;
			if (target.classList.contains('js-other-coupons-coupon')) {
				e.stopPropagation();
				it.otherCoupons.toggleModal(target);
				return;
			}
			if (target.classList.contains('js-coupon-go-store')) {
				it.coupons.goToStore(target);
			}
			it.ui.toggleDropdown($('.filter._other-coupons .js-filter-btn') as HTMLElement, 'close');
		});
		$('.js-coupon-empty-choose')?.addEventListener('click', async () => it.otherCoupons.showList(true));

		$('.filter._other-coupons')?.addEventListener('click', (e) => {
			const target = e.target as HTMLElement;
			if (target.classList.contains('js-filter-item')) {
				e.stopPropagation();
				it.otherCoupons.filter(target.dataset.filter);
			}
		});
		$('.other-coupons .js-sort-btn')?.addEventListener('click', (e) => {
			e.stopPropagation();
			it.otherCoupons.sort();
		});
		$('.js-coupon-no-coupons-choose')?.addEventListener('click', () => it.coupons.handleNoCouponsSaleLink());
	}

	private handleErrorScreen() {
		const it = this;

		$('.js-error-general')?.addEventListener('click', () => it.ui.handleErrorBtn());
		$('.js-error-load')?.addEventListener('click', () => it.ui.handleErrorBtn());
		$('.js-game-over')?.addEventListener('click', (e) => it.ui.menuSaleHandler(e.target as HTMLElement));
	}

	private handleEndgame() {
		const it = this;

		$('.js-endgame-box-restart')?.addEventListener('click', () => it.endgame.toggleBoxModal());
		$('.js-endgame-box-store')?.addEventListener('click', () => it.endgame.handleBoxesList());
		$('.js-endgame-complete-restart')?.addEventListener('click', () => it.endgame.toggleCompleteModal());
		$('.js-endgame-complete-store')?.addEventListener('click', (e) => it.endgame.goToStore());
		$('.js-gameover-store')?.addEventListener('click', (e) => it.endgame.gameOverGoToStore());

		// $('.js-endgame-coupon-remind-leave')?.addEventListener('click', () => it.endgame.leave());
		// $('.js-endgame-coupon-remind-grab')?.addEventListener('click', () => it.endgame.togglePlatformCoupon());
		// $('.js-endgame-coupon-grab')?.addEventListener('click', () => it.endgame.grab());
		// $('.js-endgame-coupon-submit-store')?.addEventListener('click', (e) =>
		// 	it.endgame.goToStore(e.target as HTMLElement)
		// );
		// $('.js-endgame-coupon-submit-restart')?.addEventListener('click', () => it.endgame.restartGame());
	}
}

// @ts-ignore
window.game = new Game();

if (process.env.NODE_ENV === 'development') {
	// @ts-ignore
	window.game.init(initialGameData);
} else {
	initProxi();
}
