import { PrizeTir, UserDependencies, UserInterface } from '.';
import { ApiInterface, IPrizeCategories, IUserPrizesResponse, IUserStatusInfo, Prize, PrizeType, Status } from '../API';
import { BoxesInterface } from '../Boxes';
import { CouponsInterface } from '../Coupons';
import { DiceInterface } from '../Dice';
import { EndgameInterface } from '../Endgame';
import { MovesInterface } from '../Moves';
import { OtherCouponsInterface } from '../OtherCoupons';
import { PointsInterface } from '../Points';
import { TutorialInterface } from '../Tutorial';
import { UIInterface } from '../UI';

export class User implements UserInterface {
	private static singleton: User;
	private api: ApiInterface;
	private dice: DiceInterface;
	private ui: UIInterface;
	private coupons: CouponsInterface;
	private otherCoupons: OtherCouponsInterface;
	private boxes: BoxesInterface;
	private points: PointsInterface;
	private moves: MovesInterface;
	private endgame: EndgameInterface;

	status: IUserStatusInfo = null;
	prizes: IUserPrizesResponse = null;
	currentPrize: Prize = null;
	currentAlternativeCoupon: Prize = null;
	currentCouponMoveId: number = null;
	assigningCouponTir: PrizeTir;
	isAlternativeCoupons: boolean = false;
	newCellId: number = null;
	currentState: Status = null;

	static getInstance(): User {
		if (!User.singleton) User.singleton = new User();

		return User.singleton;
	}

	inject(deps: UserDependencies) {
		this.api = deps.api;
		this.dice = deps.dice;
		this.ui = deps.ui;
		this.coupons = deps.coupons;
		this.otherCoupons = deps.otherCoupons;
		this.boxes = deps.boxes;
		this.moves = deps.moves;
		this.points = deps.points;
		this.endgame = deps.endgame;
	}

	async init() {
		await this.getStatus();
		// await this.getPrizes();
		// this.ui.setUserPrizes();
	}

	async getStatus() {
		const it = this;
		it.status = (await this.api.getStatus()).data;
		it.currentPrize = it.status.currentState.prize;
		it.ui.updateProgress();

		if (it.status.currentState?.newSteps > 0 && it.status.hasPassedTutorial) {
			it.moves.toggleAddMoveModal(it.status.currentState.newSteps);
		}
	}

	async getPrizes() {
		this.prizes = (await this.api.getUserPrizes()).data;
	}

	async takePrize(prizeId: number) {
		const it = this;

		const { status } = (await it.api.takePrize({ prizeId })).data;

		if (status !== Status.success) {
			return false;
		}

		return true;
	}

	async dismissPrize() {
		const it = this;

		await it.api.takePrize({ dismiss: true });
		it.clearCurrentPrize();
	}

	clearCurrentPrize() {
		const it = this;

		it.currentPrize = null;
		it.currentAlternativeCoupon = null;
		it.currentCouponMoveId = null;
		it.currentState = null;
	}

	async move() {
		const it = this;

		if (it.ui.animationRunning) return;

		if (it.status.availableStepsCount === 0) {
			it.moves.toggleGetMoveModal();
			return;
		}

		it.clearCurrentPrize();

		const { receivedPrize, newCellId, status } = (await it.api.makeStep()).data;

		const diceNumber = Math.floor(Math.random() * (6 - 1 + 1) + 1);

		it.dice.handleTrow(diceNumber);
		it.currentPrize = receivedPrize;
		it.newCellId = newCellId;
		it.currentState = status;

		if (receivedPrize?.type === PrizeType.superbox) {
			it.status.receivedChestsCount += receivedPrize.amount;
		}
	}

	async handleCurrentPrize() {
		const it = this;

		if (it.currentState === Status.out_of_prizes && it.newCellId !== it.status.totalCellsCount) {
			const list = (await it.api.getAlternativePrizes()).data;

			if (list.prizeCategories.length) {
				it.coupons.toggleEmptyModal();
				return;
			} else {
				it.dismissPrize();
				it.coupons.toggleNoCouponsModal();
				return;
			}
		}

		switch (it.currentPrize.type) {
			case PrizeType.seller_coupon:
				it.coupons.toggleModal();
				break;
			case PrizeType.superbox:
				it.boxes.toggleBoxesGainModal();
				break;
			case PrizeType.loyalty_points:
				it.points.togglePointsGainModal();
				break;
		}
	}
}
