import { ILink, IMenuOptions, initialGameData, IOpen, IRequest, ITrackEvent } from './initialGameData';
import Postmate from 'postmate';

export function initProxi() {
	const requestMap = {};

	function makeHash(type) {
		return type + Math.random();
	}

	function dispatchToProxy(taskName: string) {
		const payload = {
			body: {},
			message: `Task "${taskName}" was emitted`,
		};

		const open = ({ option } : IOpen) => {
			option = IMenuOptions[option];
			payload.body = { option }

			this.emit(taskName, payload);
			return null;
		}

		const apiProxy = ({ api, method, body } : IRequest) => {
			const hash = makeHash(api);
			payload.body = { api, method, body, hash };

			this.emit(taskName, payload);

			return new Promise((res) => {
				requestMap[hash] = res;
			});
		};

		const trackEventProxy = ({ type, eventName, params }: ITrackEvent) => {
			payload.body = { type, eventName, params };
			this.emit(taskName, payload);
			return null;
		};

		const goLink = ({ link }: ILink) => {
			payload.body = { link };
			this.emit(taskName, payload);
			return null;
		};

		const shareLink = ({ link }: ILink) => {
			payload.body = { link };
			this.emit(taskName, payload);
			return null;
		};

		const goBack = () => {
			this.emit(taskName, payload);
			return null;
		};

		const router = {
			goBack: goBack,
			goLink: goLink,
			trackEvent: trackEventProxy,
			apiCall: apiProxy,
			shareLink: shareLink,
			open: open,
			fallback: () => {
				payload.message = `You need to define event handler for "${taskName}" task.`;
				this.emit(taskName, payload);
			},
		};

		if (router[taskName]) {
			return router[taskName];
		}

		return router.fallback;
	}

	const handshake = new Postmate.Model({
		responseHandler: ({ hash, data }) => {
			if (hash) {
				// После получения данных из бриджа, резолвим промис
				// с полученными данными
				requestMap[hash](data);
			}
		},
	});

	handshake.then((parent) => {
		/*
		 * Устанавливаем связь между игрой и бриджом,
		 * переопределяем функцию вызова API
		 * и вызываем инициализацию игры
		 */
		const dataProvider = initialGameData.dataProvider;
		for (const task in dataProvider) {
			dataProvider[task] = dispatchToProxy.call(parent, task);
		}

		// @ts-ignore
		window.game.init(initialGameData);
	});
}
