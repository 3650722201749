import { PointsDependencies, PointsInterface } from '.';
import { $, formatNumber, num2str } from '../../helpers';

import { AnalyticsInterface, EventName } from '../Analytics';
import { GamedataInterface } from '../GameData';
import { Modal, ModalsInterface } from '../Modal';
import { UserInterface } from '../User';

export class Points implements PointsInterface {
	private static singleton: Points;
	private initialData: GamedataInterface['initialData'];
	private analytics: AnalyticsInterface;
	private modal: ModalsInterface;
	private user: UserInterface;

	inject(deps: PointsDependencies) {
		this.initialData = deps.gameData.initialData;
		this.analytics = deps.analytics;
		this.user = deps.user;
		this.modal = deps.modal;
	}

	public static getInstance(): Points {
		if (!Points.singleton) Points.singleton = new Points();

		return Points.singleton;
	}

	togglePointsModal() {
		const it = this;

		if (it.modal.current === Modal.POINTS) {
			it.modal.hide(Modal.POINTS);
			return;
		}

		it.analytics.track(EventName.Page_Aliland_Map_LPCell_Click);

		it.modal.show(Modal.POINTS);

		it.analytics.track(EventName.Page_Aliland_LPPopup_Exposure);
	}

	togglePointsGainModal() {
		const it = this;

		if (it.modal.current === Modal.POINTS_GAIN) {
			it.modal.hide(Modal.POINTS_GAIN);
			return;
		}
		// it.analytics.track(EventName.Page_Aliland_Menu_CouponList_Click);
		$('.points-gain__num').textContent = it.user.currentPrize.amount.toString();
		$('.points-gain__noun').textContent = num2str(it.user.currentPrize.amount, ['балл', 'балла', 'баллов']);
		it.modal.show(Modal.POINTS_GAIN);

		it.analytics.track(EventName.Page_Aliland_LPPopup_Exposure);
	}

	goToApp() {
		const it = this;

		it.togglePointsGainModal();
		it.initialData?.dataProvider?.goBack();

		it.analytics.track(EventName.Page_Aliland_LPPopup_GoToAPP_Click);
	}

	calcPoints() {
		const it = this;

		if (!it.user.prizes?.loyaltyPoints) {
			return;
		}

		let points = 0;
		it.user.prizes.loyaltyPoints.forEach((item) => (points += item.amount));

		if (points > 0) {
			$('.points-list__screen._start').classList.remove('_active');
			$('.points-list__screen._finish').classList.add('_active');
			$('.points-list__value span').textContent = `${points} ${num2str(points, ['балл', 'балла', 'баллов'])}`;
		}
	}

	handleListScreenAppBtn() {
		const it = this;
		it.initialData?.dataProvider?.goBack();
	}
}
