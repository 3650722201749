import gsap from 'gsap';
import { $, $$, formatNumber } from '../../helpers';
import { AnalyticsInterface, EventName } from '../Analytics';
import { ApiInterface, IChestPrizesResponse, Prize } from '../API';
import { BoxesInterface } from '../Boxes';
import { CouponsInterface } from '../Coupons';
import { GamedataInterface } from '../GameData';
import { Modal, ModalsInterface } from '../Modal';
import { PointsInterface } from '../Points';
import { UIInterface } from '../UI';
import { UserInterface } from '../User';
import { PrizesDependencies, PrizesInterface } from './types';

export class Prizes implements PrizesInterface {
	private static singleton: Prizes;
	private analytics: AnalyticsInterface;
	private initialData: GamedataInterface['initialData'];
	private api: ApiInterface;
	private modal: ModalsInterface;
	private ui: UIInterface;
	private user: UserInterface;
	private coupons: CouponsInterface;
	private boxes: BoxesInterface;
	private points: PointsInterface;

	inject(deps: PrizesDependencies) {
		this.initialData = deps.gameData.initialData;
		this.api = deps.api;
		this.modal = deps.modal;
		this.ui = deps.ui;
		this.user = deps.user;
		this.coupons = deps.coupons;
		this.boxes = deps.boxes;
		this.points = deps.points;
		this.analytics = deps.analytics;
	}

	public static getInstance(): Prizes {
		if (!Prizes.singleton) Prizes.singleton = new Prizes();

		return Prizes.singleton;
	}

	async togglePrizesScreen() {
		const it = this;

		if (it.ui.animationRunning) return;

		if (it.modal.current === Modal.PRIZES) {
			it.modal.hide(Modal.PRIZES);
			return;
		}

		it.analytics.track(EventName.Page_Aliland_Menu_Rewards_Click);

		gsap.timeline({
			onComplete: async () => {
				await it.user.getStatus();
				await it.user.getPrizes();
				it.coupons.updateList();
				it.ui.setUserPrizes();
				it.boxes.setUserBoxesCounter();
				it.modal.show(Modal.PRIZES);
			},
		})
			.to('.js-prizes-btn', { scale: 0.95, duration: 0.05 })
			.to('.js-prizes-btn', { scale: 1, duration: 0.05 });
	}

	async togglePrizesCouponsListScreen() {
		const it = this;

		if (it.modal.current === Modal.PRIZES_LIST) {
			it.modal.hide(Modal.PRIZES_LIST);
			return;
		}

		it.analytics.track(EventName.Page_Aliland_Menu_RewardsCoupons_Click);

		await it.coupons.updateList();
		it.handlePrizesListTabs('coupons');
		it.modal.show(Modal.PRIZES_LIST);
	}

	async togglePrizesBoxesScreen() {
		const it = this;

		if (it.modal.current === Modal.PRIZES_LIST) {
			it.modal.hide(Modal.PRIZES_LIST);
			return;
		}

		if (it.modal.current === Modal.BOX_GAIN) {
			it.analytics.track(EventName.Page_Aliland_SuperboxPopup_Exchange_Click, 'click', {
				ae_page_type: 'aliland_dice',
				ae_page_area: 'superbox_popup',
				ae_button_type: 'exchange_superbox',
				ae_object_value: JSON.stringify({
					superbox_cnt: it.user.status.receivedChestsCount.toString(),
				}),
				ae_click_behavior: 'game_flow',
				'spm-cnt': 'a2g2l.aliland.dice.superbox_popup_exchange',
			});

			it.modal.hide(Modal.BOX_GAIN);
		}

		if (it.modal.current === Modal.PRIZES) {
			it.analytics.track(EventName.Page_Aliland_Menu_RewardsSuperbox_Click);

			it.modal.hide(Modal.PRIZES);
		}

		if (it.modal.current === Modal.BOX) {
			it.analytics.track(EventName.Page_Aliland_SuperboxPopup_CouponList_Click);
		}

		// if (it.modal.current === Modal.BOX) {
		// 	it.modal.handleReturn();
		// }

		it.boxes.list = (await it.api.getChestPrizes()).data;
		it.boxes.build();

		it.handlePrizesListTabs('boxes');

		if (it.modal.current === Modal.ENDGAME_BOX) {
			it.modal.show(Modal.PRIZES_LIST_ENDGAME);
			return;
		}

		it.modal.show(Modal.PRIZES_LIST);

		it.analytics.track(EventName.Page_Aliland_SuperboxCoupons_Screen, 'expose', {
			exp_page: 'aliland_superbox',
			exp_page_area: 'superbox_couponlist',
			exp_type: 'superbox_couponlist',
			exp_condition: it.user.status?.receivedChestsCount === 0 ? 'out_of_game_flow' : 'game_flow',
			'spm-cnt': 'a2g2l.aliland.superbox.couponlist',
		});
	}

	// togglePrizesPointsScreen() {
	// 	const it = this;

	// 	if (it.modal.current === Modal.POINTS_LIST) {
	// 		it.modal.hide(Modal.POINTS_LIST);
	// 		return;
	// 	}

	// 	it.analytics.track(EventName.Page_Aliland_Menu_RewardsLP_Click);

	// 	it.points.calcPoints();
	// 	it.modal.show(Modal.POINTS_LIST);
	// }

	togglePrizesMyPrizesScreen() {
		const it = this;

		if (it.modal.current === Modal.PRIZES_LIST) {
			it.modal.hide(Modal.PRIZES_LIST);
			return;
		}

		// it.analytics.track(EventName.Page_Aliland_Menu_CouponList_Click);
		it.handlePrizesListTabs('prizes');
		it.modal.show(Modal.PRIZES_LIST);
	}

	async toggleEmptySupercouponsToBoxes() {
		const it = this;

		it.boxes.list = (await it.api.getChestPrizes()).data;
		it.boxes.build();

		it.handlePrizesListTabs('boxes');
	}

	async handlePrizesListTabs(tab: 'coupons' | 'boxes' | 'prizes') {
		const it = this;

		if (tab === 'coupons') {
			$('.prizes-list').classList.remove('_boxes');
			$('.prizes-list').classList.add('_coupons');
			$('.js-prize-tab-boxes').classList.remove('_active');
			$('.js-prize-tab-prizes').classList.remove('_active');
			$('.prizes-list__section._boxes').classList.remove('_active');
			$('.prizes-list__section._prizes').classList.remove('_active');
			$('.js-prize-tab-coupons').classList.add('_active');
			$('.prizes-list__section._coupons').classList.add('_active');
			$('.prizes-list__heading').textContent = 'Купоны';
		}

		if (tab === 'boxes') {
			$('.prizes-list').classList.remove('_coupons');
			$('.prizes-list').classList.add('_boxes');
			$('.js-prize-tab-coupons').classList.remove('_active');
			$('.js-prize-tab-prizes').classList.remove('_active');
			$('.prizes-list__section._coupons').classList.remove('_active');
			$('.prizes-list__section._prizes').classList.remove('_active');
			$('.js-prize-tab-boxes').classList.add('_active');
			$('.prizes-list__section._boxes').classList.add('_active');
			$('.prizes-list__heading').textContent = 'Супербоксы';
		}

		if (tab === 'prizes') {
			$('.js-prize-tab-coupons').classList.remove('_active');
			$('.js-prize-tab-boxes').classList.remove('_active');
			$('.prizes-list__section._coupons').classList.remove('_active');
			$('.prizes-list__section._boxes').classList.remove('_active');
			$('.js-prize-tab-prizes').classList.add('_active');
			$('.prizes-list__section._prizes').classList.add('_active');
		}
	}

	// toggleGiveaway() {
	// 	const it = this;

	// 	if (it.modal.current === Modal.GIVEAWAY) {
	// 		it.modal.hide(Modal.GIVEAWAY);
	// 		return;
	// 	}

	// 	// it.analytics.track(EventName.Page_Aliland_Menu_CouponList_Click);
	// 	it.modal.hide(Modal.PRIZES);
	// 	it.modal.show(Modal.GIVEAWAY);
	// }
}
