/* примеры методов */
const goBack = () => {
	console.log('goBack');
	return null;
};

const open = ({ option }: IOpen) => {
	console.log('open', { option });
	return null;
};

const trackEvent = ({ type, eventName, params }: ITrackEvent) => {
	console.log('trackEvent', { type, eventName, params });
	return null;
};

const goLink = ({ link }: ILink) => {
	console.log('goLink', { link });
	return null;
};

const shareLink = ({ link }: ILink) => {
	console.log('shareLink');
	return null;
};

const apiCall = ({ api, method, body }: IRequest) => {
	console.log('apiCall', api, method, body);
	return null;
};

const dataProvider: IGameBackend = {
	goBack,
	trackEvent,
	goLink,
	shareLink,
	apiCall,
	open
};

const staticData: IStaticData = {
	rulesText: [
		{
			title: 'Положения об акции',
			text: `<p>ПРАВИЛА АКЦИИ «TV+TV Box»<br>Настоящие Правила акции «TV+TV Box» (далее&nbsp;— «Правила») регулируют порядок проведения акции «TV+TV Box», которая организована и&nbsp;проводится на&nbsp;платформах&nbsp;и (или) порталах, описанных в&nbsp;пункте 4&nbsp;настоящих Правил (далее&nbsp;— «Акция»).<br><br>Перед тем как принять участие в&nbsp;Акции, Вы&nbsp;должны внимательно ознакомитьсяс настоящими Правилами. Совершение действий, направленных на&nbsp;участие в&nbsp;Акции, означает Ваше согласие с&nbsp;настоящими Правилами.<br><br>1. Акция
			В&nbsp;течение Периода проведения Акции (как этот термин определен в&nbsp;пункте 2&nbsp;ниже) лица, которые удовлетворяют требованиям, установленным пунктом&nbsp;5 (далее&nbsp;— «Участники»), получают возможность пробрести приёмник TV-Box Триколор по&nbsp;специальной цене в&nbsp;1(один) рубль при покупке товаров в&nbsp;комплекте из&nbsp;списка, указанного ниже.</p>`,
		},
	],
	chatLink: '',
	promotionPageLink: '',
	productsLink: '',
};

/* интерфейсы  */

export enum IMenuOptions {
	SUPPORT_LINK = 'SUPPORT_LINK',
	RULES_LINK = 'RULES_LINK',
	SALES_LINK = 'SALES_LINK',
}

export enum ETasks {
	goBack = 'goBack',
	trackEvent = 'trackEvent',
	goLink = 'goLink',
	getGameData = 'getGameData',
	shareLink = 'shareLink',
	apiCall = 'apiCall',
	open = 'open'
}

export enum API_NAMES {
	getStatus = 'getStatus',
	makeStep = 'makeStep',
	getTasks = 'getTasks',
	getMap = 'getMap',
	getMonuments = 'getMonuments',
	getUserPrizes = 'getUserPrizes',
	getAlternativePrizes = 'getAlternativePrizes',
	getChestPrizes = 'getChestPrizes',
	takePrize = 'takePrize',
	sendSuperprizeForm = 'sendSuperprizeForm',
	passTutorial = 'passTutorial',
	visitLink = 'visitLink',
}

export interface ITrackEvent {
	type: 'click' | 'expose';
	eventName: string;
	params?: Record<string, any>;
}

export interface ILink {
	link: string;
}

export interface IResponse {
	data: any;
	error: null | string;
}

export interface IRequest {
	method?: 'GET' | 'POST';
	body?: Record<any, any>;
	api: API_NAMES;
}

export interface IGameBackend {
	[ETasks.goBack]: () => any;
	[ETasks.trackEvent]: ({ type, eventName, params }: ITrackEvent) => void;
	[ETasks.goLink]: ({ link }: ILink) => void;
	[ETasks.shareLink]: ({ link }: ILink) => void;
	[ETasks.apiCall]: ({ api, method, body }: IRequest) => Promise<Response>;
	[ETasks.open]: (p: { option: IMenuOptions }) => any;
}

export type Rules = {
	title: string;
	text: string;
};

export interface IStaticData {
	rulesText: Rules[];
	chatLink: string;
	promotionPageLink: string;
	productsLink: string;
}

export interface IInitialGameDataBackend {
	buyerId: number;
	staticData: IStaticData;
}

export interface IInitialGameData extends IInitialGameDataBackend {
	dataProvider: IGameBackend;
	assetsUrl: string;
}

export interface IOpen {
	option: IMenuOptions
}

export const initialGameData: IInitialGameData = {
	dataProvider,
	buyerId:
		parseInt(new URL(window.location.href)?.searchParams?.get('userId')) ||
		Math.floor(Math.random() * (3000 - 2000 + 1) + 2000),
	staticData,
	assetsUrl: '',
};

export const mock_url = 'http://185.231.153.8/bx-aliland-api/api/v2/';
export const mtopContext = {
	userId:
		parseInt(new URL(window.location.href)?.searchParams?.get('userId')) ||
		Math.floor(Math.random() * (3000 - 2000 + 1) + 2000),
	deviceId: 'localPostman',
	appKey: '001',
	appVersion: '001',
	verCode: '1',
	platform: 'local',
	ip: '127.0.0.1',
	userAgent: 'Local Postman Test',
	mockNow: null,
};
