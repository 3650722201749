import { GamedataInterface } from '../GameData';
import { UIInterface } from '../UI';

export interface ApiInterface {
	inject: (gameData: GamedataInterface, ui: UIInterface) => void;
	getStatus: () => Promise<IMtopResponse<IUserStatusInfo>>;
	makeStep: () => Promise<IMtopResponse<IStepResponse>>;
	getTasks: () => Promise<IMtopResponse<ITasksResponse>>;
	getMap: () => Promise<IMtopResponse<IMapResponse>>;
	getMonuments: () => Promise<IMtopResponse<IMonumentsResponse>>;
	getUserPrizes: () => Promise<IMtopResponse<IUserPrizesResponse>>;
	getAlternativePrizes: () => Promise<IMtopResponse<IAlternativePrizes>>;
	getChestPrizes: () => Promise<IMtopResponse<IChestPrizesResponse>>;
	takePrize: (takePrizeRequest: TakePrizeRequest) => Promise<IMtopResponse<ITakePrizeResponse>>;
	sendSuperprizeForm: (
		sendSuperprizeFormRequest: SendSuperprizeFormRequest
	) => Promise<IMtopResponse<ISuperprizeFromResponse>>;
	passTutorial: () => Promise<IMtopResponse<ITutorialResponse>>;
	visitLink: (visitLinkRequest: VisitLinkRequest) => Promise<IMtopResponse<IVisitLinkResponse>>;
}

export interface IMtopResponse<T> {
	error: {
		code: number;
		details: string;
		message: string;
	};
	success: boolean;
	data: T | null;
}

export interface Error {
	code: String;
	message: String;
	details: any | null;
}

export interface Response<Type> {
	data: Type;
	error: Error | null;
}

export interface Message {
	message: string;
}

// /api/v2/getStatus
export enum PromoTimeType {
	notStarted = 'notStarted',
	warmup = 'warmUp',
	promo = 'promo',
	completed = 'completed',
	notActive = 'notActive',
	postPromo = 'postPromo',
}

export interface IUserStatusInfo {
	currentCellId: number;
	totalCellsCount: number;
	completedStepsCount: number;
	availableStepsCount: number;
	isFirstMove: boolean;
	receivedChestsCount: number;
	userPrizes: Prize[];
	timeToNewDay: bigint;
	promoTimeType: PromoTimeType;
	timeToPromoTypeEnd: bigint;
	hasPassedTutorial: boolean;
	needToSendSuperprizeSubmission: boolean;
	needToUpdateApp: boolean;
	currentState: {
		prize: Prize;
		newSteps: number;
	};
	state: State | null; // not null if state must be recovered after making step
}

export interface State {
	lastDiceNumber: number;
	receivedPrize: Prize;
	alternativePrizes: Prize[] | null;
}

export enum PrizeType {
	aer_coupon = 'aer_coupon',
	seller_coupon = 'seller_coupon',
	loyalty_points = 'loyalty_points',
	superbox = 'superbox',
}

export interface Prize {
	id: number;
	type: PrizeType;
	category: string;
	amount: number;
	threshold: number;
	validFrom: bigint;
	validTill: bigint;
	title: string;
	subtitle: string;
	sellerId: number;
	sellerTitle: string;
	sellerSubtitle: string;
	imageUrl: string; // URL
	link: string; // URL
	price: number;
}

// /api/v2/makeStep
export enum Status {
	success = 'success',
	out_of_prizes = 'out_of_prizes',
	all_coupons_received = 'all_coupons_received',
	all_prizes_received = 'all_prizes_received',
}

export interface IStepResponse {
	status: Status;
	alternativePrizes: IPrizeCategories[]; // length 0 == no alternative prizes for cell right now so should hide the button
	receivedPrize: Prize;
	newCellId: number;
	totalCellsCount: number;
	diceNumber: number;
	completedStepsCount: number;
	availableStepsCount: number;
	receivedChestsCount: number;
	needToSendSuperprizeSubmission: boolean;
}

// /api/v2/getTasks
export interface ITasksResponse {
	tasks: Task[];
	links: {
		title: string;
		subtitle: string;
		links: TaskLink[];
	};
}

export enum TaskType {
	invite_friend = 'invite_friend',
	daily_activity = 'daily_activity',
	purchase = 'purchase',
}

export enum TaskStatus {
	new = 'new',
	completed = 'completed',
}

export enum TaskLinkStatus {
	new = 'new',
	visited = 'visited',
}

export interface Task {
	type: TaskType;
	content: any;
	status: TaskStatus;
}

export interface TaskLink {
	id: bigint;
	title: string;
	link: string;
	status: TaskLinkStatus;
}

// /api/v2/getMap
export interface IMapResponse {
	cells: MapCell[];
}

export interface MapCell {
	id: number;
	sellerId: number;
	sellerTitle: string;
	sellerSubtitle: string;
	link: string; // URL
	logoUrl: string; // URL
	mapLogoURL: string; // URL
	contentImages: string[]; // URLs
	type: MapCellType;
}

export enum MapCellType {
	loyalty_points = 'loyalty_points',
	seller = 'seller',
	superbox = 'superbox',
}

// /api/v2/getMonuments
export interface IMonumentsResponse {
	monuments: Monument[];
}

export interface Monument {
	position: Position;
	link: MonumentLink;
}

export interface Position {
	x: number;
	y: number;
}

export interface MonumentLink {
	id: number;
	title: string;
	description: string;
	link: string; // URL
	imageUrl: string; // URL
	monumentImageUrl: string; // URL
	contentImages: string[];
}

// /api/v2/getUserPrizes
export interface IUserPrizesResponse {
	loyaltyPoints: PrizeStatus[];
	superCoupons: PrizeStatus[];
	coupons: PrizeStatus[];
}

export enum PrizeStatusStatus {
	received = 'received',
	under_review = 'under_review',
}

export interface PrizeStatus extends Prize {
	status: PrizeStatusStatus;
}

// /api/v2/getChestPrizes
export type getChestPrizes = () => Response<IChestPrizesResponse>;

export interface IChestPrizesResponse {
	selectedCategory: string;
	prizeCategories: IPrizeCategories[];
}

// /api/v2/visitLink
export enum VisitLinkStatus {
	success = 'success',
	// allTaskLinksvVisited = 'all_task_links_visited',
}

export interface IVisitLinkResponse {
	result: VisitLinkStatus;
	recievedStepsCount: number;
}

export enum LinkType {
	task = 'task',
	monument = 'monument',
}

export interface VisitLinkRequest {
	linkId: number;
	linkType: LinkType;
}

// /api/v2/takePrize
export interface TakePrizeRequest {
	prizeId?: number;
	dismiss?: boolean;
}

export interface ITakePrizeResponse {
	status: Status;
}

// /api/v2/sendSuperprizeForm
export interface SendSuperprizeFormRequest {
	fio: string;
	email: string;
	phone: string;
}

export interface ISuperprizeFromResponse extends Message {
	// message: "success";
}

// /api/v2/passTutorial
export interface ITutorialResponse extends Message {
	// message: "success";
}

export interface IPrize {
	prizeName: string;
	imageUrl: string;
}

export interface IAlternativePrizes {
	selectedCategory: string;
	prizeCategories: IPrizeCategories[];
}

export interface IPrizeCategories {
	categoryName: string;
	prizes: Prize[];
}
