import { $, $$ } from '../../helpers';

import { Modal, ModalsDependencies, ModalsInterface } from '.';
import { AnalyticsInterface } from '../Analytics';
import { EventName } from '../Analytics';
import { GamedataInterface } from '../GameData';
import { EndgameInterface } from '../Endgame';
import { CouponsInterface } from '../Coupons';

export class Modals implements ModalsInterface {
	private static singleton: Modals;
	private initialData: GamedataInterface['initialData'];
	private analytics: AnalyticsInterface;
	private coupons: CouponsInterface;
	private endgame: EndgameInterface;
	current: Modal = null;
	private history: Modal[] = [];

	private headerEl: HTMLElement = $('.header');
	private wrapperEl: HTMLElement = $('.wrapper');
	private backBtnTextEl: HTMLElement = $('.js-back-btn-text');

	private loginEl: HTMLElement = $('.login');
	private loaderEl: HTMLElement = $('.loader');
	private errorGeneralEl: HTMLElement = $('.error-general');
	private errorLoadingEl: HTMLElement = $('.error-loading');
	private gameOverEl: HTMLElement = $('.gameover');

	private menuEl: HTMLElement = $('.menu');
	private addMoveEl: HTMLElement = $('.add-move');
	private getMoveEl: HTMLElement = $('.get-move');
	private brandEl: HTMLElement = $('.brand');
	private monumentEl: HTMLElement = $('.monument');
	private rulesEl: HTMLElement = $('.rules');
	private giveawayEl: HTMLElement = $('.giveaway');
	private formEl: HTMLElement = $('.form');
	private tutorialEl: HTMLElement = $('.tutorial');

	private prizesEl: HTMLElement = $('.prizes');
	private prizesListEl: HTMLElement = $('.prizes-list');

	// private pointsEl: HTMLElement = $('.points');
	// private pointsGainEl: HTMLElement = $('.points-gain');
	// private pointsListEl: HTMLElement = $('.points-list');

	private boxEl: HTMLElement = $('.box');
	private boxGainEl: HTMLElement = $('.box-gain');

	private couponEl: HTMLElement = $('.coupon');
	private couponOtherEl: HTMLElement = $('.coupon-other');
	private couponEmptyEl: HTMLElement = $('.coupon-empty');
	private couponNoCouponsEl: HTMLElement = $('.coupon-no-coupons');
	private couponSubmitEl: HTMLElement = $('.coupon-submit');
	private couponRemindEl: HTMLElement = $('.coupon-remind');
	private otherCouponsEl: HTMLElement = $('.other-coupons');

	private supercouponEl: HTMLElement = $('.supercoupon');
	private supercouponSubmitEl: HTMLElement = $('.supercoupon-submit');
	private supercouponRemindEl: HTMLElement = $('.supercoupon-remind');

	// private endgameCouponEl: HTMLElement = $('.endgame-coupon');
	// private endgameCouponSubmitEl: HTMLElement = $('.endgame-coupon-submit');
	// private endgameCouponRemindEl: HTMLElement = $('.endgame-coupon-remind');
	// private endgameSigninEl: HTMLElement = $('.endgame-signin');
	// private endgameSigninNoCouponsEl: HTMLElement = $('.endgame-signin-no-coupons');
	private endgameCompleteEl: HTMLElement = $('.endgame-complete');
	private endgameBoxEl: HTMLElement = $('.endgame-box');

	inject(deps: ModalsDependencies) {
		this.initialData = deps.gameData.initialData;
		this.analytics = deps.analytics;
		this.coupons = deps.coupons;
		this.endgame = deps.endgame;
	}

	public static getInstance(): Modals {
		if (!Modals.singleton) Modals.singleton = new Modals();

		return Modals.singleton;
	}

	initListeners() {
		window.addEventListener('load', () => this.setHeights());
		window.addEventListener('resize', () => this.setHeights());
	}

	setHeights() {
		[...$$('.tutorial__img')]?.forEach(
			(elem) => ((elem as HTMLElement).style.height = window.innerHeight - 288 + 'px')
		);
		[...$$('.js-height')]?.forEach((elem) => ((elem as HTMLElement).style.height = window.innerHeight + 'px'));
		[...$$('.js-min-height')]?.forEach(
			(elem) => ((elem as HTMLElement).style.minHeight = window.innerHeight + 'px')
		);
	}

	show(modal: Modal) {
		const it = this;

		it.hide(it.current);

		it.wrapperEl.classList.add('_modal-active');
		$('.modal').classList.add('_active');
		$('.overlay').classList.add('_active');
		it.backBtnTextEl.style.display = 'none';

		switch (modal) {
			case Modal.LOGIN:
				it.loginEl.classList.add('_active');
				break;
			case Modal.LOADER:
				it.loaderEl.classList.add('_active');
				break;
			case Modal.ERROR_GENERAL:
				it.errorGeneralEl.classList.add('_active');
				break;
			case Modal.ERROR_LOADING:
				it.errorLoadingEl.classList.add('_active');
				break;
			case Modal.GAMEOVER:
				it.gameOverEl.classList.add('_active');
				break;
			case Modal.MENU:
				it.headerEl.classList.add('_modal-active', '_no-back-btn');
				it.menuEl.classList.add('_active');
				break;
			case Modal.ADD_MOVE:
				it.headerEl.classList.add('_modal-active', '_no-back-btn');
				it.addMoveEl.classList.add('_active');
				break;
			case Modal.GET_MOVE:
				it.headerEl.classList.add('_modal-active', '_no-menu');
				it.getMoveEl.classList.add('_active');
				break;
			case Modal.BRAND:
				it.headerEl.classList.add('_modal-active', '_no-back-btn');
				it.brandEl.classList.add('_active');
				break;
			case Modal.MONUMENT:
				it.headerEl.classList.add('_modal-active', '_no-back-btn');
				it.monumentEl.classList.add('_active');
				break;
			// case Modal.RULES:
			// 	it.headerEl.classList.add('_modal-active', '_no-menu');
			// 	it.rulesEl.classList.add('_active');
			// 	if (!it.history.includes(Modal.MENU)) {
			// 		it.tutorialEl.classList.remove('_active');
			// 	}
			// 	break;
			case Modal.GIVEAWAY:
				it.headerEl.classList.add('_modal-active', '_no-menu');
				it.giveawayEl.classList.add('_active');
				break;
			case Modal.FORM:
				it.headerEl.classList.add('_modal-active', '_no-back-btn');
				it.formEl.classList.add('_active');
				break;
			case Modal.PRIZES:
				it.headerEl.classList.add('_modal-active', '_modal-prizes', '_no-back-btn');
				it.prizesEl.classList.add('_active');
				break;
			case Modal.PRIZES_LIST:
				it.headerEl.classList.add('_modal-active', '_no-menu');
				it.prizesListEl.classList.add('_active');
				break;
			case Modal.PRIZES_LIST_ENDGAME:
				it.headerEl.classList.add('_modal-active', '_no-menu');
				it.prizesListEl.classList.add('_active');
				it.history = [];
				break;
			// case Modal.POINTS:
			// 	it.headerEl.classList.add('_modal-active', '_no-back-btn');
			// 	it.pointsEl.classList.add('_active');
			// 	break;
			// case Modal.POINTS_GAIN:
			// 	it.headerEl.classList.add('_modal-active', '_no-back-btn');
			// 	it.pointsGainEl.classList.add('_active');
			// 	break;
			// case Modal.POINTS_LIST:
			// 	it.headerEl.classList.add('_modal-active', '_no-menu');
			// 	it.pointsListEl.classList.add('_active');
			// 	break;
			case Modal.BOX:
				it.headerEl.classList.add('_modal-active', '_no-back-btn');
				it.boxEl.classList.add('_active');
				break;
			case Modal.BOX_GAIN:
				it.headerEl.classList.add('_modal-active', '_no-back-btn');
				it.boxGainEl.classList.add('_active');
				break;
			case Modal.COUPON:
				it.headerEl.classList.add('_modal-active', '_no-back-btn');
				it.couponEl.classList.add('_active');
				it.history.unshift(Modal.COUPON_REMIND);
				break;
			case Modal.COUPON_EMPTY:
				it.headerEl.classList.add('_modal-active', '_no-back-btn');
				it.couponEmptyEl.classList.add('_active');
				break;
			case Modal.COUPON_NO_COUPONS:
				it.headerEl.classList.add('_modal-active', '_no-back-btn');
				it.couponNoCouponsEl.classList.add('_active');
				break;
			case Modal.COUPON_OTHER:
				it.headerEl.classList.add('_modal-active', '_no-menu');
				it.couponOtherEl.classList.add('_active');
				break;
			case Modal.COUPON_OTHER_EMPTY:
				it.headerEl.classList.add('_modal-active', '_show-back-btn');
				it.couponOtherEl.classList.add('_active');
				break;
			case Modal.COUPON_SUBMIT:
				it.headerEl.classList.add('_modal-active', '_no-back-btn');
				it.couponSubmitEl.classList.add('_active');
				break;
			// case Modal.COUPON_REMIND_FROM_COUPON_OTHER:
			// case Modal.COUPON_REMIND_FROM_OTHER_COUPONS:
			case Modal.COUPON_REMIND:
				it.headerEl.classList.add('_modal-active', '_no-menu', '_no-back-btn');
				it.couponRemindEl.classList.add('_active');
				break;
			// case Modal.COUPON_REMIND_EMPTY_FROM_COUPON_OTHER_EMPTY:
			// case Modal.COUPON_REMIND_EMPTY_FROM_OTHER_COUPONS_NO_COUPON:
			case Modal.COUPON_REMIND_EMPTY:
				it.headerEl.classList.add('_modal-active', '_no-menu');
				it.couponRemindEl.classList.add('_active');
				break;
			case Modal.OTHER_COUPONS:
				it.headerEl.classList.add('_modal-active', '_no-menu');
				it.otherCouponsEl.classList.add('_active');
				break;
			case Modal.OTHER_COUPONS_NO_COUPON:
				it.headerEl.classList.add('_modal-active', '_no-menu');
				it.otherCouponsEl.classList.add('_active');
				break;
			case Modal.OTHER_COUPONS_ERROR:
				it.headerEl.classList.add('_modal-active', '_no-menu');
				it.otherCouponsEl.classList.add('_active');
				break;
			case Modal.SUPERCOUPON:
				it.headerEl.classList.add('_modal-active', '_no-menu');
				it.supercouponEl.classList.add('_active');
				// it.history = [Modal.SUPERCOUPON_REMIND];
				break;
			case Modal.SUPERCOUPON_SUBMIT:
				it.headerEl.classList.add('_modal-active', '_no-menu', '_no-back-btn');
				it.supercouponSubmitEl.classList.add('_active');
				break;
			case Modal.SUPERCOUPON_REMIND:
				it.headerEl.classList.add('_modal-active', '_no-menu', '_no-back-btn');
				it.supercouponRemindEl.classList.add('_active');
				break;
			// case Modal.ENDGAME_COUPON:
			// 	it.headerEl.classList.add('_modal-active', '_no-menu');
			// 	it.endgameCouponEl.classList.add('_active');
			// 	it.history.unshift(Modal.ENDGAME_COUPON_REMIND);
			// 	break;
			// case Modal.ENDGAME_COUPON_SUBMIT:
			// 	it.headerEl.classList.add('_modal-active', '_no-menu', '_no-back-btn');
			// 	it.endgameCouponSubmitEl.classList.add('_active');
			// 	break;
			// case Modal.ENDGAME_COUPON_REMIND:
			// 	it.headerEl.classList.add('_modal-active', '_no-menu', '_no-back-btn');
			// 	it.endgameCouponRemindEl.classList.add('_active');
			// 	break;
			// case Modal.ENDGAME_SIGNIN:
			// 	it.headerEl.classList.add('_modal-active', '_no-menu');
			// 	it.endgameSigninEl.classList.add('_active');
			// 	break;
			// case Modal.ENDGAME_SIGNIN_NO_COUPONS:
			// 	it.headerEl.classList.add('_modal-active', '_no-menu');
			// 	it.endgameSigninNoCouponsEl.classList.add('_active');
			// 	break;
			case Modal.ENDGAME_COMPLETE:
				it.headerEl.classList.add('_modal-active', '_no-back-btn');
				it.endgameCompleteEl.classList.add('_active');
				break;
			case Modal.ENDGAME_BOX:
				it.headerEl.classList.add('_modal-active', '_no-back-btn');
				it.endgameBoxEl.classList.add('_active');
				break;
		}

		it.wrapperEl.scrollTop = 0;
		it.current = modal;
		if (it.history[it.history.length - 1] !== it.current) {
			it.history.push(modal);
		}

		switch (modal) {
			case Modal.ADD_MOVE:
			case Modal.BRAND:
			case Modal.MONUMENT:
			// case Modal.POINTS:
			// case Modal.POINTS_GAIN:
			case Modal.BOX:
			case Modal.BOX_GAIN:
			case Modal.COUPON:
			case Modal.COUPON_EMPTY:
			case Modal.COUPON_NO_COUPONS:
			case Modal.COUPON_OTHER:
			case Modal.COUPON_OTHER_EMPTY:
			case Modal.COUPON_SUBMIT:
			case Modal.COUPON_REMIND:
			case Modal.COUPON_REMIND_EMPTY:
			case Modal.SUPERCOUPON:
			case Modal.SUPERCOUPON_SUBMIT:
			case Modal.SUPERCOUPON_REMIND:
			// case Modal.ENDGAME_COUPON:
			// case Modal.ENDGAME_COUPON_SUBMIT:
			// case Modal.ENDGAME_COUPON_REMIND:
			// case Modal.ENDGAME_SIGNIN:
			// case Modal.ENDGAME_SIGNIN_NO_COUPONS:
			case Modal.ENDGAME_COMPLETE:
			case Modal.ENDGAME_BOX:
				$('.wrapper').scrollTo(0, $('.wrapper').scrollHeight);
		}
	}

	hide(modal: Modal = this.current) {
		const it = this;

		it.headerEl.scrollTop = 0;

		it.wrapperEl.classList.remove('_modal-active');
		$('.modal').classList.remove('_active');
		$('.overlay').classList.remove('_active');
		it.backBtnTextEl.style.display = 'block';

		switch (modal) {
			case Modal.LOGIN:
				it.loginEl.classList.remove('_active');
				break;
			case Modal.LOADER:
				it.loaderEl.classList.remove('_active');
				break;
			case Modal.ERROR_GENERAL:
				it.errorGeneralEl.classList.remove('_active');
				break;
			case Modal.ERROR_LOADING:
				it.errorLoadingEl.classList.remove('_active');
				break;
			case Modal.GAMEOVER:
				it.gameOverEl.classList.remove('_active');
				break;
			case Modal.MENU:
				it.headerEl.classList.remove('_modal-active', '_no-back-btn');
				it.menuEl.classList.remove('_active');
				break;
			case Modal.ADD_MOVE:
				it.headerEl.classList.remove('_modal-active', '_no-back-btn');
				it.addMoveEl.classList.remove('_active');
				it.history = [];
				break;
			case Modal.GET_MOVE:
				it.headerEl.classList.remove('_modal-active', '_no-menu');
				it.getMoveEl.classList.remove('_active');
				break;
			case Modal.BRAND:
				it.headerEl.classList.remove('_modal-active', '_no-back-btn');
				it.brandEl.classList.remove('_active');
				break;
			case Modal.MONUMENT:
				it.headerEl.classList.remove('_modal-active', '_no-back-btn');
				it.monumentEl.classList.remove('_active');
				break;
			// case Modal.RULES:
			// 	it.headerEl.classList.remove('_modal-active', '_no-menu');
			// 	it.rulesEl.classList.remove('_active');
			// 	if (!it.history.includes(Modal.MENU)) {
			// 		it.tutorialEl.classList.add('_active');
			// 	}
			// 	break;
			case Modal.GIVEAWAY:
				it.headerEl.classList.remove('_modal-active', '_no-menu');
				it.giveawayEl.classList.remove('_active');
				break;
			case Modal.FORM:
				it.headerEl.classList.remove('_modal-active', '_no-back-btn');
				it.formEl.classList.remove('_active');
				break;
			case Modal.PRIZES:
				it.headerEl.classList.remove('_modal-active', '_modal-prizes', '_no-back-btn');
				it.prizesEl.classList.remove('_active');
				break;
			case Modal.PRIZES_LIST:
				it.headerEl.classList.remove('_modal-active', '_no-menu');
				it.prizesListEl.classList.remove('_active');
				break;
			case Modal.PRIZES_LIST_ENDGAME:
				it.headerEl.classList.remove('_modal-active', '_no-menu');
				it.prizesListEl.classList.remove('_active');
				break;
			// case Modal.POINTS:
			// 	it.headerEl.classList.remove('_modal-active', '_no-back-btn');
			// 	it.pointsEl.classList.remove('_active');
			// 	break;
			// case Modal.POINTS_GAIN:
			// 	it.headerEl.classList.remove('_modal-active', '_no-back-btn');
			// 	it.pointsGainEl.classList.remove('_active');
			// 	break;
			// case Modal.POINTS_LIST:
			// 	it.headerEl.classList.remove('_modal-active', '_no-menu');
			// 	it.pointsListEl.classList.remove('_active');
			// 	break;
			case Modal.BOX:
				it.headerEl.classList.remove('_modal-active', '_no-back-btn');
				it.boxEl.classList.remove('_active');
				break;
			case Modal.BOX_GAIN:
				it.headerEl.classList.remove('_modal-active', '_no-back-btn');
				it.boxGainEl.classList.remove('_active');
				break;
			case Modal.COUPON:
				it.headerEl.classList.remove('_modal-active', '_no-back-btn');
				it.couponEl.classList.remove('_active');
				break;
			case Modal.COUPON_EMPTY:
				it.headerEl.classList.remove('_modal-active', '_no-back-btn');
				it.couponEmptyEl.classList.remove('_active');
				break;
			case Modal.COUPON_NO_COUPONS:
				it.headerEl.classList.remove('_modal-active', '_no-back-btn');
				it.couponNoCouponsEl.classList.remove('_active');
				break;
			case Modal.COUPON_OTHER:
				it.headerEl.classList.remove('_modal-active', '_no-menu');
				it.couponOtherEl.classList.remove('_active');
				break;
			case Modal.COUPON_OTHER_EMPTY:
				it.headerEl.classList.remove('_modal-active', '_no-menu');
				it.couponOtherEl.classList.remove('_active');
				break;
			case Modal.COUPON_SUBMIT:
				it.headerEl.classList.remove('_modal-active', '_no-back-btn');
				it.couponSubmitEl.classList.remove('_active');
				it.history = [];
				// it.couponSeller = null;
				break;
			// case Modal.COUPON_REMIND_FROM_COUPON_OTHER:
			// case Modal.COUPON_REMIND_FROM_OTHER_COUPONS:
			case Modal.COUPON_REMIND:
				it.headerEl.classList.remove('_modal-active', '_no-menu', '_no-back-btn');
				it.couponRemindEl.classList.remove('_active');
				break;
			// case Modal.COUPON_REMIND_EMPTY_FROM_COUPON_OTHER_EMPTY:
			// case Modal.COUPON_REMIND_EMPTY_FROM_OTHER_COUPONS_NO_COUPON:
			case Modal.COUPON_REMIND_EMPTY:
				it.headerEl.classList.remove('_modal-active', '_no-menu');
				it.couponRemindEl.classList.remove('_active');
				break;
			case Modal.OTHER_COUPONS:
				it.headerEl.classList.remove('_modal-active', '_no-menu');
				it.otherCouponsEl.classList.remove('_active');
				break;
			case Modal.OTHER_COUPONS_NO_COUPON:
				it.headerEl.classList.remove('_modal-active', '_no-menu');
				it.otherCouponsEl.classList.remove('_active');
				break;
			case Modal.OTHER_COUPONS_ERROR:
				it.headerEl.classList.remove('_modal-active', '_no-menu');
				it.otherCouponsEl.classList.remove('_active');
				break;
			case Modal.SUPERCOUPON:
				it.headerEl.classList.remove('_modal-active', '_no-menu');
				it.supercouponEl.classList.remove('_active');
				break;
			case Modal.SUPERCOUPON_SUBMIT:
				it.headerEl.classList.remove('_modal-active', '_no-menu', '_no-back-btn');
				it.supercouponSubmitEl.classList.remove('_active');
				it.history = [];
				break;
			case Modal.SUPERCOUPON_REMIND:
				it.headerEl.classList.remove('_modal-active', '_no-menu', '_no-back-btn');
				it.supercouponRemindEl.classList.remove('_active');
				break;
			// case Modal.ENDGAME_COUPON:
			// 	it.headerEl.classList.remove('_modal-active', '_no-menu');
			// 	it.endgameCouponEl.classList.remove('_active');
			// 	break;
			// case Modal.ENDGAME_COUPON_SUBMIT:
			// 	it.headerEl.classList.remove('_modal-active', '_no-menu', '_no-back-btn');
			// 	it.endgameCouponSubmitEl.classList.remove('_active');
			// 	it.history = [];
			// 	break;
			// case Modal.ENDGAME_COUPON_REMIND:
			// 	it.headerEl.classList.remove('_modal-active', '_no-menu', '_no-back-btn');
			// 	it.endgameCouponRemindEl.classList.remove('_active');
			// 	break;
			// case Modal.ENDGAME_SIGNIN:
			// 	it.headerEl.classList.remove('_modal-active', '_no-menu');
			// 	it.endgameSigninEl.classList.remove('_active');
			// 	break;
			// case Modal.ENDGAME_SIGNIN_NO_COUPONS:
			// 	it.headerEl.classList.remove('_modal-active', '_no-menu');
			// 	it.endgameSigninNoCouponsEl.classList.remove('_active');
			// 	break;
			case Modal.ENDGAME_COMPLETE:
				it.headerEl.classList.remove('_modal-active', '_no-back-btn');
				it.endgameCompleteEl.classList.remove('_active');
				break;
			case Modal.ENDGAME_BOX:
				it.headerEl.classList.remove('_modal-active', '_no-back-btn');
				it.endgameBoxEl.classList.remove('_active');
				break;
		}

		it.current = null;
	}

	handleReturn() {
		const it = this;

		if (it.history.length) {
			it.hide();
			it.history.pop();
			if (it.history.length) {
				it.current = it.history[it.history.length - 1];
				it.show(it.current);
			}
			return;
		} else {
			it.analytics.track(EventName.Page_Aliland_Map_BackToApp);
			it.initialData?.dataProvider?.goBack();
		}
	}

	async handleMenu() {
		const it = this;

		if (it.current) {
			if (it.current === Modal.COUPON) {
				it.handleReturn();
				return;
			}
			if (it.current === Modal.COUPON_EMPTY) {
				it.coupons.toggleEmptyModal();
			}
			if (it.current === Modal.COUPON_NO_COUPONS) {
				it.coupons.toggleNoCouponsModal();
			}
			if (it.current === Modal.ENDGAME_BOX) {
				it.endgame.toggleBoxModal();
			}
			if (it.current === Modal.ENDGAME_COMPLETE) {
				it.endgame.toggleCompleteModal();
			}

			it.hide();
			it.history = [];
			return;
		}

		it.show(Modal.MENU);
	}
}
