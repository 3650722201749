import gsap from 'gsap';
import { $, $$ } from '../../helpers';

import { TutorialDependencies, TutorialInterface } from '.';
import { UIInterface } from '../UI';
import { MapInterface } from '../GameMap';
import { ApiInterface } from '../API';
import { UserInterface } from '../User';
import { AnalyticsInterface, EventName } from '../Analytics';

export class Tutorial implements TutorialInterface {
	private static singleton: Tutorial;
	private api: ApiInterface;
	private analytics: AnalyticsInterface;
	private map: MapInterface;
	private ui: UIInterface;
	private user: UserInterface;
	private tutorial: HTMLElement = $('.tutorial');
	private tutorialBtn: HTMLElement = $('.js-tutorial-step-btn');
	private step1: HTMLElement = $('.tutorial__step._1');
	private step2: HTMLElement = $('.tutorial__step._2');
	private step3: HTMLElement = $('.tutorial__step._3');
	// private step4: HTMLElement = $('.tutorial__step._4');
	private rulesBtn: HTMLElement = $('.js-tutorial-rules');
	private currentStep: number = 1;
	private xDown = null;
	private yDown = null;
	private timeline = null;

	inject(deps: TutorialDependencies) {
		this.api = deps.api;
		this.analytics = deps.analytics;
		this.map = deps.map;
		this.ui = deps.ui;
		this.user = deps.user;
	}

	public static getInstance(): Tutorial {
		if (!Tutorial.singleton) Tutorial.singleton = new Tutorial();

		return Tutorial.singleton;
	}

	init() {
		const it = this;

		gsap.set('.header', { y: '-100%' });
		gsap.set('.dice', { y: '125%' });

		it.tutorial.classList.add('_active');

		it.map.dragInstance.disable();

		it.timeline = gsap.timeline({
			paused: true,
			onStart: () => (it.ui.animationRunning = true),
			onComplete: () => it.stopOnboarding(),
		});

		// for (let cell = 2; cell <= it.map.MAX_CELLS; cell++) {
		// for (let cell = it.map.MAX_CELLS; cell >= 1; cell--) {
		// 	const cellNumber = it.map.getCellNumber(cell);
		// 	const cameraCoordinates = it.map.getCoordinates(cellNumber);

		// 	// const delay = [6, 9, 11, 14, 17, 19, 20, 22, 25, 27, 30, 34, 38].includes(cell) ? 0.2 : 0;
		// 	it.timeline.to('.map__inner', { ...cameraCoordinates, ease: 'none', duration: 0.35 });
		// }
		// it.timeline.to('.map__inner', { ...it.map.getCoordinates(it.map.getCellNumber(1)), delay: 2, duration: 1 });

		const cameraCoordinates1 = it.map.getCoordinates(it.map.getCellNumber(40));
		const cameraCoordinates2 = it.map.getCoordinates(it.map.getCellNumber(1));
		it.timeline.set('.map__inner', { ...cameraCoordinates1 });
		it.timeline.to('.map__inner', { ...cameraCoordinates2, ease: 'power2.inOut', duration: 4 });

		$('.map').addEventListener('click', () => {
			if (it.user.status.hasPassedTutorial) return;
			it.timeline.kill();
			gsap.to('.map__inner', { ...it.map.getCoordinates(it.map.getCellNumber(1)), duration: 1 });
			it.stopOnboarding();

			it.analytics.track(EventName.Page_Aliland_Map_SkipMapOverview_Click);
		});
	}

	initListeners() {
		const it = this;

		[...$$('.js-tutorial-rules')]?.forEach((btn) =>
			btn.addEventListener('click', () => {
				gsap.set('.header, .dice', { y: '0' });
				it.ui.toggleRulesScreen(true);
			})
		);

		$('.js-tutorial-step-btn')?.addEventListener('click', () => {
			const step = it.nextStep();
			if (step === 4) it.timeline.play();
		});

		it.tutorial?.addEventListener('touchstart', (e) => it.handleTouchStart(e), false);
		it.tutorial?.addEventListener(
			'touchmove',
			(e) => {
				const step = it.handleTouchMove(e);
				if (step === 4) it.timeline.play();
			},
			false
		);
	}

	private stopOnboarding() {
		const it = this;

		it.timeline.kill();
		it.ui.animationRunning = false;
		it.map.dragInstance.enable();
		it.user.status.hasPassedTutorial = true;

		gsap.to('.header, .dice', { y: '0' });
	}

	private nextStep() {
		const it = this;

		if (it.currentStep + 1 > 4) return;

		it.currentStep++;
		it.setCurrentStep(it.currentStep);

		if (it.currentStep === 4) {
			it.api.passTutorial();
		}
		return it.currentStep;
	}

	private prevStep() {
		const it = this;

		if (it.currentStep - 1 === 0) return;

		it.currentStep--;
		it.setCurrentStep(it.currentStep);
		return it.currentStep;
	}

	private setCurrentStep(step: number) {
		const it = this;

		gsap.to(it.step1, { opacity: 0, duration: 0.15 });
		gsap.to(it.step2, { opacity: 0, duration: 0.15 });
		gsap.to(it.step3, { opacity: 0, duration: 0.15 });

		switch (step) {
			case 1:
				gsap.to(it.step1, { opacity: 1, duration: 0.15 });
				it.tutorialBtn.firstElementChild.textContent = 'Хорошо';
				it.tutorialBtn.lastElementChild.textContent = '1 / 3';
				it.rulesBtn.style.display = 'block';
				break;
			case 2:
				gsap.to(it.step2, { opacity: 1, duration: 0.15 });
				it.tutorialBtn.firstElementChild.textContent = 'Понятно';
				it.tutorialBtn.lastElementChild.textContent = '2 / 3';
				break;
			case 3:
				gsap.to(it.step3, { opacity: 1, duration: 0.15 });
				it.tutorialBtn.firstElementChild.textContent = 'Отлично';
				it.tutorialBtn.lastElementChild.textContent = '3 / 3';
				break;
			// case 4:
			// 	gsap.to(it.step4, { opacity: 1, duration: 0.15 });
			// 	it.tutorialBtn.firstElementChild.textContent = 'Начинаем!';
			// 	it.tutorialBtn.lastElementChild.textContent = '4 / 4';
			// 	break;
			case 4:
				it.tutorial.classList.remove('_active');
				break;
		}

		it.rulesBtn.style.display = step > 1 ? 'none' : 'inline-block';
		[...$$('.tutorial__progress button')].forEach((button) => {
			button.classList.remove('_active');
		});
		$(`.tutorial__progress button._${step}`)?.classList.add('_active');
	}

	private getTouches(e) {
		return (
			e.touches || // browser API
			e.originalEvent.touches
		); // jQuery
	}

	private handleTouchStart(e) {
		const it = this;

		const firstTouch = it.getTouches(e)[0];
		it.xDown = firstTouch.clientX;
		it.yDown = firstTouch.clientY;
	}

	private handleTouchMove(e) {
		const it = this;

		if (!it.xDown || !it.yDown) {
			return;
		}

		let xUp = e.touches[0].clientX;
		let yUp = e.touches[0].clientY;

		let xDiff = it.xDown - xUp;
		let yDiff = it.yDown - yUp;

		if (Math.abs(xDiff) > Math.abs(yDiff)) {
			if (xDiff > 0) {
				/* right swipe */
				it.nextStep();
			} else {
				/* left swipe */
				it.prevStep();
			}
		}
		it.xDown = null;
		it.yDown = null;

		return it.currentStep;
	}
}
