export const mapData = {
	data: {
		cells: [
			{
				id: 1,
				type: 'empty',
				sellerId: 4423813829,
				sellerTitle: 'AliExpress',
				sellerSubtitle:
					'Скидки, купоны, промокоды, распродажи. Огромный выбор товаров и выгодные цены на электронику и бытовую технику, одежду для детей и взрослых, товары для дома и сада и многое другое на AliExpress.',
				link: 'https://aliexpress.ru',
				logoUrl: 'map/1/AliExpressIcon-min.png',
				mapLogoURL: '',
				contentImages: [],
			},
			{
				id: 2,
				type: 'seller',
				sellerId: 229766518,
				sellerTitle: 'Mi',
				sellerSubtitle: 'Mi Store',
				link: 'https://aliexpress.ru/store/4491087',
				logoUrl: 'map/2/Brand=Xiaomi.png',
				mapLogoURL: 'map/2/229766518.png',
				contentImages: ['map/offers/Brand=Xiaomi.png'],
			},
			{
				id: 3,
				type: 'seller',
				sellerId: 944834602,
				sellerTitle: 'Crocs',
				sellerSubtitle: 'Crocs Official Store',
				link: 'https://crocs.aliexpress.ru/store/4631023',
				logoUrl: 'map/3/HTB14hreaAxz61VjSZFrq6xeLFXaZ.jpeg',
				mapLogoURL: 'map/3/944834602.png',
				contentImages: [
					'map/3/Ha8204675e06744db95e765fbda0d8f6fv.jpg_640x640.webp',
					'map/3/H92f28aca0a834ed0bfb22c9fa90c0b97l.jpg_640x640.jpg',
				],
			},
			{
				id: 4,
				type: 'seller',
				sellerId: 982957467,
				sellerTitle: 'Samsung',
				sellerSubtitle: 'Samsung Store',
				link: 'https://samsung.aliexpress.ru/store/5602272',
				logoUrl: 'map/4/O1CN010Ut1DT1TN2wnejE9W_!!6000000002369-2-tps-112-112.png',
				mapLogoURL: 'map/4/982957467.png',
				contentImages: [],
			},
			{
				id: 5,
				type: 'seller',
				sellerId: 220712488,
				sellerTitle: 'DEKO',
				sellerSubtitle: 'DEKO Store',
				link: 'https://deko.aliexpress.com/store/1854241',
				logoUrl: 'map/5/HTB1nN2vcgMPMeJjy1Xcq6xpppXad.jpg',
				mapLogoURL: 'map/5/220712488.png',
				contentImages: [],
			},
			{
				id: 6,
				type: 'seller',
				sellerId: 965720389,
				sellerTitle: 'PUMA',
				sellerSubtitle: 'PUMA Store',
				link: 'https://puma.aliexpress.ru/store/5253008',
				logoUrl: 'map/6/H6f9e7dd255604ef8bd3362e8be06a665w.jpg',
				mapLogoURL: 'map/6/965720389.png',
				contentImages: [],
			},
			{
				id: 7,
				type: 'seller',
				sellerId: 962251956,
				sellerTitle: 'MOLNIA',
				sellerSubtitle: 'MOLNIA Store',
				link: 'https://molnia.aliexpress.ru/store/5087087',
				logoUrl: '',
				mapLogoURL: 'map/7/5087087.png',
				contentImages: [],
			},
			{
				id: 8,
				type: 'seller',
				sellerId: 4423813829,
				sellerTitle: 'AliExpress',
				sellerSubtitle: 'AliExpress Russia',
				link: 'https://aliexpress.ru',
				logoUrl: 'map/8/AliExpressIcon-min.png',
				mapLogoURL: 'map/8/aliexpress-cell.png',
				contentImages: ['map/8/AliExpressIcon-min.png', 'map/8/AliExpressIcon-min.png'],
			},
			{
				id: 9,
				type: 'seller',
				sellerId: 952538221,
				sellerTitle: 'Asus',
				sellerSubtitle: 'Asus Store',
				link: 'https://ru.aliexpress.com/store/4924114',
				logoUrl: 'map/9/O1CN01xpUnrM1WAAf2V4aNh_!!6000000002747-2-tps-112-112.png',
				mapLogoURL: 'map/9/952538221.png',
				contentImages: [],
			},
			{
				id: 10,
				type: 'seller',
				sellerId: 965937103,
				sellerTitle: 'SOKOLOV',
				sellerSubtitle: 'SOKOLOV Store',
				link: 'https://sokolov.aliexpress.ru/store/5261005',
				logoUrl: 'map/10/O1CN01ntHR6o1JPCejDw9Df_!!6000000001020-2-tps-112-112.png',
				mapLogoURL: 'map/10/965937103.png',
				contentImages: [],
			},
			{
				id: 11,
				type: 'seller',
				sellerId: 232981631,
				sellerTitle: '70mai',
				sellerSubtitle: '70mai Store',
				link: 'https://70mai.aliexpress.com/store/4257014',
				logoUrl: 'map/11/HTB1zckUX6nuK1RkSmFPq6AuzFXaG.jpg',
				mapLogoURL: 'map/11/232981631.png',
				contentImages: [],
			},
			{
				id: 12,
				type: 'seller',
				sellerId: 973149782,
				sellerTitle: 'Salomon',
				sellerSubtitle: 'Salomon Store',
				link: 'https://salomon.aliexpress.ru/store/5430328',
				logoUrl: '',
				mapLogoURL: 'map/12/973149782.png',
				contentImages: [],
			},
			{
				id: 13,
				type: 'seller',
				sellerId: 923080806,
				sellerTitle: 'Lego',
				sellerSubtitle: 'Lego Store',
				link: 'https://aliexpress.ru/store/3199003',
				logoUrl: 'map/13/Udc2b5916a667415e922c4c5257629b5bf.png',
				mapLogoURL: 'map/13/923080806.png',
				contentImages: [
					'map/13/S07295b40b676474e92dbbe08a6051925J.jpg',
					'map/13/H9c994b4b018141a9bb63f3d71ecf52d4h.jpg_200x200.jpg',
				],
			},
			{
				id: 14,
				type: 'superbox',
				sellerId: 4423813829,
				sellerTitle: 'AliExpress',
				sellerSubtitle: 'AliExpress Russia',
				link: 'https://aliexpress.ru',
				logoUrl: 'map/14/AliExpressIcon-min.png',
				mapLogoURL: 'map/14/box-icon.svg',
				contentImages: ['map/14/AliExpressIcon-min.png', 'map/14/AliExpressIcon-min.png'],
			},
			{
				id: 15,
				type: 'seller',
				sellerId: 234490267,
				sellerTitle: 'Dreame',
				sellerSubtitle: 'Dreame Store',
				link: 'https://dreame.aliexpress.com/store/4493044',
				logoUrl: '',
				mapLogoURL: 'map/15/234490267.png',
				contentImages: [],
			},
			{
				id: 16,
				type: 'seller',
				sellerId: 1000800257,
				sellerTitle: 'Indesit',
				sellerSubtitle: 'Indesit Store',
				link: 'https://aliexpress.ru/store/5892597',
				logoUrl: '',
				mapLogoURL: 'map/16/1000800257.png',
				contentImages: [],
			},
			{
				id: 17,
				type: 'seller',
				sellerId: 964541386,
				sellerTitle: 'LG',
				sellerSubtitle: 'LG Store',
				link: 'https://aliexpress.ru/store/5249013',
				logoUrl: '',
				mapLogoURL: 'map/17/964541386.png',
				contentImages: [],
			},
			{
				id: 18,
				type: 'seller',
				sellerId: 969556668,
				sellerTitle: 'Tefal',
				sellerSubtitle: 'Tefal Store',
				link: 'https://aliexpress.ru/store/5367291',
				logoUrl: 'map/18/H3e1dfa6b91e74be5b3e39025362ad1aem.png',
				mapLogoURL: 'map/18/969556668.png',
				contentImages: [],
			},
			{
				id: 19,
				type: 'seller',
				sellerId: 959763778,
				sellerTitle: 'Smartlife',
				sellerSubtitle: 'Smartlife Store',
				link: 'https://aliexpress.ru/store/5080103',
				logoUrl: '',
				mapLogoURL: 'map/19/5080103.png',
				contentImages: [],
			},
			{
				id: 20,
				type: 'seller',
				sellerId: 1002987299,
				sellerTitle: 'HP',
				sellerSubtitle: 'HP Store',
				link: 'https://hprussia.aliexpress.ru/store/900238075',
				logoUrl: 'map/20//H9fceb223d79e4a898fc60be21cc9e5bbx.png',
				mapLogoURL: 'map/20/1002987299.png',
				contentImages: [],
			},
			{
				id: 21,
				type: 'seller',
				sellerId: 992602613,
				sellerTitle: 'ТЕХНОПАРК',
				sellerSubtitle: 'ТЕХНОПАРК Store',
				link: 'https://aliexpress.ru/store/5798963',
				logoUrl: '',
				mapLogoURL: 'map/21/992602613.png',
				contentImages: [],
			},
			{
				id: 22,
				type: 'seller',
				sellerId: 229766518,
				sellerTitle: 'Mi',
				sellerSubtitle: 'Mi Store',
				link: 'https://aliexpress.ru/store/4491087',
				logoUrl: 'map/22/Brand=Xiaomi.png',
				mapLogoURL: 'map/22/229766518.png',
				contentImages: ['map/22/Brand=Xiaomi.png'],
			},
			{
				id: 23,
				type: 'seller',
				sellerId: 959009814,
				sellerTitle: 'JBL',
				sellerSubtitle: 'JBL Store',
				link: 'https://promotion.aliexpress.ru/wow/gcp/aer/daily/aer/promo3c/FDRbCPfA2H?wh_weex=true&_immersiveMode=true&wx_navbar_hidden=true&wx_navbar_transparent=true&ignoreNavigationBar=true&wx_statusbar_hidden=true',
				logoUrl: 'map/23/O1CN01GZNwhj1MjIsuTqFSt_!!6000000001470-2-tps-112-112.png',
				mapLogoURL: 'map/23/959009814.png',
				contentImages: [],
			},
			{
				id: 24,
				type: 'seller',
				sellerId: 994671620,
				sellerTitle: 'Nintendo',
				sellerSubtitle: 'Nintendo Store',
				link: 'https://aliexpress.ru/store/5880008',
				logoUrl: 'map/24/O1CN01BlE72I1QfPzDQ6Gms_!!6000000002003-2-tps-112-112.png',
				mapLogoURL: 'map/24/994671620.png',
				contentImages: [],
			},
			{
				id: 25,
				type: 'seller',
				sellerId: 1054034785,
				sellerTitle: 'Подружка',
				sellerSubtitle: 'Подружка Store',
				link: 'https://podruzhka.aliexpress.ru/store/912027152',
				logoUrl: 'map/25/Hda6dde3d9df24a1ab4fc97b3d890b80c4.jpeg_640x640.jpeg',
				mapLogoURL: 'map/25/1054034785.png',
				contentImages: [],
			},
			{
				id: 26,
				type: 'seller',
				sellerId: 941978513,
				sellerTitle: 'Tmall. Все для дома',
				sellerSubtitle: 'Tmall. Все для дома Store',
				link: 'https://aliexpress.ru/store/4491088',
				logoUrl:
					'map/26/8f7a6e58-7afd-4980-bfd9-0d0f5d533b28-750x750%20%E2%80%93%20362_Tmall%201P_1005003184445821%20%E2%80%93%201.jpg',
				mapLogoURL: 'map/26/941978513.png',
				contentImages: [],
			},
			{
				id: 27,
				type: 'superbox',
				sellerId: 4423813829,
				sellerTitle: 'AliExpress',
				sellerSubtitle: 'AliExpress Russia',
				link: 'https://aliexpress.ru',
				logoUrl: 'map/27/AliExpressIcon-min.png',
				mapLogoURL: 'map/27/box-icon.svg',
				contentImages: ['map/27/AliExpressIcon-min.png', 'map/27/AliExpressIcon-min.png'],
			},
			{
				id: 28,
				type: 'seller',
				sellerId: 227126403,
				sellerTitle: 'TEYES',
				sellerSubtitle: 'TEYES Store',
				link: 'teyes.aliexpress.com',
				logoUrl: 'map/28/HTB1Z5RyAL1TBuNjy0Fjq6yjyXXav.jpg',
				mapLogoURL: 'map/28/227126403.png',
				contentImages: [],
			},
			{
				id: 29,
				type: 'seller',
				sellerId: 992142266,
				sellerTitle: 'realme',
				sellerSubtitle: 'realme Store',
				link: 'https://aliexpress.ru/store/5787899',
				logoUrl: 'map/29/H0bc1580f912f4b2db7f794daa4814278u.webp',
				mapLogoURL: 'map/15/234490267.png',
				contentImages: [],
			},
			{
				id: 30,
				type: 'seller',
				sellerId: 975855925,
				sellerTitle: 'Microsoft/xbox',
				sellerSubtitle: 'Microsoft/xbox Store',
				link: 'https://aliexpress.ru/store/v3/home.html?pagePath=index.htm&shopId=5496228&srcSns=sns_Copy&sellerAdminSeq=975855925&social_params=40102643211&sellerId=975855925&aff_fcid=3e6e477bf506459891652b8f881721e2-1632306612067-03087-_9hvxom&tt=MG&aff_fsk=_9hvxom&aff_platform=default&sk=_9hvxom&aff_trace_key=3e6e477bf506459891652b8f881721e2-1632306612067-03087-_9hvxom&shareId=40102643211&platform=AE&terminal_id=723bc0799575461386adc9d04878b139',
				logoUrl: 'map/30/O1CN01WTozGc1dkXQ6spMij_!!6000000003774-2-tps-112-112.png',
				mapLogoURL: 'map/30/975855925.png',
				contentImages: [],
			},
			{
				id: 31,
				type: 'seller',
				sellerId: 923080806,
				sellerTitle: 'Lego',
				sellerSubtitle: 'Lego Store',
				link: 'https://aliexpress.ru/store/3199003',
				logoUrl: 'map/31/Udc2b5916a667415e922c4c5257629b5bf.png',
				mapLogoURL: 'map/31/923080806.png',
				contentImages: [
					'map/31/S07295b40b676474e92dbbe08a6051925J.jpg',
					'map/31/H9c994b4b018141a9bb63f3d71ecf52d4h.jpg_200x200.jpg',
				],
			},
			{
				id: 32,
				type: 'seller',
				sellerId: 987339791,
				sellerTitle: 'Улыбка Радуги',
				sellerSubtitle: 'Улыбка Радуги Store',
				link: 'https://r-ulybka.aliexpress.ru/store/5714069',
				logoUrl: '',
				mapLogoURL: 'map/32/987339791.png',
				contentImages: [],
			},
			{
				id: 33,
				type: 'seller',
				sellerId: 971877411,
				sellerTitle: 'REDMOND',
				sellerSubtitle: 'REDMOND Store',
				link: 'https://aliexpress.ru/store/5425205',
				logoUrl: '',
				mapLogoURL: 'map/33/971877411.png',
				contentImages: [],
			},
			{
				id: 34,
				type: 'seller',
				sellerId: 944834602,
				sellerTitle: 'Crocs',
				sellerSubtitle: 'Crocs Official Store',
				link: 'https://crocs.aliexpress.ru/store/4631023',
				logoUrl: 'map/34/HTB14hreaAxz61VjSZFrq6xeLFXaZ.jpeg',
				mapLogoURL: 'map/34/944834602.png',
				contentImages: [
					'map/34/Ha8204675e06744db95e765fbda0d8f6fv.jpg_640x640.webp',
					'map/34/H92f28aca0a834ed0bfb22c9fa90c0b97l.jpg_640x640.jpg',
				],
			},
			{
				id: 35,
				type: 'seller',
				sellerId: 923080806,
				sellerTitle: 'Lego',
				sellerSubtitle: 'Lego Store',
				link: 'https://aliexpress.ru/store/3199003',
				logoUrl: 'map/35/Udc2b5916a667415e922c4c5257629b5bf.png',
				mapLogoURL: 'map/35/923080806.png',
				contentImages: [
					'map/35/S07295b40b676474e92dbbe08a6051925J.jpg',
					'map/35/H9c994b4b018141a9bb63f3d71ecf52d4h.jpg_200x200.jpg',
				],
			},
			{
				id: 36,
				type: 'seller',
				sellerId: 944834602,
				sellerTitle: 'Crocs',
				sellerSubtitle: 'Crocs Official Store',
				link: 'https://crocs.aliexpress.ru/store/4631023',
				logoUrl: 'map/36/HTB14hreaAxz61VjSZFrq6xeLFXaZ.jpeg',
				mapLogoURL: 'map/36/944834602.png',
				contentImages: [
					'map/36/Ha8204675e06744db95e765fbda0d8f6fv.jpg_640x640.webp',
					'map/36/H92f28aca0a834ed0bfb22c9fa90c0b97l.jpg_640x640.jpg',
				],
			},
			{
				id: 37,
				type: 'seller',
				sellerId: 4423813829,
				sellerTitle: 'AliExpress',
				sellerSubtitle: 'AliExpress Russia',
				link: 'https://aliexpress.ru',
				logoUrl: 'map/37/AliExpressIcon-min.png',
				mapLogoURL: 'map/37/aliexpress-cell.png',
				contentImages: ['map/37/AliExpressIcon-min.png', 'map/37/AliExpressIcon-min.png'],
			},
			{
				id: 38,
				type: 'seller',
				sellerId: 122820548,
				sellerTitle: '17KM',
				sellerSubtitle: 'CB\tNST',
				link: 'https://17km.aliexpress.com/store/908304',
				logoUrl: '',
				mapLogoURL: 'map/38/aliexpress-cell.png',
				contentImages: [],
			},
			{
				id: 39,
				type: 'seller',
				sellerId: 229766518,
				sellerTitle: 'Mi',
				sellerSubtitle: 'Mi Store',
				link: 'https://aliexpress.ru/store/4491087',
				logoUrl: 'map/39/Brand=Xiaomi.png',
				mapLogoURL: 'map/39/229766518.png',
				contentImages: ['map/39/Brand=Xiaomi.png'],
			},
			{
				id: 40,
				type: 'superbox',
				sellerId: 4423813829,
				sellerTitle: 'AliExpress',
				sellerSubtitle: 'AliExpress Russia',
				link: 'https://aliexpress.ru',
				logoUrl: 'map/40/AliExpressIcon-min.png',
				mapLogoURL: 'map/40/box-icon.svg',
				contentImages: ['map/40/AliExpressIcon-min.png', 'map/40/AliExpressIcon-min.png'],
			},
		],
	},
};

export const statusData = {
	data: {
		currentCellId: 1,
		totalCellsCount: 40,
		completedStepsCount: 0,
		availableStepsCount: 5,
		isFirstMove: false,
		receivedChestsCount: 0,
		currentState: {
			prize: null,
			newSteps: null,
		},
		timeToNewDay: 72766262,
		promoTimeType: 'promo',
		timeToPromoTypeEnd: 18047566262,
		hasPassedTutorial: false,
		needToSendSuperprizeSubmission: false,
		needToUpdateApp: false,
	},
};

export const passTutorialData = {
	data: {
		message: 'success',
	},
};

export const getTasksData = {
	data: {
		tasks: [
			{
				type: 'invite_friend',
				content: {
					title: 'Рассказать другу об игре',
					description: 'Сможете получить один ход, если друг перейдет по ссылке и бросит кубик',
					link: 'https://promotion.aliexpress.ru/wow/gcp/fWeeDWcSs5?_immersiveMode=true&wx_navbar_hidden=true&wx_navbar_transparent=true&ignoreNavigationBar=true&wx_statusbar_hidden=true&share=2025',
					link_title: 'Отправить ссылку',
				},
				status: 'completed',
			},
			{
				type: 'daily_activity',
				content: {
					title: 'Зайти в игру 5 дней',
					description: 'Сможете получить 5 ходов за 5 дней. Новый день — новый ход',
					days_streak_count: 10,
					total_streak_count: 5,
				},
				status: 'completed',
			},
			{
				type: 'purchase',
				content: {
					title: 'Оформить заказ на сумму от 1337 ₽',
					description: 'Даём один ход за заказ. Советуем не отменять — не сможете получить суперкупоны',
					link: 'https://campaign.aliexpress.com/wow/gcp/ae/channel/ae/accelerate/tupr?wh_pid=/jjjgf/ywe5k68cj2&wh_weex=true&wx_navbar_hidden=true&wx_navbar_transparent=true&wx_statusbar_hidden=true',
					link_title: 'Выбрать товар',
					orders_label: '',
				},
				status: 'in_progress',
			},
		],
		links: {
			title: 'Узнать всё о распродаже',
			subtitle: 'Если откроете все ссылки, то получите ход',
			links: [
				{
					id: 16,
					title: 'Главная страница большой распродажи',
					link: 'https://campaign.aliexpress.com/wow/gcp/ae/channel/ae/accelerate/tupr?wh_pid=/jjjgf/ywe5k68cj2&wh_weex=true&wx_navbar_hidden=true&wx_navbar_transparent=true&wx_statusbar_hidden=true',
					status: 'new',
				},
				{
					id: 17,
					title: 'Популярные товары с\\u00A0ускоренной доставкой',
					link: 'https://campaign.aliexpress.com/wow/gcp/ae/channel/ae/accelerate/tupr?wh_weex=true&_immersiveMode=true&wx_navbar_hidden=true&wx_navbar_transparent=true&ignoreNavigationBar=true&wx_statusbar_hidden=true&wh_pid=jjjgf/SPHWTertce',
					status: 'new',
				},
				{
					id: 18,
					title: 'Хиты техники со\\u00A0скидками до\\u00A0-60%',
					link: 'https://promotion.aliexpress.ru/wow/gcp/aer/channel/aer/bombs/sthits?wh_weex=true&_immersiveMode=true&wx_navbar_hidden=true&wx_navbar_transparent=true&ignoreNavigationBar=true&wx_statusbar_hidden=true',
					status: 'new',
				},
				{
					id: 19,
					title: 'Всё для дома и семьи со\\u00A0скидками до\\u00A0-75%',
					link: 'https://campaign.aliexpress.com/wow/gcp/ae/channel/ae/accelerate/tupr?wh_weex=true&_immersiveMode=true&wx_navbar_hidden=true&wx_navbar_transparent=true&ignoreNavigationBar=true&wx_statusbar_hidden=true&wh_pid=jjjgf/7fACQtHDc4',
					status: 'new',
				},
				{
					id: 20,
					title: 'Товары из Китая с\\u00A0доставкой до\\u00A03\\u00A0дней',
					link: 'https://promotion.aliexpress.ru/wow/gcp/aer/channel/aer/bombs/cbhits?wh_weex=true&_immersiveMode=true&wx_navbar_hidden=true&wx_navbar_transparent=true&ignoreNavigationBar=true&wx_statusbar_hidden=true',
					status: 'new',
				},
			],
		},
	},
};

export const getChestPrizesData = {
	data: {
		selectedCategory: 'Все категории',
		prizeCategories: [
			{
				categoryName: 'Автотовары',
				prizes: [
					{
						id: 915,
						type: 'superbox',
						category: 'Автотовары',
						amount: 7000,
						threshold: 10000,
						validFrom: 1648470223225,
						validTill: 1648470223225,
						title: 'Все товары',
						subtitle: '',
						sellerId: 965720389,
						sellerTitle: 'PUMA',
						sellerSubtitle: 'PUMA Store',
						imageUrl: '',
						link: '',
						price: 3,
					},
					{
						id: 152,
						type: 'superbox',
						category: 'Автотовары',
						amount: 20735,
						threshold: 205298,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 228086465,
						sellerTitle: 'YOYA TOP',
						sellerSubtitle: 'OWH\tNST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
						price: 2,
					},
				],
			},
			{
				categoryName: 'Компьютеры',
				prizes: [
					{
						id: 154,
						type: 'superbox',
						category: 'Компьютеры',
						amount: 22495,
						threshold: 222730,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 247453068,
						sellerTitle: 'POCO Direct',
						sellerSubtitle: 'OWH\tST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
						price: 4,
					},
				],
			},
			{
				categoryName: 'Красота и здоровье',
				prizes: [
					{
						id: 163,
						type: 'superbox',
						category: 'Красота и здоровье',
						amount: 86892,
						threshold: 860318,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 955814039,
						sellerTitle: 'Polaris',
						sellerSubtitle: 'Local\tST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
						price: 3,
					},
					{
						id: 151,
						type: 'superbox',
						category: 'Красота и здоровье',
						amount: 20426,
						threshold: 202239,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 224687538,
						sellerTitle: 'TIGER FORCE',
						sellerSubtitle: 'OWH\tNST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
						price: 2,
					},
				],
			},
			{
				categoryName: 'Одежда',
				prizes: [
					{
						id: 156,
						type: 'superbox',
						category: 'Одежда',
						amount: 82808,
						threshold: 819885,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 910893005,
						sellerTitle: 'FRAP',
						sellerSubtitle: 'Local\tST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
						price: 4,
					},
				],
			},
			{
				categoryName: 'Электроника',
				prizes: [
					{
						id: 160,
						type: 'superbox',
						category: 'Электроника',
						amount: 82915,
						threshold: 820949,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 912074797,
						sellerTitle: 'infinix',
						sellerSubtitle: 'Local\tST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
						price: 6,
					},
				],
			},
			{
				categoryName: 'Category 1',
				prizes: [
					{
						id: 910,
						type: 'superbox',
						category: 'Category 1',
						amount: 15000,
						threshold: 15001,
						validFrom: 1648470223225,
						validTill: 1648470223225,
						title: 'Все товары',
						subtitle: '',
						sellerId: 962251956,
						sellerTitle: 'MOLNIA',
						sellerSubtitle: 'MOLNIA Store',
						imageUrl: '',
						link: '',
						price: 5,
					},
					{
						id: 916,
						type: 'superbox',
						category: 'Category 1',
						amount: 15000,
						threshold: 15001,
						validFrom: 1648470223225,
						validTill: 1648470223225,
						title: 'Все товары',
						subtitle: '',
						sellerId: 959763778,
						sellerTitle: 'Smartlife',
						sellerSubtitle: 'Smartlife Store',
						imageUrl: '',
						link: '',
						price: 5,
					},
					{
						id: 5,
						type: 'superbox',
						category: 'Category 1',
						amount: 4570,
						threshold: 4580,
						validFrom: 1645574737727,
						validTill: 1646006737727,
						title: 'Все товары',
						subtitle: 'Заберите этот приз со скидкой 99%',
						sellerId: 4423813829,
						sellerTitle: 'AliExpress',
						sellerSubtitle: 'AliExpress Russia',
						imageUrl:
							'https://st.aliexpress.ru/bx-alibox-api-storage/8f7a6e58-7afd-4980-bfd9-0d0f5d533b28-750x750%20%E2%80%93%20362_Tmall%201P_1005003184445821%20%E2%80%93%201.jpg',
						link: 'https://aliexpress.ru/item/1005003184445821.html?item_id=1005003282820469&sku_id=12000024553526750&spm=a2g2w.productlist.0.0.79ca1e2b8sxz1H',
						price: 3,
					},
				],
			},
			{
				categoryName: 'Для детей',
				prizes: [
					{
						id: 158,
						type: 'superbox',
						category: 'Для детей',
						amount: 82891,
						threshold: 820706,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 911805169,
						sellerTitle: 'EMPEROR CAMP',
						sellerSubtitle: 'Local\tST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
						price: 3,
					},
				],
			},
			{
				categoryName: 'Украшения',
				prizes: [
					{
						id: 157,
						type: 'superbox',
						category: 'Украшения',
						amount: 82894,
						threshold: 820735,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 911837006,
						sellerTitle: 'Roidmi, Dreame',
						sellerSubtitle: 'Local\tST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
						price: 3,
					},
				],
			},
			{
				categoryName: 'Дом и сад, инструкменты',
				prizes: [
					{
						id: 153,
						type: 'superbox',
						category: 'Дом и сад, инструкменты',
						amount: 21970,
						threshold: 217532,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 241678379,
						sellerTitle: 'Mi Discount',
						sellerSubtitle: 'OWH\tNST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
						price: 2,
					},
				],
			},
			{
				categoryName: 'Смартфоны',
				prizes: [
					{
						id: 159,
						type: 'superbox',
						category: 'Смартфоны',
						amount: 82860,
						threshold: 820399,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 911464037,
						sellerTitle: 'vivo',
						sellerSubtitle: 'Local\tST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
						price: 3,
					},
				],
			},
		],
	},
};

export const makeStepData = {
	data: {
		selectedCategory: '',
		alternativePrizes: [],
		receivedPrize: {
			id: 206,
			type: 'seller_coupon',
			category: 'Электроника',
			amount: 91180,
			threshold: 902778,
			validFrom: 1646136529770,
			validTill: 1646568529770,
			title: 'Все товары',
			subtitle: 'все товары',
			sellerId: 1002987299,
			sellerTitle: 'HP',
			sellerSubtitle: 'HP Store',
			imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
			link: 'https://aliexpress.ru',
		},
		status: 'success',
		newCellId: 20,
		totalCellsCount: 40,
		diceNumber: 1,
		completedStepsCount: 6,
		availableStepsCount: 45,
		receivedChestsCount: 2,
		needToSendSuperprizeSubmission: false,
	},
};

export const getAlternativePrizesData = {
	data: {
		selectedCategory: 'Все категории',
		prizeCategories: [
			{
				categoryName: 'Для детей',
				prizes: [
					{
						id: 955,
						type: 'seller_coupon',
						category: 'Для детей',
						amount: 200,
						threshold: 2000,
						validFrom: 1648720453827,
						validTill: 1648720453827,
						title: 'Все товары',
						subtitle: '',
						sellerId: 984444754,
						sellerTitle: 'Oldos',
						sellerSubtitle: '',
						imageUrl: '',
						link: 'https://aliexpress.ru/store/5622002',
					},
					{
						id: 956,
						type: 'seller_coupon',
						category: 'Для детей',
						amount: 350,
						threshold: 3500,
						validFrom: 1648720453827,
						validTill: 1648720453827,
						title: 'Все товары',
						subtitle: '',
						sellerId: 984444754,
						sellerTitle: 'Oldos',
						sellerSubtitle: '',
						imageUrl: '',
						link: 'https://aliexpress.ru/store/5622002',
					},
					{
						id: 957,
						type: 'seller_coupon',
						category: 'Для детей',
						amount: 500,
						threshold: 5000,
						validFrom: 1648720453827,
						validTill: 1648720453827,
						title: 'Все товары',
						subtitle: '',
						sellerId: 984444754,
						sellerTitle: 'Oldos',
						sellerSubtitle: '',
						imageUrl: '',
						link: 'https://aliexpress.ru/store/5622002',
					},
					{
						id: 958,
						type: 'seller_coupon',
						category: 'Для детей',
						amount: 600,
						threshold: 6000,
						validFrom: 1648720453827,
						validTill: 1648720453827,
						title: 'Все товары',
						subtitle: '',
						sellerId: 984444754,
						sellerTitle: 'Oldos',
						sellerSubtitle: '',
						imageUrl: '',
						link: 'https://aliexpress.ru/store/5622002',
					},
					{
						id: 959,
						type: 'seller_coupon',
						category: 'Для детей',
						amount: 700,
						threshold: 7000,
						validFrom: 1648720453827,
						validTill: 1648720453827,
						title: 'Все товары',
						subtitle: '',
						sellerId: 984444754,
						sellerTitle: 'Oldos',
						sellerSubtitle: '',
						imageUrl: '',
						link: 'https://aliexpress.ru/store/5622002',
					},
					{
						id: 960,
						type: 'seller_coupon',
						category: 'Для детей',
						amount: 100,
						threshold: 1000,
						validFrom: 1648720453827,
						validTill: 1648720453827,
						title: 'Все товары',
						subtitle: '',
						sellerId: 1049065802,
						sellerTitle: 'BUTTON BLUE',
						sellerSubtitle: '',
						imageUrl: '',
						link: 'https://buttonblue.aliexpress.ru/store/911885253',
					},
					{
						id: 961,
						type: 'seller_coupon',
						category: 'Для детей',
						amount: 200,
						threshold: 1500,
						validFrom: 1648720453827,
						validTill: 1648720453827,
						title: 'Все товары',
						subtitle: '',
						sellerId: 1049065802,
						sellerTitle: 'BUTTON BLUE',
						sellerSubtitle: '',
						imageUrl: '',
						link: 'https://buttonblue.aliexpress.ru/store/911885253',
					},
					{
						id: 962,
						type: 'seller_coupon',
						category: 'Для детей',
						amount: 300,
						threshold: 2000,
						validFrom: 1648720453827,
						validTill: 1648720453827,
						title: 'Все товары',
						subtitle: '',
						sellerId: 1049065802,
						sellerTitle: 'BUTTON BLUE',
						sellerSubtitle: '',
						imageUrl: '',
						link: 'https://buttonblue.aliexpress.ru/store/911885253',
					},
					{
						id: 963,
						type: 'seller_coupon',
						category: 'Для детей',
						amount: 400,
						threshold: 2500,
						validFrom: 1648720453827,
						validTill: 1648720453827,
						title: 'Все товары',
						subtitle: '',
						sellerId: 1049065802,
						sellerTitle: 'BUTTON BLUE',
						sellerSubtitle: '',
						imageUrl: '',
						link: 'https://buttonblue.aliexpress.ru/store/911885253',
					},
					{
						id: 964,
						type: 'seller_coupon',
						category: 'Для детей',
						amount: 500,
						threshold: 3500,
						validFrom: 1648720453827,
						validTill: 1648720453827,
						title: 'Все товары',
						subtitle: '',
						sellerId: 1049065802,
						sellerTitle: 'BUTTON BLUE',
						sellerSubtitle: '',
						imageUrl: '',
						link: 'https://buttonblue.aliexpress.ru/store/911885253',
					},
					{
						id: 965,
						type: 'seller_coupon',
						category: 'Для детей',
						amount: 150,
						threshold: 1000,
						validFrom: 1648720453827,
						validTill: 1648720453827,
						title: 'Все товары',
						subtitle: '',
						sellerId: 987896455,
						sellerTitle: 'Gulliver Market',
						sellerSubtitle: '',
						imageUrl: '',
						link: 'https://aliexpress.ru/store/5740033',
					},
					{
						id: 966,
						type: 'seller_coupon',
						category: 'Для детей',
						amount: 200,
						threshold: 1500,
						validFrom: 1648720453827,
						validTill: 1648720453827,
						title: 'Все товары',
						subtitle: '',
						sellerId: 987896455,
						sellerTitle: 'Gulliver Market',
						sellerSubtitle: '',
						imageUrl: '',
						link: 'https://aliexpress.ru/store/5740033',
					},
					{
						id: 967,
						type: 'seller_coupon',
						category: 'Для детей',
						amount: 300,
						threshold: 2000,
						validFrom: 1648720453827,
						validTill: 1648720453827,
						title: 'Все товары',
						subtitle: '',
						sellerId: 987896455,
						sellerTitle: 'Gulliver Market',
						sellerSubtitle: '',
						imageUrl: '',
						link: 'https://aliexpress.ru/store/5740033',
					},
					{
						id: 968,
						type: 'seller_coupon',
						category: 'Для детей',
						amount: 400,
						threshold: 2500,
						validFrom: 1648720453827,
						validTill: 1648720453827,
						title: 'Все товары',
						subtitle: '',
						sellerId: 987896455,
						sellerTitle: 'Gulliver Market',
						sellerSubtitle: '',
						imageUrl: '',
						link: 'https://aliexpress.ru/store/5740033',
					},
					{
						id: 969,
						type: 'seller_coupon',
						category: 'Для детей',
						amount: 500,
						threshold: 3500,
						validFrom: 1648720453827,
						validTill: 1648720453827,
						title: 'Все товары',
						subtitle: '',
						sellerId: 987896455,
						sellerTitle: 'Gulliver Market',
						sellerSubtitle: '',
						imageUrl: '',
						link: 'https://aliexpress.ru/store/5740033',
					},
					{
						id: 945,
						type: 'seller_coupon',
						category: 'Для детей',
						amount: 250,
						threshold: 1600,
						validFrom: 1648557508136,
						validTill: 1648557508136,
						title: 'Все товары',
						subtitle: '',
						sellerId: 1044008213,
						sellerTitle: 'Tainika Store',
						sellerSubtitle: '',
						imageUrl: '',
						link: 'https://ru.aliexpress.com/store/911753340',
					},
					{
						id: 947,
						type: 'seller_coupon',
						category: 'Для детей',
						amount: 1000,
						threshold: 5000,
						validFrom: 1648557508136,
						validTill: 1648557508136,
						title: 'Все товары',
						subtitle: '',
						sellerId: 999897207,
						sellerTitle: 'PRODVDOM',
						sellerSubtitle: '',
						imageUrl: '',
						link: 'https://prodvdom.aliexpress.ru/store/5870723',
					},
					{
						id: 948,
						type: 'seller_coupon',
						category: 'Для детей',
						amount: 600,
						threshold: 3000,
						validFrom: 1648557508136,
						validTill: 1648557508136,
						title: 'Все товары',
						subtitle: '',
						sellerId: 999897207,
						sellerTitle: 'PRODVDOM',
						sellerSubtitle: '',
						imageUrl: '',
						link: 'https://prodvdom.aliexpress.ru/store/5870723',
					},
					{
						id: 950,
						type: 'seller_coupon',
						category: 'Для детей',
						amount: 500,
						threshold: 4000,
						validFrom: 1648557508136,
						validTill: 1648557508136,
						title: 'Все товары',
						subtitle: '',
						sellerId: 999897207,
						sellerTitle: 'PRODVDOM',
						sellerSubtitle: '',
						imageUrl: '',
						link: 'https://prodvdom.aliexpress.ru/store/5870723',
					},
					{
						id: 951,
						type: 'seller_coupon',
						category: 'Для детей',
						amount: 300,
						threshold: 2000,
						validFrom: 1648557508136,
						validTill: 1648557508136,
						title: 'Все товары',
						subtitle: '',
						sellerId: 999897207,
						sellerTitle: 'PRODVDOM',
						sellerSubtitle: '',
						imageUrl: '',
						link: 'https://prodvdom.aliexpress.ru/store/5870723',
					},
					{
						id: 86,
						type: 'seller_coupon',
						category: 'Для детей',
						amount: 86187,
						threshold: 853343,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 948065000,
						sellerTitle: 'Zigmund',
						sellerSubtitle: 'Local\tST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 954,
						type: 'seller_coupon',
						category: 'Для детей',
						amount: 400,
						threshold: 2000,
						validFrom: 1648557508136,
						validTill: 1648557508136,
						title: 'Все товары',
						subtitle: '',
						sellerId: 4695974649,
						sellerTitle: 'Dr. Korner',
						sellerSubtitle: '',
						imageUrl: '',
						link: 'https://drkorner.aliexpress.ru/store/912456499',
					},
					{
						id: 943,
						type: 'seller_coupon',
						category: 'Для детей',
						amount: 100,
						threshold: 1000,
						validFrom: 1648557508136,
						validTill: 1648557508136,
						title: 'Все товары',
						subtitle: '',
						sellerId: 1044008213,
						sellerTitle: 'Tainika Store',
						sellerSubtitle: '',
						imageUrl: '',
						link: 'https://ru.aliexpress.com/store/911753340',
					},
					{
						id: 952,
						type: 'seller_coupon',
						category: 'Для детей',
						amount: 100,
						threshold: 1000,
						validFrom: 1648557508136,
						validTill: 1648557508136,
						title: 'Все товары',
						subtitle: '',
						sellerId: 4695974649,
						sellerTitle: 'Dr. Korner',
						sellerSubtitle: '',
						imageUrl: '',
						link: 'https://drkorner.aliexpress.ru/store/912456499',
					},
					{
						id: 944,
						type: 'seller_coupon',
						category: 'Для детей',
						amount: 150,
						threshold: 1200,
						validFrom: 1648557508136,
						validTill: 1648557508136,
						title: 'Все товары',
						subtitle: '',
						sellerId: 1044008213,
						sellerTitle: 'Tainika Store',
						sellerSubtitle: '',
						imageUrl: '',
						link: 'https://ru.aliexpress.com/store/911753340',
					},
					{
						id: 949,
						type: 'seller_coupon',
						category: 'Для детей',
						amount: 200,
						threshold: 2000,
						validFrom: 1648557508136,
						validTill: 1648557508136,
						title: 'Все товары',
						subtitle: '',
						sellerId: 999897207,
						sellerTitle: 'PRODVDOM',
						sellerSubtitle: '',
						imageUrl: '',
						link: 'https://prodvdom.aliexpress.ru/store/5870723',
					},
					{
						id: 953,
						type: 'seller_coupon',
						category: 'Для детей',
						amount: 200,
						threshold: 1500,
						validFrom: 1648557508136,
						validTill: 1648557508136,
						title: 'Все товары',
						subtitle: '',
						sellerId: 4695974649,
						sellerTitle: 'Dr. Korner',
						sellerSubtitle: '',
						imageUrl: '',
						link: 'https://drkorner.aliexpress.ru/store/912456499',
					},
					{
						id: 946,
						type: 'seller_coupon',
						category: 'Для детей',
						amount: 100,
						threshold: 1000,
						validFrom: 1648557508136,
						validTill: 1648557508136,
						title: 'Все товары',
						subtitle: '',
						sellerId: 1024104790,
						sellerTitle: 'Tea Trener Store',
						sellerSubtitle: '',
						imageUrl: '',
						link: 'https://ru.aliexpress.com/store/911051238',
					},
					{
						id: 98,
						type: 'seller_coupon',
						category: 'Для детей',
						amount: 18745,
						threshold: 185594,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 206195833,
						sellerTitle: 'Byintek',
						sellerSubtitle: 'OWH\tST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 74,
						type: 'seller_coupon',
						category: 'Для детей',
						amount: 85420,
						threshold: 845746,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 939624021,
						sellerTitle: 'Alltime',
						sellerSubtitle: 'Local\tNST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 122,
						type: 'seller_coupon',
						category: 'Для детей',
						amount: 21339,
						threshold: 211278,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 234730236,
						sellerTitle: 'Aachoae Store',
						sellerSubtitle: 'CB\tNST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 134,
						type: 'seller_coupon',
						category: 'Для детей',
						amount: 11165,
						threshold: 110549,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 122820548,
						sellerTitle: '17KM',
						sellerSubtitle: 'CB\tNST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 110,
						type: 'seller_coupon',
						category: 'Для детей',
						amount: 20933,
						threshold: 207261,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 230267579,
						sellerTitle: 'WOSAI',
						sellerSubtitle: 'OWH\tST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 146,
						type: 'seller_coupon',
						category: 'Для детей',
						amount: 20963,
						threshold: 207563,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 230602588,
						sellerTitle: 'Amii',
						sellerSubtitle: 'CB\tNST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 204,
						type: 'seller_coupon',
						category: 'Для детей',
						amount: 88141,
						threshold: 872688,
						validFrom: 1646136529770,
						validTill: 1646568529770,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 969556668,
						sellerTitle: 'Tefal',
						sellerSubtitle: 'Tefal Store',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 180,
						type: 'seller_coupon',
						category: 'Для детей',
						amount: 87792,
						threshold: 869235,
						validFrom: 1646136529770,
						validTill: 1646568529770,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 965720389,
						sellerTitle: 'PUMA',
						sellerSubtitle: 'PUMA Store',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
				],
			},
			{
				categoryName: 'Дом и сад, инструкменты',
				prizes: [
					{
						id: 247,
						type: 'seller_coupon',
						category: 'Дом и сад, инструкменты',
						amount: 88472,
						threshold: 875968,
						validFrom: 1646136529770,
						validTill: 1646568529770,
						title: 'Все товары',
						subtitle: 'Электрический духовой шкаф Electrolux',
						sellerId: 973201467,
						sellerTitle: 'Techport',
						sellerSubtitle: 'Local\tNST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 105,
						type: 'seller_coupon',
						category: 'Дом и сад, инструкменты',
						amount: 20771,
						threshold: 205658,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 228486479,
						sellerTitle: 'BASEUS',
						sellerSubtitle: 'OWH\tST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 117,
						type: 'seller_coupon',
						category: 'Дом и сад, инструкменты',
						amount: 20926,
						threshold: 207191,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 230189539,
						sellerTitle: 'SDARISB',
						sellerSubtitle: 'OWH\tST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 223,
						type: 'seller_coupon',
						category: 'Дом и сад, инструкменты',
						amount: 90194,
						threshold: 893017,
						validFrom: 1646136529770,
						validTill: 1646568529770,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 992142266,
						sellerTitle: 'realme',
						sellerSubtitle: 'realme Store',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 199,
						type: 'seller_coupon',
						category: 'Дом и сад, инструкменты',
						amount: 87685,
						threshold: 868174,
						validFrom: 1646136529770,
						validTill: 1646568529770,
						title: 'Все товары',
						subtitle: 'Холодильник GA-B509CLWL',
						sellerId: 964541386,
						sellerTitle: 'LG',
						sellerSubtitle: 'LG Store',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 81,
						type: 'seller_coupon',
						category: 'Дом и сад, инструкменты',
						amount: 92908,
						threshold: 919888,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 1021996228,
						sellerTitle: 'SPLAT',
						sellerSubtitle: 'Local\tNST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 129,
						type: 'seller_coupon',
						category: 'Дом и сад, инструкменты',
						amount: 20167,
						threshold: 199677,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 221841414,
						sellerTitle: 'ICEbear',
						sellerSubtitle: 'OWH\tNST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 141,
						type: 'seller_coupon',
						category: 'Дом и сад, инструкменты',
						amount: 18733,
						threshold: 185484,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 206073761,
						sellerTitle: 'XCAN',
						sellerSubtitle: 'CB\tST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 187,
						type: 'seller_coupon',
						category: 'Дом и сад, инструкменты',
						amount: 86594,
						threshold: 857370,
						validFrom: 1646136529770,
						validTill: 1646568529770,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 952538221,
						sellerTitle: 'Asus',
						sellerSubtitle: 'Asus Store',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 211,
						type: 'seller_coupon',
						category: 'Дом и сад, инструкменты',
						amount: 90236,
						threshold: 893431,
						validFrom: 1646136529770,
						validTill: 1646568529770,
						title: 'Все товары',
						subtitle: 'Умная колонка Яндекс Макс (синий)',
						sellerId: 992602613,
						sellerTitle: 'ТЕХНОПАРК',
						sellerSubtitle: 'ТЕХНОПАРК Store',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
				],
			},
			{
				categoryName: 'Бытовая техника',
				prizes: [
					{
						id: 243,
						type: 'seller_coupon',
						category: 'Бытовая техника',
						amount: 86752,
						threshold: 858937,
						validFrom: 1646136529770,
						validTill: 1646568529770,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 954279214,
						sellerTitle: 'Beko',
						sellerSubtitle: 'Local\tST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 231,
						type: 'seller_coupon',
						category: 'Бытовая техника',
						amount: 88992,
						threshold: 881112,
						validFrom: 1646136529770,
						validTill: 1646568529770,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 978916251,
						sellerTitle: 'Huadie',
						sellerSubtitle: 'Local\tNST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 101,
						type: 'seller_coupon',
						category: 'Бытовая техника',
						amount: 20759,
						threshold: 205537,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 228352069,
						sellerTitle: 'yi',
						sellerSubtitle: 'OWH\tST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 113,
						type: 'seller_coupon',
						category: 'Бытовая техника',
						amount: 20504,
						threshold: 203014,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 225549657,
						sellerTitle: 'prostormer',
						sellerSubtitle: 'OWH\tST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 125,
						type: 'seller_coupon',
						category: 'Бытовая техника',
						amount: 21026,
						threshold: 208184,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 231292476,
						sellerTitle: 'ANDELI',
						sellerSubtitle: 'OWH\tST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 137,
						type: 'seller_coupon',
						category: 'Бытовая техника',
						amount: 21180,
						threshold: 209703,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 232980967,
						sellerTitle: 'SHAREFUNBAY',
						sellerSubtitle: 'OWH\tNST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 149,
						type: 'seller_coupon',
						category: 'Бытовая техника',
						amount: 18420,
						threshold: 182383,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 202628377,
						sellerTitle: 'CINOON',
						sellerSubtitle: 'CB\tNST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 207,
						type: 'seller_coupon',
						category: 'Бытовая техника',
						amount: 90236,
						threshold: 893431,
						validFrom: 1646136529770,
						validTill: 1646568529770,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 992602613,
						sellerTitle: 'ТЕХНОПАРК',
						sellerSubtitle: 'ТЕХНОПАРК Store',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
				],
			},
			{
				categoryName: 'Красота и здоровье',
				prizes: [
					{
						id: 233,
						type: 'seller_coupon',
						category: 'Красота и здоровье',
						amount: 88992,
						threshold: 881112,
						validFrom: 1646136529770,
						validTill: 1646568529770,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 978916251,
						sellerTitle: 'Huadie',
						sellerSubtitle: 'Local\tNST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 103,
						type: 'seller_coupon',
						category: 'Красота и здоровье',
						amount: 20181,
						threshold: 199814,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 221994305,
						sellerTitle: 'Hiseeu Official',
						sellerSubtitle: 'OWH\tST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 139,
						type: 'seller_coupon',
						category: 'Красота и здоровье',
						amount: 20962,
						threshold: 207544,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 230582080,
						sellerTitle: 'hugcitar',
						sellerSubtitle: 'CB\tNST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 185,
						type: 'seller_coupon',
						category: 'Красота и здоровье',
						amount: 87182,
						threshold: 863195,
						validFrom: 1646136529770,
						validTill: 1646568529770,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 959009814,
						sellerTitle: 'JBL',
						sellerSubtitle: 'JBL Store',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 209,
						type: 'seller_coupon',
						category: 'Красота и здоровье',
						amount: 90236,
						threshold: 893431,
						validFrom: 1646136529770,
						validTill: 1646568529770,
						title: 'Все товары',
						subtitle: 'Стиральныая машина LG',
						sellerId: 992602613,
						sellerTitle: 'ТЕХНОПАРК',
						sellerSubtitle: 'ТЕХНОПАРК Store',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 197,
						type: 'seller_coupon',
						category: 'Красота и здоровье',
						amount: 90981,
						threshold: 900810,
						validFrom: 1646136529770,
						validTill: 1646568529770,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 1000800257,
						sellerTitle: 'Indesit',
						sellerSubtitle: 'Indesit Store',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
				],
			},
			{
				categoryName: 'Одежда',
				prizes: [
					{
						id: 72,
						type: 'seller_coupon',
						category: 'Одежда',
						amount: 85420,
						threshold: 845746,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 939624021,
						sellerTitle: 'Alltime',
						sellerSubtitle: 'Local\tNST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 132,
						type: 'seller_coupon',
						category: 'Одежда',
						amount: 18205,
						threshold: 180256,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 200265482,
						sellerTitle: 'yongrow',
						sellerSubtitle: 'OWH\tNST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 144,
						type: 'seller_coupon',
						category: 'Одежда',
						amount: 20168,
						threshold: 199687,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 221853110,
						sellerTitle: 'Astrid',
						sellerSubtitle: 'OWH\tNST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 120,
						type: 'seller_coupon',
						category: 'Одежда',
						amount: 20938,
						threshold: 207314,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 230326912,
						sellerTitle: 'FEDONAS',
						sellerSubtitle: 'CB\tNST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 202,
						type: 'seller_coupon',
						category: 'Одежда',
						amount: 87685,
						threshold: 868174,
						validFrom: 1646136529770,
						validTill: 1646568529770,
						title: 'Все товары',
						subtitle: 'Холодильник GA-B509CLSL',
						sellerId: 964541386,
						sellerTitle: 'LG',
						sellerSubtitle: 'LG Store',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 108,
						type: 'seller_coupon',
						category: 'Одежда',
						amount: 21712,
						threshold: 214978,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 238841127,
						sellerTitle: 'ROIDMI',
						sellerSubtitle: 'OWH\tST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 84,
						type: 'seller_coupon',
						category: 'Одежда',
						amount: 89597,
						threshold: 887100,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 985568706,
						sellerTitle: 'Sony',
						sellerSubtitle: 'Local\tST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 214,
						type: 'seller_coupon',
						category: 'Одежда',
						amount: 89758,
						threshold: 888694,
						validFrom: 1646136529770,
						validTill: 1646568529770,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 987339791,
						sellerTitle: 'Улыбка Радуги',
						sellerSubtitle: 'Улыбка Радуги Store',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 178,
						type: 'seller_coupon',
						category: 'Одежда',
						amount: 20064,
						threshold: 198661,
						validFrom: 1646136529770,
						validTill: 1646568529770,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 220712488,
						sellerTitle: 'DEKO',
						sellerSubtitle: 'DEKO Store',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
				],
			},
			{
				categoryName: 'Другое',
				prizes: [
					{
						id: 928,
						type: 'seller_coupon',
						category: 'Другое',
						amount: 100,
						threshold: 750,
						validFrom: 1648484561255,
						validTill: 1648484561255,
						title: 'Все товары',
						subtitle: '',
						sellerId: 4721751730,
						sellerTitle: 'The Olive Tree Store',
						sellerSubtitle: '',
						imageUrl: '',
						link: 'https://aliexpress.ru/store/912501238',
					},
					{
						id: 929,
						type: 'seller_coupon',
						category: 'Другое',
						amount: 150,
						threshold: 1000,
						validFrom: 1648484561255,
						validTill: 1648484561255,
						title: 'Все товары',
						subtitle: '',
						sellerId: 4721751730,
						sellerTitle: 'The Olive Tree Store',
						sellerSubtitle: '',
						imageUrl: '',
						link: 'https://aliexpress.ru/store/912501238',
					},
					{
						id: 930,
						type: 'seller_coupon',
						category: 'Другое',
						amount: 200,
						threshold: 1500,
						validFrom: 1648484561255,
						validTill: 1648484561255,
						title: 'Все товары',
						subtitle: '',
						sellerId: 4721751730,
						sellerTitle: 'The Olive Tree Store',
						sellerSubtitle: '',
						imageUrl: '',
						link: 'https://aliexpress.ru/store/912501238',
					},
					{
						id: 931,
						type: 'seller_coupon',
						category: 'Другое',
						amount: 800,
						threshold: 4000,
						validFrom: 1648484561255,
						validTill: 1648484561255,
						title: 'Все товары',
						subtitle: '',
						sellerId: 1007517284,
						sellerTitle: 'КОНТИ Official Store',
						sellerSubtitle: '',
						imageUrl: '',
						link: 'https://aliexpress.ru/store/910315027',
					},
					{
						id: 935,
						type: 'seller_coupon',
						category: 'Другое',
						amount: 100,
						threshold: 950,
						validFrom: 1648484561255,
						validTill: 1648484561255,
						title: 'Все товары',
						subtitle: '',
						sellerId: 1007517284,
						sellerTitle: 'КОНТИ Official Store',
						sellerSubtitle: '',
						imageUrl: '',
						link: 'https://aliexpress.ru/store/910315027',
					},
					{
						id: 932,
						type: 'seller_coupon',
						category: 'Другое',
						amount: 600,
						threshold: 3350,
						validFrom: 1648484561255,
						validTill: 1648484561255,
						title: 'Все товары',
						subtitle: '',
						sellerId: 1007517284,
						sellerTitle: 'КОНТИ Official Store',
						sellerSubtitle: '',
						imageUrl: '',
						link: 'https://aliexpress.ru/store/910315027',
					},
					{
						id: 933,
						type: 'seller_coupon',
						category: 'Другое',
						amount: 300,
						threshold: 2000,
						validFrom: 1648484561255,
						validTill: 1648484561255,
						title: 'Все товары',
						subtitle: '',
						sellerId: 1007517284,
						sellerTitle: 'КОНТИ Official Store',
						sellerSubtitle: '',
						imageUrl: '',
						link: 'https://aliexpress.ru/store/910315027',
					},
					{
						id: 934,
						type: 'seller_coupon',
						category: 'Другое',
						amount: 200,
						threshold: 1700,
						validFrom: 1648484561255,
						validTill: 1648484561255,
						title: 'Все товары',
						subtitle: '',
						sellerId: 1007517284,
						sellerTitle: 'КОНТИ Official Store',
						sellerSubtitle: '',
						imageUrl: '',
						link: 'https://aliexpress.ru/store/910315027',
					},
				],
			},
			{
				categoryName: 'Электроника',
				prizes: [
					{
						id: 165,
						type: 'seller_coupon',
						category: 'Электроника',
						amount: 83916,
						threshold: 830855,
						validFrom: 1646127950496,
						validTill: 1646559950496,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 923080806,
						sellerTitle: 'Lego',
						sellerSubtitle: 'Lego Store',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 242,
						type: 'seller_coupon',
						category: 'Электроника',
						amount: 86752,
						threshold: 858937,
						validFrom: 1646136529770,
						validTill: 1646568529770,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 954279214,
						sellerTitle: 'Beko',
						sellerSubtitle: 'Local\tST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 112,
						type: 'seller_coupon',
						category: 'Электроника',
						amount: 18381,
						threshold: 181998,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 202199972,
						sellerTitle: 'ANYCUBIC',
						sellerSubtitle: 'OWH\tST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 76,
						type: 'seller_coupon',
						category: 'Электроника',
						amount: 95618,
						threshold: 946713,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 1051798195,
						sellerTitle: 'Crockid',
						sellerSubtitle: 'Local\tNST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 206,
						type: 'seller_coupon',
						category: 'Электроника',
						amount: 91180,
						threshold: 902778,
						validFrom: 1646136529770,
						validTill: 1646568529770,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 1002987299,
						sellerTitle: 'HP',
						sellerSubtitle: 'HP Store',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 182,
						type: 'seller_coupon',
						category: 'Электроника',
						amount: 87792,
						threshold: 869235,
						validFrom: 1646136529770,
						validTill: 1646568529770,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 965720389,
						sellerTitle: 'PUMA',
						sellerSubtitle: 'PUMA Store',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 148,
						type: 'seller_coupon',
						category: 'Электроника',
						amount: 21706,
						threshold: 214912,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 238767664,
						sellerTitle: 'SRAN Store',
						sellerSubtitle: 'CB\tST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 100,
						type: 'seller_coupon',
						category: 'Электроника',
						amount: 18394,
						threshold: 182124,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 202339875,
						sellerTitle: 'liitokala',
						sellerSubtitle: 'OWH\tST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 124,
						type: 'seller_coupon',
						category: 'Электроника',
						amount: 20281,
						threshold: 200803,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 223092868,
						sellerTitle: 'MoesHouse',
						sellerSubtitle: 'OWH\tST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
				],
			},
			{
				categoryName: 'Компьютеры',
				prizes: [
					{
						id: 118,
						type: 'seller_coupon',
						category: 'Компьютеры',
						amount: 21642,
						threshold: 214277,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 238062376,
						sellerTitle: 'ABIR',
						sellerSubtitle: 'OWH\tST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 106,
						type: 'seller_coupon',
						category: 'Компьютеры',
						amount: 21537,
						threshold: 213244,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 236914696,
						sellerTitle: 'Anlan',
						sellerSubtitle: 'OWH\tNST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 142,
						type: 'seller_coupon',
						category: 'Компьютеры',
						amount: 21333,
						threshold: 211218,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 234663248,
						sellerTitle: 'Trendyol',
						sellerSubtitle: 'CB\tNST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 176,
						type: 'seller_coupon',
						category: 'Компьютеры',
						amount: 89359,
						threshold: 884750,
						validFrom: 1646136529770,
						validTill: 1646568529770,
						title: 'Все товары',
						subtitle: 'Смартфон Samsung Galaxy A12 / A32',
						sellerId: 982957467,
						sellerTitle: 'Samsung',
						sellerSubtitle: 'Samsung Store',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 224,
						type: 'seller_coupon',
						category: 'Компьютеры',
						amount: 88160,
						threshold: 872878,
						validFrom: 1646136529770,
						validTill: 1646568529770,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 969768266,
						sellerTitle: 'Babycare',
						sellerSubtitle: 'Local\tNST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 82,
						type: 'seller_coupon',
						category: 'Компьютеры',
						amount: 96056,
						threshold: 951058,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 1056625787,
						sellerTitle: 'Western Digital',
						sellerSubtitle: 'Local\tST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 130,
						type: 'seller_coupon',
						category: 'Компьютеры',
						amount: 21987,
						threshold: 217696,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 241860361,
						sellerTitle: 'All Of Me',
						sellerSubtitle: 'CB\tNST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 200,
						type: 'seller_coupon',
						category: 'Компьютеры',
						amount: 87685,
						threshold: 868174,
						validFrom: 1646136529770,
						validTill: 1646568529770,
						title: 'Все товары',
						subtitle: 'Холодильник GA-B509CQWL',
						sellerId: 964541386,
						sellerTitle: 'LG',
						sellerSubtitle: 'LG Store',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 188,
						type: 'seller_coupon',
						category: 'Компьютеры',
						amount: 87812,
						threshold: 869430,
						validFrom: 1646136529770,
						validTill: 1646568529770,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 965937103,
						sellerTitle: 'SOKOLOV',
						sellerSubtitle: 'SOKOLOV Store',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 212,
						type: 'seller_coupon',
						category: 'Компьютеры',
						amount: 90236,
						threshold: 893431,
						validFrom: 1646136529770,
						validTill: 1646568529770,
						title: 'Все товары',
						subtitle: 'Умная колонка Яндекс Макс (красный)',
						sellerId: 992602613,
						sellerTitle: 'ТЕХНОПАРК',
						sellerSubtitle: 'ТЕХНОПАРК Store',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
				],
			},
			{
				categoryName: 'Спорт и отдых',
				prizes: [
					{
						id: 220,
						type: 'seller_coupon',
						category: 'Спорт и отдых',
						amount: 88352,
						threshold: 874777,
						validFrom: 1646136529770,
						validTill: 1646568529770,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 971877411,
						sellerTitle: 'REDMOND',
						sellerSubtitle: 'REDMOND Store',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 126,
						type: 'seller_coupon',
						category: 'Спорт и отдых',
						amount: 22201,
						threshold: 219812,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 244211404,
						sellerTitle: 'TINECO',
						sellerSubtitle: 'OWH\tST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 232,
						type: 'seller_coupon',
						category: 'Спорт и отдых',
						amount: 88992,
						threshold: 881112,
						validFrom: 1646136529770,
						validTill: 1646568529770,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 978916251,
						sellerTitle: 'Huadie',
						sellerSubtitle: 'Local\tNST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 138,
						type: 'seller_coupon',
						category: 'Спорт и отдых',
						amount: 21741,
						threshold: 215259,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 239153001,
						sellerTitle: 'KONKA Appliances',
						sellerSubtitle: 'OWH\tST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 102,
						type: 'seller_coupon',
						category: 'Спорт и отдых',
						amount: 18228,
						threshold: 180475,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 200508591,
						sellerTitle: 'Reolink',
						sellerSubtitle: 'OWH\tST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 114,
						type: 'seller_coupon',
						category: 'Спорт и отдых',
						amount: 18212,
						threshold: 180325,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 200341888,
						sellerTitle: 'UGREEN',
						sellerSubtitle: 'OWH\tST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 184,
						type: 'seller_coupon',
						category: 'Спорт и отдых',
						amount: 87182,
						threshold: 863195,
						validFrom: 1646136529770,
						validTill: 1646568529770,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 959009814,
						sellerTitle: 'JBL',
						sellerSubtitle: 'JBL Store',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 168,
						type: 'seller_coupon',
						category: 'Спорт и отдых',
						amount: 85894,
						threshold: 850436,
						validFrom: 1646127950496,
						validTill: 1646559950496,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 944834602,
						sellerTitle: 'Crocs',
						sellerSubtitle: 'Crocs Official Store',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 208,
						type: 'seller_coupon',
						category: 'Спорт и отдых',
						amount: 90236,
						threshold: 893431,
						validFrom: 1646136529770,
						validTill: 1646568529770,
						title: 'Все товары',
						subtitle: 'Холодильник LG GA-B 509',
						sellerId: 992602613,
						sellerTitle: 'ТЕХНОПАРК',
						sellerSubtitle: 'ТЕХНОПАРК Store',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 196,
						type: 'seller_coupon',
						category: 'Спорт и отдых',
						amount: 21317,
						threshold: 211062,
						validFrom: 1646136529770,
						validTill: 1646568529770,
						title: 'Все товары',
						subtitle: 'Ноутбук HP Pavilion Gaming',
						sellerId: 234490267,
						sellerTitle: 'Dreame',
						sellerSubtitle: 'Dreame Store',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 316,
						type: 'seller_coupon',
						category: 'Спорт и отдых',
						amount: 87251,
						threshold: 863873,
						validFrom: 1646136529770,
						validTill: 1646568529770,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 959763778,
						sellerTitle: 'Smartlife',
						sellerSubtitle: 'Smartlife Store',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
				],
			},
			{
				categoryName: 'Обувь',
				prizes: [
					{
						id: 71,
						type: 'seller_coupon',
						category: 'Обувь',
						amount: 87459,
						threshold: 865931,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 962049983,
						sellerTitle: 'Happy Baby',
						sellerSubtitle: 'Local\tNST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 201,
						type: 'seller_coupon',
						category: 'Обувь',
						amount: 87685,
						threshold: 868174,
						validFrom: 1646136529770,
						validTill: 1646568529770,
						title: 'Все товары',
						subtitle: 'Узкая стиральная машина F2M5HS7S',
						sellerId: 964541386,
						sellerTitle: 'LG',
						sellerSubtitle: 'LG Store',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 143,
						type: 'seller_coupon',
						category: 'Обувь',
						amount: 21186,
						threshold: 209768,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 233052559,
						sellerTitle: 'BOXYM',
						sellerSubtitle: 'OWH\tNST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 119,
						type: 'seller_coupon',
						category: 'Обувь',
						amount: 21365,
						threshold: 211537,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 235017749,
						sellerTitle: 'pracmanu',
						sellerSubtitle: 'OWH\tST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 249,
						type: 'seller_coupon',
						category: 'Обувь',
						amount: 88472,
						threshold: 875968,
						validFrom: 1646136529770,
						validTill: 1646568529770,
						title: 'Все товары',
						subtitle: 'Стиральная машина Electrolux',
						sellerId: 973201467,
						sellerTitle: 'Techport',
						sellerSubtitle: 'Local\tNST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
				],
			},
			{
				categoryName: 'Смартфоны',
				prizes: [
					{
						id: 123,
						type: 'seller_coupon',
						category: 'Смартфоны',
						amount: 21576,
						threshold: 213623,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 237336121,
						sellerTitle: 'OCLEAN',
						sellerSubtitle: 'OWH\tST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 87,
						type: 'seller_coupon',
						category: 'Смартфоны',
						amount: 86187,
						threshold: 853343,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 948065000,
						sellerTitle: 'Zigmund',
						sellerSubtitle: 'Local\tST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 75,
						type: 'seller_coupon',
						category: 'Смартфоны',
						amount: 87066,
						threshold: 862042,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 957729199,
						sellerTitle: 'Forward',
						sellerSubtitle: 'Local\tNST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 111,
						type: 'seller_coupon',
						category: 'Смартфоны',
						amount: 21873,
						threshold: 216572,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 240611733,
						sellerTitle: 'Booster boluojun',
						sellerSubtitle: 'OWH\tNST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 99,
						type: 'seller_coupon',
						category: 'Смартфоны',
						amount: 21306,
						threshold: 210956,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 234372507,
						sellerTitle: 'NEATSVOR',
						sellerSubtitle: 'OWH\tST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 181,
						type: 'seller_coupon',
						category: 'Смартфоны',
						amount: 87792,
						threshold: 869235,
						validFrom: 1646136529770,
						validTill: 1646568529770,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 965720389,
						sellerTitle: 'PUMA',
						sellerSubtitle: 'PUMA Store',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 205,
						type: 'seller_coupon',
						category: 'Смартфоны',
						amount: 87251,
						threshold: 863873,
						validFrom: 1646136529770,
						validTill: 1646568529770,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 959763778,
						sellerTitle: 'Smartlife',
						sellerSubtitle: 'Smartlife Store',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 193,
						type: 'seller_coupon',
						category: 'Смартфоны',
						amount: 88714,
						threshold: 878358,
						validFrom: 1646136529770,
						validTill: 1646568529770,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 975855925,
						sellerTitle: 'Microsoft/xbox',
						sellerSubtitle: 'Microsoft/xbox Store',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
				],
			},
			{
				categoryName: 'Украшения',
				prizes: [
					{
						id: 133,
						type: 'seller_coupon',
						category: 'Украшения',
						amount: 20432,
						threshold: 202303,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 224759163,
						sellerTitle: 'Eilyken Store',
						sellerSubtitle: 'CB\tNST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 121,
						type: 'seller_coupon',
						category: 'Украшения',
						amount: 22562,
						threshold: 223392,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 248188942,
						sellerTitle: 'AData',
						sellerSubtitle: 'CB\tST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 251,
						type: 'seller_coupon',
						category: 'Украшения',
						amount: 88472,
						threshold: 875968,
						validFrom: 1646136529770,
						validTill: 1646568529770,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 973201467,
						sellerTitle: 'Techport',
						sellerSubtitle: 'Local\tNST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 85,
						type: 'seller_coupon',
						category: 'Украшения',
						amount: 91791,
						threshold: 908829,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 1009709020,
						sellerTitle: 'Vasylisa',
						sellerSubtitle: 'Local\tST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 203,
						type: 'seller_coupon',
						category: 'Украшения',
						amount: 87685,
						threshold: 868174,
						validFrom: 1646136529770,
						validTill: 1646568529770,
						title: 'Все товары',
						subtitle: 'Холодильник GA-B509CMQM',
						sellerId: 964541386,
						sellerTitle: 'LG',
						sellerSubtitle: 'LG Store',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 215,
						type: 'seller_coupon',
						category: 'Украшения',
						amount: 89758,
						threshold: 888694,
						validFrom: 1646136529770,
						validTill: 1646568529770,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 987339791,
						sellerTitle: 'Улыбка Радуги',
						sellerSubtitle: 'Улыбка Радуги Store',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 179,
						type: 'seller_coupon',
						category: 'Украшения',
						amount: 87792,
						threshold: 869235,
						validFrom: 1646136529770,
						validTill: 1646568529770,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 965720389,
						sellerTitle: 'PUMA',
						sellerSubtitle: 'PUMA Store',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
				],
			},
			{
				categoryName: 'Автотовары',
				prizes: [
					{
						id: 104,
						type: 'seller_coupon',
						category: 'Автотовары',
						amount: 20134,
						threshold: 199348,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 221476156,
						sellerTitle: 'ROCKBROS',
						sellerSubtitle: 'OWH\tST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 80,
						type: 'seller_coupon',
						category: 'Автотовары',
						amount: 95337,
						threshold: 943937,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 1048714281,
						sellerTitle: 'U.S. Polo Assn',
						sellerSubtitle: 'Local\tNST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 246,
						type: 'seller_coupon',
						category: 'Автотовары',
						amount: 88472,
						threshold: 875968,
						validFrom: 1646136529770,
						validTill: 1646568529770,
						title: 'Все товары',
						subtitle: 'Стиральная машина Electrolux',
						sellerId: 973201467,
						sellerTitle: 'Techport',
						sellerSubtitle: 'Local\tNST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 116,
						type: 'seller_coupon',
						category: 'Автотовары',
						amount: 20109,
						threshold: 199107,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 221208907,
						sellerTitle: 'GOOJODOQ',
						sellerSubtitle: 'CB\tNST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 210,
						type: 'seller_coupon',
						category: 'Автотовары',
						amount: 90236,
						threshold: 893431,
						validFrom: 1646136529770,
						validTill: 1646568529770,
						title: 'Все товары',
						subtitle: 'Стирально-сушильная машина LG F1296CDS3',
						sellerId: 992602613,
						sellerTitle: 'ТЕХНОПАРК',
						sellerSubtitle: 'ТЕХНОПАРК Store',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 140,
						type: 'seller_coupon',
						category: 'Автотовары',
						amount: 18406,
						threshold: 182238,
						validFrom: 1646060693874,
						validTill: 1646492693874,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 202466561,
						sellerTitle: 'Dancer"s elegant',
						sellerSubtitle: 'CB\tNST',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 174,
						type: 'seller_coupon',
						category: 'Автотовары',
						amount: 89359,
						threshold: 884750,
						validFrom: 1646136529770,
						validTill: 1646568529770,
						title: 'Все товары',
						subtitle: 'Телевизор Samsung 43" Q60A QLED',
						sellerId: 982957467,
						sellerTitle: 'Samsung',
						sellerSubtitle: 'Samsung Store',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
					{
						id: 198,
						type: 'seller_coupon',
						category: 'Автотовары',
						amount: 87685,
						threshold: 868174,
						validFrom: 1646136529770,
						validTill: 1646568529770,
						title: 'Все товары',
						subtitle: 'все товары',
						sellerId: 964541386,
						sellerTitle: 'LG',
						sellerSubtitle: 'LG Store',
						imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
						link: 'https://aliexpress.ru',
					},
				],
			},
		],
	},
};

export const takePrizeData = {
	data: {
		status: 'success',
	},
};

export const getUserPrizesData = {
	data: {
		loyaltyPoints: null,
		superCoupons: [
			{
				id: 152,
				type: 'superbox',
				category: 'Автотовары',
				amount: 20735,
				threshold: 205298,
				validFrom: 1646060693874,
				validTill: 1646492693874,
				title: 'Все товары',
				subtitle: 'все товары',
				sellerId: 228086465,
				sellerTitle: 'YOYA TOP',
				sellerSubtitle: 'OWH\tNST',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'booked',
			},
			{
				id: 156,
				type: 'superbox',
				category: 'Одежда',
				amount: 82808,
				threshold: 819885,
				validFrom: 1646060693874,
				validTill: 1646492693874,
				title: 'Все товары',
				subtitle: 'все товары',
				sellerId: 910893005,
				sellerTitle: 'FRAP',
				sellerSubtitle: 'Local\tST',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'booked',
			},
			{
				id: 158,
				type: 'superbox',
				category: 'Для детей',
				amount: 82891,
				threshold: 820706,
				validFrom: 1646060693874,
				validTill: 1646492693874,
				title: 'Все товары',
				subtitle: 'все товары',
				sellerId: 911805169,
				sellerTitle: 'EMPEROR CAMP',
				sellerSubtitle: 'Local\tST',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'booked',
			},
			{
				id: 158,
				type: 'superbox',
				category: 'Для детей',
				amount: 82891,
				threshold: 820706,
				validFrom: 1646060693874,
				validTill: 1646492693874,
				title: 'Все товары',
				subtitle: 'все товары',
				sellerId: 911805169,
				sellerTitle: 'EMPEROR CAMP',
				sellerSubtitle: 'Local\tST',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'booked',
			},
			{
				id: 151,
				type: 'superbox',
				category: 'Красота и здоровье',
				amount: 20426,
				threshold: 202239,
				validFrom: 1646060693874,
				validTill: 1646492693874,
				title: 'Все товары',
				subtitle: 'все товары',
				sellerId: 224687538,
				sellerTitle: 'TIGER FORCE',
				sellerSubtitle: 'OWH\tNST',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'booked',
			},
			{
				id: 163,
				type: 'superbox',
				category: 'Красота и здоровье',
				amount: 86892,
				threshold: 860318,
				validFrom: 1646060693874,
				validTill: 1646492693874,
				title: 'Все товары',
				subtitle: 'все товары',
				sellerId: 955814039,
				sellerTitle: 'Polaris',
				sellerSubtitle: 'Local\tST',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'booked',
			},
			{
				id: 159,
				type: 'superbox',
				category: 'Смартфоны',
				amount: 82860,
				threshold: 820399,
				validFrom: 1646060693874,
				validTill: 1646492693874,
				title: 'Все товары',
				subtitle: 'все товары',
				sellerId: 911464037,
				sellerTitle: 'vivo',
				sellerSubtitle: 'Local\tST',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'booked',
			},
			{
				id: 151,
				type: 'superbox',
				category: 'Красота и здоровье',
				amount: 20426,
				threshold: 202239,
				validFrom: 1646060693874,
				validTill: 1646492693874,
				title: 'Все товары',
				subtitle: 'все товары',
				sellerId: 224687538,
				sellerTitle: 'TIGER FORCE',
				sellerSubtitle: 'OWH\tNST',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'booked',
			},
			{
				id: 163,
				type: 'superbox',
				category: 'Красота и здоровье',
				amount: 86892,
				threshold: 860318,
				validFrom: 1646060693874,
				validTill: 1646492693874,
				title: 'Все товары',
				subtitle: 'все товары',
				sellerId: 955814039,
				sellerTitle: 'Polaris',
				sellerSubtitle: 'Local\tST',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'booked',
			},
			{
				id: 160,
				type: 'superbox',
				category: 'Электроника',
				amount: 82915,
				threshold: 820949,
				validFrom: 1646060693874,
				validTill: 1646492693874,
				title: 'Все товары',
				subtitle: 'все товары',
				sellerId: 912074797,
				sellerTitle: 'infinix',
				sellerSubtitle: 'Local\tST',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'booked',
			},
			{
				id: 152,
				type: 'superbox',
				category: 'Автотовары',
				amount: 20735,
				threshold: 205298,
				validFrom: 1646060693874,
				validTill: 1646492693874,
				title: 'Все товары',
				subtitle: 'все товары',
				sellerId: 228086465,
				sellerTitle: 'YOYA TOP',
				sellerSubtitle: 'OWH\tNST',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'booked',
			},
			{
				id: 151,
				type: 'superbox',
				category: 'Красота и здоровье',
				amount: 20426,
				threshold: 202239,
				validFrom: 1646060693874,
				validTill: 1646492693874,
				title: 'Все товары',
				subtitle: 'все товары',
				sellerId: 224687538,
				sellerTitle: 'TIGER FORCE',
				sellerSubtitle: 'OWH\tNST',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'booked',
			},
		],
		coupons: [
			{
				id: 315,
				type: 'seller_coupon',
				category: 'Бытовая техника',
				amount: 90194,
				threshold: 893017,
				validFrom: 1646136529770,
				validTill: 1646568529770,
				title: 'Все товары',
				subtitle: 'все товары',
				sellerId: 992142266,
				sellerTitle: 'realme',
				sellerSubtitle: 'realme Store',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'assigned',
			},
			{
				id: 167,
				type: 'seller_coupon',
				category: 'Бытовая техника',
				amount: 85894,
				threshold: 850436,
				validFrom: 1646127950496,
				validTill: 1646559950496,
				title: 'Все товары',
				subtitle: 'все товары',
				sellerId: 944834602,
				sellerTitle: 'Crocs',
				sellerSubtitle: 'Crocs Official Store',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'assigned',
			},
			{
				id: 166,
				type: 'seller_coupon',
				category: 'Бытовая техника',
				amount: 83916,
				threshold: 830855,
				validFrom: 1646127950496,
				validTill: 1646559950496,
				title: 'Все товары',
				subtitle: 'все товары',
				sellerId: 923080806,
				sellerTitle: 'Lego',
				sellerSubtitle: 'Lego Store',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'assigned',
			},
			{
				id: 169,
				type: 'seller_coupon',
				category: 'Красота и здоровье',
				amount: 85894,
				threshold: 850436,
				validFrom: 1646127950496,
				validTill: 1646559950496,
				title: 'Все товары',
				subtitle: 'все товары',
				sellerId: 944834602,
				sellerTitle: 'Crocs',
				sellerSubtitle: 'Crocs Official Store',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'assigned',
			},
			{
				id: 164,
				type: 'seller_coupon',
				category: 'Обувь',
				amount: 20887,
				threshold: 206810,
				validFrom: 1646127950496,
				validTill: 1646559950496,
				title: 'Все товары',
				subtitle: 'все товары',
				sellerId: 229766518,
				sellerTitle: 'Mi',
				sellerSubtitle: 'Mi Store',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'assigned',
			},
			{
				id: 1,
				type: 'seller_coupon',
				category: 'Category 1',
				amount: 100,
				threshold: 1000,
				validFrom: 1645190331358,
				validTill: 1645622331358,
				title: 'Все товары',
				subtitle: 'AliExpress item subtitle',
				sellerId: 4423813829,
				sellerTitle: 'AliExpress',
				sellerSubtitle: 'AliExpress Russia',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'assigned',
			},
			{
				id: 175,
				type: 'seller_coupon',
				category: 'Дом и сад, инструкменты',
				amount: 89359,
				threshold: 884750,
				validFrom: 1646136529770,
				validTill: 1646568529770,
				title: 'Все товары',
				subtitle: 'Стиральная машина Samsung Aegis',
				sellerId: 982957467,
				sellerTitle: 'Samsung',
				sellerSubtitle: 'Samsung Store',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'assigned',
			},
			{
				id: 221,
				type: 'seller_coupon',
				category: 'Красота и здоровье',
				amount: 85634,
				threshold: 847865,
				validFrom: 1646136529770,
				validTill: 1646568529770,
				title: 'Все товары',
				subtitle: 'все товары',
				sellerId: 941978513,
				sellerTitle: 'Tmall. Все для дома',
				sellerSubtitle: 'Tmall. Все для дома Store',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'assigned',
			},
			{
				id: 222,
				type: 'seller_coupon',
				category: 'Автотовары',
				amount: 20647,
				threshold: 204434,
				validFrom: 1646136529770,
				validTill: 1646568529770,
				title: 'Все товары',
				subtitle: 'все товары',
				sellerId: 227126403,
				sellerTitle: 'TEYES',
				sellerSubtitle: 'TEYES Store',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'assigned',
			},
			{
				id: 170,
				type: 'seller_coupon',
				category: 'Электроника',
				amount: 20887,
				threshold: 206810,
				validFrom: 1646136529770,
				validTill: 1646568529770,
				title: 'Все товары',
				subtitle: 'все товары',
				sellerId: 229766518,
				sellerTitle: 'Mi',
				sellerSubtitle: 'Mi Store',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'assigned',
			},
			{
				id: 164,
				type: 'seller_coupon',
				category: 'Обувь',
				amount: 20887,
				threshold: 206810,
				validFrom: 1646127950496,
				validTill: 1646559950496,
				title: 'Все товары',
				subtitle: 'все товары',
				sellerId: 229766518,
				sellerTitle: 'Mi',
				sellerSubtitle: 'Mi Store',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'assigned',
			},
			{
				id: 177,
				type: 'seller_coupon',
				category: 'Обувь',
				amount: 89359,
				threshold: 884750,
				validFrom: 1646136529770,
				validTill: 1646568529770,
				title: 'Все товары',
				subtitle: 'все товары',
				sellerId: 982957467,
				sellerTitle: 'Samsung',
				sellerSubtitle: 'Samsung Store',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'assigned',
			},
			{
				id: 248,
				type: 'seller_coupon',
				category: 'Компьютеры',
				amount: 88472,
				threshold: 875968,
				validFrom: 1646136529770,
				validTill: 1646568529770,
				title: 'Все товары',
				subtitle: 'Стиральная машина Electrolux',
				sellerId: 973201467,
				sellerTitle: 'Techport',
				sellerSubtitle: 'Local\tNST',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'assigned',
			},
			{
				id: 250,
				type: 'seller_coupon',
				category: 'Одежда',
				amount: 88472,
				threshold: 875968,
				validFrom: 1646136529770,
				validTill: 1646568529770,
				title: 'Все товары',
				subtitle: 'все товары',
				sellerId: 973201467,
				sellerTitle: 'Techport',
				sellerSubtitle: 'Local\tNST',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'assigned',
			},
			{
				id: 244,
				type: 'seller_coupon',
				category: 'Спорт и отдых',
				amount: 86752,
				threshold: 858937,
				validFrom: 1646136529770,
				validTill: 1646568529770,
				title: 'Все товары',
				subtitle: 'все товары',
				sellerId: 954279214,
				sellerTitle: 'Beko',
				sellerSubtitle: 'Local\tST',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'assigned',
			},
			{
				id: 245,
				type: 'seller_coupon',
				category: 'Красота и здоровье',
				amount: 86752,
				threshold: 858937,
				validFrom: 1646136529770,
				validTill: 1646568529770,
				title: 'Все товары',
				subtitle: 'все товары',
				sellerId: 954279214,
				sellerTitle: 'Beko',
				sellerSubtitle: 'Local\tST',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'assigned',
			},
			{
				id: 83,
				type: 'seller_coupon',
				category: 'Обувь',
				amount: 96117,
				threshold: 951660,
				validFrom: 1646060693874,
				validTill: 1646492693874,
				title: 'Все товары',
				subtitle: 'все товары',
				sellerId: 1057294877,
				sellerTitle: 'SanDisk',
				sellerSubtitle: 'Local\tST',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'assigned',
			},
			{
				id: 107,
				type: 'seller_coupon',
				category: 'Обувь',
				amount: 20838,
				threshold: 206318,
				validFrom: 1646060693874,
				validTill: 1646492693874,
				title: 'Все товары',
				subtitle: 'все товары',
				sellerId: 229219542,
				sellerTitle: 'BeauToday',
				sellerSubtitle: 'CB\tNST',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'assigned',
			},
			{
				id: 115,
				type: 'seller_coupon',
				category: 'Красота и здоровье',
				amount: 21196,
				threshold: 209866,
				validFrom: 1646060693874,
				validTill: 1646492693874,
				title: 'Все товары',
				subtitle: 'все товары',
				sellerId: 233161845,
				sellerTitle: 'Huepar',
				sellerSubtitle: 'OWH\tST',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'assigned',
			},
			{
				id: 131,
				type: 'seller_coupon',
				category: 'Обувь',
				amount: 10657,
				threshold: 105521,
				validFrom: 1646060693874,
				validTill: 1646492693874,
				title: 'Все товары',
				subtitle: 'все товары',
				sellerId: 117233990,
				sellerTitle: 'taoffen',
				sellerSubtitle: 'CB\tNST',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'assigned',
			},
			{
				id: 109,
				type: 'seller_coupon',
				category: 'Украшения',
				amount: 20075,
				threshold: 198767,
				validFrom: 1646060693874,
				validTill: 1646492693874,
				title: 'Все товары',
				subtitle: 'все товары',
				sellerId: 220830995,
				sellerTitle: 'Junsun',
				sellerSubtitle: 'OWH\tST',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'assigned',
			},
			{
				id: 73,
				type: 'seller_coupon',
				category: 'Украшения',
				amount: 85420,
				threshold: 845746,
				validFrom: 1646060693874,
				validTill: 1646492693874,
				title: 'Все товары',
				subtitle: 'все товары',
				sellerId: 939624021,
				sellerTitle: 'Alltime',
				sellerSubtitle: 'Local\tNST',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'assigned',
			},
			{
				id: 127,
				type: 'seller_coupon',
				category: 'Красота и здоровье',
				amount: 20511,
				threshold: 203080,
				validFrom: 1646060693874,
				validTill: 1646492693874,
				title: 'Все товары',
				subtitle: 'все товары',
				sellerId: 225622462,
				sellerTitle: 'huacan office',
				sellerSubtitle: 'CB\tST',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'assigned',
			},
			{
				id: 183,
				type: 'seller_coupon',
				category: 'Бытовая техника',
				amount: 87477,
				threshold: 866113,
				validFrom: 1646136529770,
				validTill: 1646568529770,
				title: 'Все товары',
				subtitle: 'все товары',
				sellerId: 962251956,
				sellerTitle: 'MOLNIA',
				sellerSubtitle: 'MOLNIA Store',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'assigned',
			},
			{
				id: 7,
				type: 'seller_coupon',
				category: 'Category 1',
				amount: 480,
				threshold: 1900,
				validFrom: 1645574737727,
				validTill: 1646006737727,
				title: 'Все товары',
				subtitle: 'Скидка {{.Amount}} ₽ на весь ассортимент магазина при покупке от {{.Threshold}} ₽.',
				sellerId: 4423813829,
				sellerTitle: 'AliExpress',
				sellerSubtitle: 'AliExpress Russia',
				imageUrl: 'https://ae01.alicdn.com/H0ff3535516c141aaacec13b17cfbe1aaK.jpg',
				link: 'https://aliexpress.ru/store/910342259',
				status: 'assigned',
			},
			{
				id: 186,
				type: 'seller_coupon',
				category: 'Автотовары',
				amount: 86594,
				threshold: 857370,
				validFrom: 1646136529770,
				validTill: 1646568529770,
				title: 'Все товары',
				subtitle: 'все товары',
				sellerId: 952538221,
				sellerTitle: 'Asus',
				sellerSubtitle: 'Asus Store',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'assigned',
			},
			{
				id: 189,
				type: 'seller_coupon',
				category: 'Обувь',
				amount: 87812,
				threshold: 869430,
				validFrom: 1646136529770,
				validTill: 1646568529770,
				title: 'Все товары',
				subtitle: 'все товары',
				sellerId: 965937103,
				sellerTitle: 'SOKOLOV',
				sellerSubtitle: 'SOKOLOV Store',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'assigned',
			},
			{
				id: 190,
				type: 'seller_coupon',
				category: 'Одежда',
				amount: 21180,
				threshold: 209704,
				validFrom: 1646136529770,
				validTill: 1646568529770,
				title: 'Все товары',
				subtitle: 'все товары',
				sellerId: 232981631,
				sellerTitle: '70mai',
				sellerSubtitle: '70mai Store',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'assigned',
			},
			{
				id: 192,
				type: 'seller_coupon',
				category: 'Для детей',
				amount: 88468,
				threshold: 875922,
				validFrom: 1646136529770,
				validTill: 1646568529770,
				title: 'Все товары',
				subtitle: 'все товары',
				sellerId: 973149782,
				sellerTitle: 'Salomon',
				sellerSubtitle: 'Salomon Store',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'assigned',
			},
			{
				id: 128,
				type: 'seller_coupon',
				category: 'Автотовары',
				amount: 21313,
				threshold: 211024,
				validFrom: 1646060693874,
				validTill: 1646492693874,
				title: 'Все товары',
				subtitle: 'все товары',
				sellerId: 234447977,
				sellerTitle: 'NIBBER',
				sellerSubtitle: 'CB\tNST',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'assigned',
			},
			{
				id: 135,
				type: 'seller_coupon',
				category: 'Смартфоны',
				amount: 21322,
				threshold: 211110,
				validFrom: 1646060693874,
				validTill: 1646492693874,
				title: 'Все товары',
				subtitle: 'все товары',
				sellerId: 234543971,
				sellerTitle: 'finetoo',
				sellerSubtitle: 'CB\tNST',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'assigned',
			},
			{
				id: 145,
				type: 'seller_coupon',
				category: 'Украшения',
				amount: 10676,
				threshold: 105712,
				validFrom: 1646060693874,
				validTill: 1646492693874,
				title: 'Все товары',
				subtitle: 'все товары',
				sellerId: 117446702,
				sellerTitle: 'ZAKOL',
				sellerSubtitle: 'CB\tNST',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'assigned',
			},
			{
				id: 147,
				type: 'seller_coupon',
				category: 'Смартфоны',
				amount: 20963,
				threshold: 207555,
				validFrom: 1646060693874,
				validTill: 1646492693874,
				title: 'Все товары',
				subtitle: 'все товары',
				sellerId: 230593619,
				sellerTitle: 'Superbaby Stroller',
				sellerSubtitle: 'OWH\tNST',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'assigned',
			},
			{
				id: 150,
				type: 'seller_coupon',
				category: 'Спорт и отдых',
				amount: 20973,
				threshold: 207653,
				validFrom: 1646060693874,
				validTill: 1646492693874,
				title: 'Все товары',
				subtitle: 'все товары',
				sellerId: 230703192,
				sellerTitle: 'GASMAN',
				sellerSubtitle: 'OWH\tNST',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'assigned',
			},
			{
				id: 309,
				type: 'seller_coupon',
				category: 'Обувь',
				amount: 90086,
				threshold: 891942,
				validFrom: 1646136529770,
				validTill: 1646568529770,
				title: 'Все товары',
				subtitle: 'все товары',
				sellerId: 990948155,
				sellerTitle: 'Midea',
				sellerSubtitle: 'Local\tST',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'assigned',
			},
			{
				id: 136,
				type: 'seller_coupon',
				category: 'Электроника',
				amount: 20325,
				threshold: 201240,
				validFrom: 1646060693874,
				validTill: 1646492693874,
				title: 'Все товары',
				subtitle: 'все товары',
				sellerId: 223578328,
				sellerTitle: 'MIEGOFCE',
				sellerSubtitle: 'OWH\tNST',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'assigned',
			},
			{
				id: 191,
				type: 'seller_coupon',
				category: 'Украшения',
				amount: 88468,
				threshold: 875922,
				validFrom: 1646136529770,
				validTill: 1646568529770,
				title: 'Все товары',
				subtitle: 'все товары',
				sellerId: 973149782,
				sellerTitle: 'Salomon',
				sellerSubtitle: 'Salomon Store',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'assigned',
			},
			{
				id: 217,
				type: 'seller_coupon',
				category: 'Смартфоны',
				amount: 95821,
				threshold: 948726,
				validFrom: 1646136529770,
				validTill: 1646568529770,
				title: 'Все товары',
				subtitle: 'все товары',
				sellerId: 1054034785,
				sellerTitle: 'Подружка',
				sellerSubtitle: 'Подружка Store',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'assigned',
			},
			{
				id: 218,
				type: 'seller_coupon',
				category: 'Электроника',
				amount: 95821,
				threshold: 948726,
				validFrom: 1646136529770,
				validTill: 1646568529770,
				title: 'Все товары',
				subtitle: 'все товары',
				sellerId: 1054034785,
				sellerTitle: 'Подружка',
				sellerSubtitle: 'Подружка Store',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'assigned',
			},
			{
				id: 216,
				type: 'seller_coupon',
				category: 'Для детей',
				amount: 90424,
				threshold: 895293,
				validFrom: 1646136529770,
				validTill: 1646568529770,
				title: 'Все товары',
				subtitle: 'все товары',
				sellerId: 994671620,
				sellerTitle: 'Nintendo',
				sellerSubtitle: 'Nintendo Store',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'assigned',
			},
			{
				id: 219,
				type: 'seller_coupon',
				category: 'Бытовая техника',
				amount: 95821,
				threshold: 948726,
				validFrom: 1646136529770,
				validTill: 1646568529770,
				title: 'Все товары',
				subtitle: 'все товары',
				sellerId: 1054034785,
				sellerTitle: 'Подружка',
				sellerSubtitle: 'Подружка Store',
				imageUrl: 'https://st.aliexpress.ru/webp/images/icons/AliExpressIcon-min.png',
				link: 'https://aliexpress.ru',
				status: 'assigned',
			},
			{
				id: 960,
				type: 'seller_coupon',
				category: 'Для детей',
				amount: 100,
				threshold: 1000,
				validFrom: 1648720453827,
				validTill: 1648720453827,
				title: 'Все товары',
				subtitle: '',
				sellerId: 1049065802,
				sellerTitle: 'BUTTON BLUE',
				sellerSubtitle: '',
				imageUrl: '',
				link: 'https://buttonblue.aliexpress.ru/store/911885253',
				status: 'assigned',
			},
		],
	},
};

export const visitLinkData = {
	data: {
		result: 'success',
		recievedStepsCount: 0,
	},
};
