import gsap from 'gsap';
import { MonumentsDependencies, MonumentsInterface } from '.';
import { $, $$ } from '../../helpers';
import { AnalyticsInterface, EventName } from '../Analytics';
import { ApiInterface, IMonumentsResponse, Monument } from '../API';
import { GamedataInterface } from '../GameData';
import { MapInterface } from '../GameMap';
import { Modal, ModalsInterface } from '../Modal';
import { monumentsArr } from './testData';
export class Monuments implements MonumentsInterface {
	private static singleton: Monuments;
	private initialData: GamedataInterface['initialData'];
	private analytics: AnalyticsInterface;
	private api: ApiInterface;
	private map: MapInterface;
	private modal: ModalsInterface;
	private monuments: Monument[];
	private currentMonument: Monument;

	inject(deps: MonumentsDependencies) {
		this.initialData = deps.gameData.initialData;
		this.analytics = deps.analytics;
		this.api = deps.api;
		this.map = deps.map;
		this.modal = deps.modal;
	}

	public static getInstance(): Monuments {
		if (!Monuments.singleton) Monuments.singleton = new Monuments();

		return Monuments.singleton;
	}

	async setMonuments() {
		const it = this;

		// it.monuments = (await it.api.getMonuments()).data?.monuments;

		// it.monuments.forEach((monument) => it.setMonumentCell(monument));
		monumentsArr.forEach((monument) => it.setMonumentCell(monument));
		it.animateCells();
	}

	private setMonumentCell(monument: Monument) {
		const it = this;
		const cell = [...$$('.cell')][it.map.getCellNumberByCoordinates(monument.position.x, monument.position.y) - 1];
		cell.innerHTML = `<div class="cell__monument" data-monument="${monument.link.id}">
			<div class="_monument-wrap"><img class="_monument" src="${monument.link.monumentImageUrl}" data-monument="${monument.link.id}" /></div>
			<div class="_monument-shadow"></div>
			<div class="_monument-light"></div>
		</div>`;
	}

	private animateCells() {
		const tl = gsap.timeline({ repeat: -1 });
		tl.to('._monument', { y: -20, duration: 0.35, stagger: 0.1, ease: 'power2.out' }, 0);
		tl.to('._monument-light', { scale: 0.65, duration: 0.35, stagger: 0.1, ease: 'power2.out' }, 0);
		tl.to('._monument-shadow', { opacity: 0, duration: 0.35, stagger: 0.1, ease: 'power2.out' }, 0);
		tl.to('._monument', { y: 0, duration: 0.5, stagger: 0.1, ease: 'power2.in' }, 0.35);
		tl.to('._monument-light', { scale: 1, duration: 0.5, stagger: 0.1, ease: 'power2.in' }, 0.35);
		tl.to('._monument-shadow', { opacity: 1, duration: 0.5, stagger: 0.1, ease: 'power2.in' }, 0.35);
		tl.to('._monument', { delay: 0.95, stagger: 0.1 });
	}

	toggleMonumentModal(target: HTMLElement) {
		const it = this;

		const monumentId = target.dataset.monument;
		it.currentMonument = it.monuments.find((monument) => monument.link.id === +monumentId);

		($('.monument .popup__logo-img') as HTMLElement).style.backgroundImage = '';
		$('.monument .popup__heading').textContent = '';
		$('.monument .popup__text').textContent = '';
		($('.monument .monument__img._1') as HTMLElement).style.backgroundImage = '';
		($('.monument .monument__img._2') as HTMLElement).style.backgroundImage = '';

		(
			$('.monument .popup__logo-img') as HTMLElement
		).style.backgroundImage = `url('${it.currentMonument.link.imageUrl}')`;
		$('.monument .popup__heading').textContent = it.currentMonument.link.title;
		$('.monument .popup__text').textContent = it.currentMonument.link.description;
		if (it.currentMonument.link.contentImages[0]) {
			(
				$('.monument .monument__img._1') as HTMLElement
			).style.backgroundImage = `url('${it.currentMonument.link.contentImages[0]}')`;
		}
		if (it.currentMonument.link.contentImages[1]) {
			(
				$('.monument .monument__img._2') as HTMLElement
			).style.backgroundImage = `url('${it.currentMonument.link.contentImages[1]}')`;
		}
		($('.monument button') as HTMLElement).dataset.href = it.currentMonument.link.link;

		it.analytics.track(EventName.Page_Aliland_Map_Monument_Click);
		it.modal.show(Modal.MONUMENT);
		it.analytics.track(EventName.Page_Aliland_MonumentPopup_Exposure);
	}

	goToStore() {
		const it = this;

		it.analytics.track(EventName.Page_Aliland_MonumentPopup_GoToStore_Click, 'click', {
			ae_page_type: 'aliland_map',
			ae_page_area: 'monument_popup',
			ae_button_type: 'gotostore',
			ae_object_value: JSON.stringify({
				store_name: it.currentMonument.link.title,
				store_id: it.currentMonument.link.id,
			}),
			ae_click_behavior: 'out_of_game_flow',
			'spm-cnt': 'a2g2l.aliland.map.monument_popup_gotostore',
		});
		it.initialData.dataProvider.goLink({ link: it.currentMonument.link.link });
	}
}
