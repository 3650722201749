import gsap from 'gsap/all';
import { Illustration, Group, Anchor, Rect, TAU, Ellipse, Shape } from 'zdog';
import anime from '../../libs/anime';
import { $ } from '../../helpers';

import { DiceDependencies, DiceInterface } from '.';
import { AnalyticsInterface } from '../Analytics';
import { EventName } from '../Analytics';
import { UserInterface } from '../User';
import { MapInterface } from '../GameMap';
import { MovesInterface } from '../Moves';
import { UIInterface } from '../UI';
export class Dice implements DiceInterface {
	private static singleton: Dice;
	private analytics: AnalyticsInterface;
	private user: UserInterface;
	private map: MapInterface;
	private moves: MovesInterface;
	private ui: UIInterface;

	lastDrop: number = 0;

	private diceBtn: HTMLElement = $('.dice');
	private diceModal: HTMLElement = $('.dice-roll');
	private diceStartTitle: HTMLElement = $('.dice-roll h3');
	private diceFinalTitle: HTMLElement = $('.dice-roll h4');
	private diceShine: HTMLElement = $('.dice-roll img._shine');
	private diceShadow: HTMLElement = $('.dice-roll img._shadow');
	private diceResult: HTMLElement = $('.dice-roll__result');
	private makeMoveBtn: HTMLElement = $('.js-make-move');

	private canvas: HTMLCanvasElement = $('.dice-roll canvas');
	private illustration: Illustration;
	private dice: Anchor;

	constructor() {
		this.createDiceFigure();
	}

	inject(deps: DiceDependencies) {
		this.analytics = deps.analytics;
		this.user = deps.user;
		this.map = deps.map;
		this.moves = deps.moves;
		this.ui = deps.ui;
	}

	static getInstance(): Dice {
		if (!Dice.singleton) Dice.singleton = new Dice();

		return Dice.singleton;
	}

	handleTrow(diceNum: number) {
		const it = this;

		if (it.ui.animationRunning) return;

		it.diceBtn.classList.add('_hover');
		setTimeout(() => it.diceBtn.classList.remove('_hover'), 50);
		setTimeout(() => {
			it.user.status.availableStepsCount === 0 ? it.moves.toggleGetMoveModal() : it.dropTheDice(diceNum);
		}, 100);
	}

	private dropTheDice(diceNum: number) {
		const it = this;

		it.analytics.track(EventName.Page_Aliland_Map_RollTheDice_Click);

		it.diceBtn.classList.add('_hover');
		setTimeout(() => it.diceBtn.classList.remove('_hover'), 300);

		it.diceModal.classList.add('_active');

		it.rollTheDice(diceNum - 1);
	}

	private createDiceFigure() {
		const it = this;

		it.illustration = new Illustration({
			element: it.canvas,
		});

		// anchor point used for the rotation
		it.dice = new Anchor({ addTo: it.illustration });

		// group describing the faces through rounded rectangles
		const faces = new Group({ addTo: it.dice });

		// due to the considerable stroke, it is possible to fake the dice using four faces only
		const face = new Rect({
			addTo: faces,
			stroke: 50,
			width: 50,
			height: 50,
			color: '#FF2722',
			translate: {
				z: -25,
			},
		});

		// rotate the faces around the center
		face.copy({ rotate: { x: TAU / 4 }, translate: { y: 25 } });
		face.copy({ rotate: { x: TAU / 4 }, translate: { y: -25 } });
		face.copy({ translate: { z: 25 } });

		// include the dots repeating as many shapes/groups as possible
		// ! when copying an element be sure to reset the rotation/translation of the copied shape
		const one = new Shape({
			addTo: it.dice,
			color: '#fff',
			path: [
				{ x: 33.2582, y: -1.5722000000000023 },
				{ x: 28.9234, y: -2.005600000000001 },
				{ x: 24.805300000000003, y: -3.161100000000001 },
				{ x: 21.048499999999997, y: -4.9666999999999994 },
				{ x: 13.9683, y: -8.2167 },
				{ x: 8.260800000000003, y: -13.850000000000001 },
				{ x: 5.082000000000001, y: -20.927799999999998 },
				{ x: 3.2757999999999967, y: -24.683329999999998 },
				{ x: 2.0476000000000028, y: -28.8 },
				{ x: 1.686399999999999, y: -33.133331 },
				{ x: 0.6026999999999987, y: -33.133331 },
				{ x: -0.48100000000000165, y: -33.133331 },
				{ x: -1.564700000000002, y: -33.133331 },
				{ x: -1.998199999999997, y: -28.8 },
				{ x: -3.1541999999999994, y: -24.683329999999998 },
				{ x: -4.9603, y: -20.927799999999998 },
				{ x: -8.211400000000001, y: -13.850000000000001 },
				{ x: -13.846699999999998, y: -8.144400000000001 },
				{ x: -20.9268, y: -4.9666999999999994 },
				{ x: -24.683680000000003, y: -3.161100000000001 },
				{ x: -28.80173, y: -1.9333000000000027 },
				{ x: -33.136536, y: -1.5722000000000023 },
				{ x: -33.136536, y: -0.48886666666666656 },
				{ x: -33.136536, y: 0.594466666666662 },
				{ x: -33.136536, y: 1.6777999999999977 },
				{ x: -28.80173, y: 2.1111000000000004 },
				{ x: -24.683680000000003, y: 3.2667 },
				{ x: -20.9268, y: 5.072200000000002 },
				{ x: -13.846699999999998, y: 8.322200000000002 },
				{ x: -8.139199999999999, y: 13.955599999999997 },
				{ x: -4.9603, y: 21.033299999999997 },
				{ x: -3.1541999999999994, y: 24.788899999999998 },
				{ x: -1.926000000000002, y: 28.9056 },
				{ x: -1.564700000000002, y: 33.2389 },
				{ x: -0.48100000000000165, y: 33.2389 },
				{ x: 0.6026999999999987, y: 33.2389 },
				{ x: 1.686399999999999, y: 33.2389 },
				{ x: 2.119799999999998, y: 28.9056 },
				{ x: 3.2757999999999967, y: 24.788899999999998 },
				{ x: 5.082000000000001, y: 21.033299999999997 },
				{ x: 8.333100000000002, y: 13.955599999999997 },
				{ x: 13.9683, y: 8.25 },
				{ x: 21.048499999999997, y: 5.072200000000002 },
				{ x: 24.805300000000003, y: 3.2667 },
				{ x: 28.9234, y: 2.038899999999998 },
				{ x: 33.2582, y: 1.6777999999999977 },
				{ x: 33.2582, y: 0.594466666666662 },
				{ x: 33.2582, y: -0.48886666666666656 },
				{ x: 33.2582, y: -1.5722000000000023 },
			],
			fill: true,
			translate: {
				z: 50,
			},
			stroke: false,
			scale: {
				x: 0.25,
				y: 0.25,
			},
		});

		const two = new Group({
			addTo: it.dice,
			rotate: { x: TAU / 4 },
			translate: { y: 50 },
		});

		one.copy({
			addTo: two,
			translate: { y: 20 },
		});

		one.copy({
			addTo: two,
			translate: { y: -20 },
		});

		const three = new Group({
			addTo: it.dice,
			rotate: { y: TAU / 4 },
			translate: { x: 50 },
		});

		one.copy({
			addTo: three,
			translate: { z: 0 },
		});

		one.copy({
			addTo: three,
			translate: { x: 20, y: -20, z: 0 },
		});

		one.copy({
			addTo: three,
			translate: { x: -20, y: 20, z: 0 },
		});

		const four = new Group({
			addTo: it.dice,
			rotate: { y: TAU / 4 },
			translate: { x: -50 },
		});

		two.copyGraph({
			addTo: four,
			rotate: { x: 0 },
			translate: { x: 20, y: 0 },
		});

		two.copyGraph({
			addTo: four,
			rotate: { x: 0 },
			translate: { x: -20, y: 0 },
		});

		const five = new Group({
			addTo: it.dice,
			rotate: { x: TAU / 4 },
			translate: { y: -50 },
		});

		four.copyGraph({
			addTo: five,
			rotate: { y: 0 },
			translate: { x: 0 },
		});

		one.copy({
			addTo: five,
			translate: { z: 0 },
		});

		const six = new Group({
			addTo: it.dice,
			translate: { z: -50 },
		});

		two.copyGraph({
			addTo: six,
			rotate: { x: 0, z: TAU / 4 },
			translate: { x: 0, y: 0 },
		});

		four.copyGraph({
			addTo: six,
			rotate: { y: 0 },
			translate: { x: 0 },
		});

		// show the static illustration
		it.illustration.updateRenderGraph();
	}

	private rollTheDice(diceNum: number): number {
		const it = this;

		// object animated through anime.js to update the illustration
		const rotation = {
			x: 0,
			y: 0,
			z: 0,
		};

		// array describing the rotation necessary to highlight the difference faces
		const rotate = [{}, { x: TAU / 4 }, { y: TAU / 4 }, { y: (TAU * 3) / 4 }, { x: (TAU * 3) / 4 }, { x: TAU / 2 }];

		// utility function returning a positive integer up to a maximum value
		const randomInt = (max = 10) => Math.floor(Math.random() * max);

		// const side = randomInt(rotate.length);
		const side = diceNum;

		const { x = TAU, y = TAU } = rotate[side];
		// animate the object toward the input values
		anime({
			targets: rotation,
			// ! increment the input rotation with a random number of additional rotations
			x: x + TAU * randomInt(),
			y: y + TAU * randomInt(),
			z: TAU * randomInt(),
			duration: 3500,
			// while the object is being updated update the rotation of the dice
			// ! remember to update the graphic with the updateRenderGraph() method
			update() {
				it.dice.rotate.x = rotation.x;
				it.dice.rotate.y = rotation.y;
				it.dice.rotate.z = rotation.z;
				it.illustration.updateRenderGraph();
			},
			// as the animation completes, call the function to update the viewBox
			complete() {
				it.lastDrop = side + 1;

				it.showDiceModal();

				// it.handleMove();
			},
		});

		return side;
	}

	private getResultText(side: number) {
		let cells;
		switch (side) {
			case 1:
				cells = 'клетку';
				break;
			case 2:
			case 3:
			case 4:
				cells = 'клетки';
				break;
			case 5:
			case 6:
				cells = 'клеток';
				break;
		}

		return `Ваш ход — на ${side} ${cells} вперёд`;
	}

	private showDiceModal() {
		const it = this;

		gsap.timeline().to(it.diceStartTitle, { opacity: 0, duration: 0.1 }).to(it.diceFinalTitle, { opacity: 1 });
		// gsap.to(it.diceShadow, { opacity: 1 });
		gsap.to(it.diceShine, { opacity: 1 });
		it.diceResult.textContent = it.getResultText(it.lastDrop);
		gsap.to(it.diceResult, { opacity: 1 });
		gsap.timeline().to(it.makeMoveBtn, { visibility: 'visible' }).to(it.makeMoveBtn, { opacity: 1 });
	}

	// private async handleMove() {
	// 	const it = this;

	// 	if (it.user?.status?.currentCellId && it.lastDrop) {
	// 		const targetPosition = it.user.status.currentCellId + it.lastDrop;
	// 		if (targetPosition >= it.map.MAX_CELLS) {
	// 			await it.user.goToFinish();
	// 		} else {
	// 			await it.user.move(targetPosition);
	// 		}
	// 	}
	// }

	hideDiceModal() {
		const it = this;

		it.diceModal.classList.remove('_active');
		gsap.set(it.diceStartTitle, { opacity: 1 });
		gsap.set(it.diceFinalTitle, { opacity: 0 });
		// gsap.set(it.diceShadow, { opacity: 0 });
		gsap.set(it.diceShine, { opacity: 0 });
		gsap.set(it.diceResult, { opacity: 0 });
		gsap.set(it.makeMoveBtn, { opacity: 0, visibility: 'hidden' });
		it.diceResult.textContent = '';
	}
}
