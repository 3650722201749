import { ApiInterface, IUserPrizesResponse, IUserStatusInfo, Prize, PrizeType, Status } from '../API';
import { BoxesInterface } from '../Boxes';
import { CouponsInterface } from '../Coupons';
import { DiceInterface } from '../Dice';
import { EndgameInterface } from '../Endgame';
import { MovesInterface } from '../Moves';
import { OtherCouponsInterface } from '../OtherCoupons';
import { PointsInterface } from '../Points';
import { UIInterface } from '../UI';

export type UserDependencies = {
	api: ApiInterface;
	dice: DiceInterface;
	ui: UIInterface;
	coupons: CouponsInterface;
	otherCoupons: OtherCouponsInterface;
	boxes: BoxesInterface;
	points: PointsInterface;
	moves: MovesInterface;
	endgame: EndgameInterface;
};

export interface UserInterface {
	status: IUserStatusInfo;
	prizes: IUserPrizesResponse;
	currentPrize: Prize;
	currentAlternativeCoupon: Prize;
	assigningCouponTir: PrizeTir;
	isAlternativeCoupons: boolean;
	newCellId: number;
	currentState: Status;
	inject: (deps: UserDependencies) => void;
	init: () => Promise<void>;
	getStatus: () => Promise<void>;
	getPrizes: () => Promise<void>;
	takePrize: (prizeId: number) => Promise<boolean>;
	dismissPrize: () => Promise<void>;
	move: () => Promise<void>;
	clearCurrentPrize: () => void;
	handleCurrentPrize: () => void;
	// goToFinish: () => Promise<void>;
	// goToStart: () => Promise<void>;
}

export enum PrizeTir {
	TIR1,
	TIR2,
}
