import {
	ApiInterface,
	IAlternativePrizes,
	IChestPrizesResponse,
	IMapResponse,
	IMonumentsResponse,
	IMtopResponse,
	IStepResponse,
	ISuperprizeFromResponse,
	ITakePrizeResponse,
	ITasksResponse,
	ITutorialResponse,
	IUserPrizesResponse,
	IUserStatusInfo,
	IVisitLinkResponse,
	SendSuperprizeFormRequest,
	TakePrizeRequest,
	VisitLinkRequest,
} from '.';
import { API_NAMES, mock_url, mtopContext } from '../../initialGameData';
import { GamedataInterface } from '../GameData';
import { UIInterface } from '../UI';
import {
	getAlternativePrizesData,
	getChestPrizesData,
	getTasksData,
	getUserPrizesData,
	makeStepData,
	mapData,
	passTutorialData,
	statusData,
	takePrizeData,
	visitLinkData,
} from './mapData';

export class Api implements ApiInterface {
	private static singleton: Api;
	private initialData: GamedataInterface['initialData'];
	private apiCall: GamedataInterface['apiCall'];
	private ui: UIInterface;

	inject(gameData: GamedataInterface, ui: UIInterface) {
		this.initialData = gameData.initialData;
		this.apiCall = gameData.apiCall;
		this.ui = ui;
	}

	static getInstance(): Api {
		if (!Api.singleton) Api.singleton = new Api();

		return Api.singleton;
	}

	private async sendMockRequest<T>(endpoint: API_NAMES, data = {}): Promise<IMtopResponse<T>> {
		if (process.env.NODE_ENV === 'development') {
			const response = await fetch(mock_url + endpoint, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer c4c45a4cf43a136acb3dbd381de55749',
				},
				body: JSON.stringify({
					mtopContext: mtopContext,
					data: { ...data },
				}),
			});

			return (await response.json()) as IMtopResponse<T>;
		}

		const response = await this.apiCall({ api: endpoint, method: 'POST', body: data });

		return Promise.resolve(response) as IMtopResponse<T>;
	}

	async getStatus(): Promise<IMtopResponse<IUserStatusInfo>> {
		try {
			// const result = await this.sendMockRequest<IUserStatusInfo>(API_NAMES.getStatus);

			// if (!result || result.error) throw new Error(result.error.message);

			return Promise.resolve(statusData) as IUserStatusInfo;
		} catch (err) {
			this.ui.showError();
			console.error(err);
		}
	}

	async makeStep(): Promise<IMtopResponse<IStepResponse>> {
		try {
			// const result = await this.sendMockRequest<IStepResponse>(API_NAMES.makeStep);

			// if (!result || result.error) throw new Error(result.error.message);

			return Promise.resolve(makeStepData) as IStepResponse;
		} catch (err) {
			this.ui.showError();
			console.error(err);
		}
	}

	async getTasks(): Promise<IMtopResponse<ITasksResponse>> {
		try {
			// const result = await this.sendMockRequest<ITasksResponse>(API_NAMES.getTasks);

			// if (!result || result.error) throw new Error(result.error.message);

			return Promise.resolve(getTasksData) as ITasksResponse;
		} catch (err) {
			this.ui.showError();
			console.error(err);
		}
	}

	async getMap(): Promise<IMtopResponse<IMapResponse>> {
		try {
			// const result = await this.sendMockRequest<IMapResponse>(API_NAMES.getMap);

			// if (!result || result.error) throw new Error(result.error.message);

			return Promise.resolve(mapData) as IMapResponse;
		} catch (err) {
			this.ui.showError();
			console.error(err);
		}
	}

	async getMonuments(): Promise<IMtopResponse<IMonumentsResponse>> {
		try {
			const result = await this.sendMockRequest<IMonumentsResponse>(API_NAMES.getMonuments);

			if (!result || result.error) throw new Error(result.error.message);

			return result;
		} catch (err) {
			this.ui.showError();
			console.error(err);
		}
	}

	async getUserPrizes(): Promise<IMtopResponse<IUserPrizesResponse>> {
		try {
			// const result = await this.sendMockRequest<IUserPrizesResponse>(API_NAMES.getUserPrizes);

			// if (!result || result.error) throw new Error(result.error.message);

			return Promise.resolve(getUserPrizesData) as IUserPrizesResponse;
		} catch (err) {
			this.ui.showError();
			console.error(err);
		}
	}

	async getAlternativePrizes(): Promise<IMtopResponse<IAlternativePrizes>> {
		try {
			// const result = await this.sendMockRequest<IAlternativePrizes>(API_NAMES.getAlternativePrizes);

			// if (!result || result.error) throw new Error(result.error.message);

			// return result;
			return Promise.resolve(getAlternativePrizesData) as IAlternativePrizes;
		} catch (err) {
			this.ui.showError();
			console.error(err);
		}
	}

	async getChestPrizes(): Promise<IMtopResponse<IChestPrizesResponse>> {
		try {
			// const result = await this.sendMockRequest<IChestPrizesResponse>(API_NAMES.getChestPrizes);

			// if (!result || result.error) throw new Error(result.error.message);

			return Promise.resolve(getChestPrizesData) as IChestPrizesResponse;
		} catch (err) {
			this.ui.showError();
			console.error(err);
		}
	}

	async takePrize(takePrizeRequest: TakePrizeRequest): Promise<IMtopResponse<ITakePrizeResponse>> {
		try {
			// const result = await this.sendMockRequest<ITakePrizeResponse>(API_NAMES.takePrize, takePrizeRequest);

			// if (!result || result.error) throw new Error(result.error.message);

			return Promise.resolve(takePrizeData) as ITakePrizeResponse;
		} catch (err) {
			this.ui.showError();
			console.error(err);
		}
	}

	// async sendSuperprizeForm(
	// 	sendSuperprizeFormRequest: SendSuperprizeFormRequest
	// ): Promise<IMtopResponse<ISuperprizeFromResponse>> {
	// 	try {
	// 		const result = await this.sendMockRequest<ISuperprizeFromResponse>(
	// 			API_NAMES.sendSuperprizeForm,
	// 			sendSuperprizeFormRequest
	// 		);

	// 		if (!result || result.error) throw new Error(result.error.message);

	// 		return result;
	// 	} catch (err) {
	// 		this.ui.showError();
	// 		console.error(err);
	// 	}
	// }

	async passTutorial(): Promise<IMtopResponse<ITutorialResponse>> {
		try {
			// const result = await this.sendMockRequest<ITutorialResponse>(API_NAMES.passTutorial);

			// if (!result || result.error) throw new Error(result.error.message);

			return Promise.resolve(passTutorialData) as ITutorialResponse;
		} catch (err) {
			this.ui.showError();
			console.error(err);
		}
	}

	async visitLink(visitLinkRequest: VisitLinkRequest): Promise<IMtopResponse<IVisitLinkResponse>> {
		try {
			// const result = await this.sendMockRequest<IVisitLinkResponse>(API_NAMES.visitLink, visitLinkRequest);

			// if (!result || result.error) throw new Error(result.error.message);

			return Promise.resolve(visitLinkData) as IVisitLinkResponse;
		} catch (err) {
			this.ui.showError();
			console.error(err);
		}
	}
}
