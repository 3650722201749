import { $, $$, num2str } from '../../helpers';

import { MovesDependencies, MovesInterface } from '.';
import {
	ApiInterface,
	ITasksResponse,
	LinkType,
	Task,
	TaskLink,
	TaskLinkStatus,
	TaskStatus,
	TaskType,
	VisitLinkStatus,
} from '../API';
import { GamedataInterface } from '../GameData';
import { Modal, ModalsInterface } from '../Modal';
import { AnalyticsInterface, EventName } from '../Analytics';
import { UserInterface } from '../User';

export class Moves implements MovesInterface {
	private static singleton: Moves;
	private initialData: GamedataInterface['initialData'];
	private api: ApiInterface;
	private analytics: AnalyticsInterface;
	private modal: ModalsInterface;
	private user: UserInterface;
	orders: number = 0;
	maxOrders: number = 20;
	tasks: ITasksResponse;

	inject(deps: MovesDependencies) {
		this.initialData = deps.gameData.initialData;
		this.api = deps.api;
		this.analytics = deps.analytics;
		this.modal = deps.modal;
		this.user = deps.user;
	}

	public static getInstance(): Moves {
		if (!Moves.singleton) Moves.singleton = new Moves();

		return Moves.singleton;
	}

	async init(): Promise<void> {
		const it = this;

		await it.updateTasks();
		it.updateLinks(it.tasks.links.links);
		it.setMovesCounter(it.user.status.availableStepsCount);
	}

	async updateTasks() {
		const it = this;

		it.tasks = (await it.api.getTasks()).data;

		if (!it.tasks?.tasks) {
			$('.js-menu-moves').parentElement.remove();
			return;
		}

		it.tasks.tasks.forEach((task) => {
			switch (task.type) {
				case TaskType.purchase:
					it.setPurchaseBlock(task);
					break;
				case TaskType.daily_activity:
					it.setDailyActivityBlock(task);
					break;
				case TaskType.invite_friend:
					it.setInviteFriendBlock(task);
					break;
			}
		});
	}

	updateLinks(links: TaskLink[]) {
		const it = this;

		if (!links) {
			return;
		}

		$('.get-move__block._links').classList.add('_active');
		$('.get-move__block._links h4').textContent = it.tasks.links.title;
		$('.get-move__block._links p').textContent = it.tasks.links.subtitle;

		let linksHTML = '';
		links.forEach((link) => {
			linksHTML += `<div class="get-move__item">
				<p>${link.title}</p>
				<button class="js-get-move-link btn ${link.status === TaskLinkStatus.visited ? '_checked' : ''}" data-href="${
				link.link
			}" data-link="${link.id}">
					${link.status === TaskLinkStatus.visited ? '<span></span>' : 'Открыть'}
				</button>
			</div>`;
		});

		$('.get-move__list').innerHTML = linksHTML;
		const totalVisited = links.filter((link) => link.status === TaskLinkStatus.visited).length.toString();
		$('.get-move__orders').textContent = `Выполнено ${totalVisited} из ${links.length}`;
	}

	toggleGetMoveModal() {
		const it = this;

		if (it.modal.current === Modal.GET_MOVE) {
			it.modal.hide(Modal.GET_MOVE);
			return;
		}

		if (it.modal.current === Modal.MENU) {
			it.analytics.track(EventName.Page_Aliland_Menu_GetTheMove_Click);
			it.modal.hide(Modal.MENU);
			// return;
		}

		if (it.modal.current !== Modal.MENU && it.user.status.availableStepsCount === 0) {
			it.analytics.track(EventName.Page_Aliland_Map_GetTheMove_Click);
		}

		it.modal.show(Modal.GET_MOVE);
		it.analytics.track(EventName.Page_Aliland_TaskList_Screen);
	}

	setMovesCounter(moves: number) {
		if (moves <= 0) {
			$('.dice').classList.remove('_active');
			$('.dice__counter span').textContent = '0';
			$('.js-throw-dice.dice__btn span').textContent = 'Получить ход';
			return;
		}

		$('.dice').classList.add('_active');
		$('.dice__counter span').textContent = moves.toString();
		$('.js-throw-dice.dice__btn span').textContent = 'Бросить кубик';
	}

	toggleAddMoveModal(moves: number) {
		const it = this;

		if (it.modal.current === Modal.ADD_MOVE) {
			it.modal.hide(Modal.ADD_MOVE);
			$('.add-move__total').textContent = '';
			return;
		}

		it.modal.show(Modal.ADD_MOVE);
		$('.add-move__total').textContent = `${moves} ${num2str(moves, ['ход', 'хода', 'ходов'])}`;
	}

	async handleGetMoveLink(target: HTMLElement) {
		const it = this;

		const linkId = +target.dataset.link;
		const linkHref = target.dataset.href;
		it.initialData.dataProvider.goLink({ link: linkHref });

		if (!target.classList.contains('_checked')) {
			const { result, recievedStepsCount } = (await it.api.visitLink({ linkId, linkType: LinkType.task })).data;

			if (result !== VisitLinkStatus.success) return;

			it.analytics.track(EventName.Page_Aliland_TaskList_Task, 'click', {
				ae_page_type: 'aliland_tasklist',
				ae_button_type: `task_${linkId}`,
				ae_object_value: JSON.stringify({ action: linkHref }),
				ae_click_behavior: 'out_of_game_flow',
				'spm-cnt': `a2g2l.aliland.tasklist.task_${linkId}`,
			});

			it.tasks = (await it.api.getTasks()).data;
			it.updateLinks(it.tasks.links.links);

			if (recievedStepsCount && recievedStepsCount > 0) {
				it.modal.handleMenu();
				await it.user.getStatus();
				it.setMovesCounter(it.user.status.availableStepsCount);
			}
		}
	}

	handleChooseProductLink(target: HTMLElement) {
		// if (it.moves.orders >= it.moves.maxOrders) return;
		const link = target.dataset.href;

		this.analytics.track(EventName.Page_Aliland_TaskList_MakeOrder);
		this.initialData.dataProvider.goLink({ link });
	}

	setUiLinks() {
		if (this.initialData?.staticData) {
			const { promotionPageLink, chatLink, productsLink } = this.initialData.staticData;

			([...$$('.js-get-move-choose-product')] as HTMLElement[]).forEach(
				(link) => (link.dataset.href = productsLink)
			);
			([...$$('.js-link-sale')] as HTMLElement[]).forEach((link) => (link.dataset.href = promotionPageLink));
			([...$$('.js-link-support')] as HTMLElement[]).forEach((link) => (link.dataset.href = chatLink));
		}
	}

	private setPurchaseBlock(task: Task) {
		$('.get-move__block._orders').classList.add('_active');
		$('.get-move__block._orders h4').textContent = task.content.title;
		$('.get-move__block._orders p').textContent = task.content.description;
		$('.get-move__block._orders .js-get-move-choose-product').textContent = task.content.link_title;
		($('.get-move__block._orders .js-get-move-choose-product') as HTMLElement).dataset.href = task.content.link;
		if (task.status === TaskStatus.completed) {
			$('.js-get-move-choose-product').textContent === 'Уже выполнено';
		}
	}

	private setDailyActivityBlock(task: Task) {
		$('.get-move__block._scale').classList.add('_active');
		$('.get-move__block._scale h4').textContent = task.content.title;
		let barsHTML = '';
		for (let index = 0; index < task.content.total_streak_count; index++) {
			const completed = index < task.content.days_streak_count && '_completed';
			barsHTML += `<div class="get-move__scale-section ${completed}"></div>`;
		}
		$('.get-move__scale-bar').innerHTML = barsHTML;
		if (task.status === TaskStatus.completed) {
			$('.get-move__block._scale p').classList.add('_completed');
			$('.get-move__block._scale p').textContent = 'Уже выполнено';
		} else {
			$('.get-move__block._scale p').textContent = task.content.description;
		}
	}

	private setInviteFriendBlock(task: Task) {
		$('.get-move__block._share').classList.add('_active');
		$('.get-move__block._share h4').textContent = task.content.title;
		$('.get-move__block._share p').textContent = task.content.description;
		$('.get-move__block._share .js-get-move-share').textContent = task.content.link_title;
		($('.get-move__block._share .js-get-move-share') as HTMLElement).dataset.href = task.content.link;
		if (task.status === TaskStatus.completed) {
			$('.js-get-move-share').textContent === 'Уже выполнено';
		}
	}

	handleShareBtn(link: string) {
		const it = this;

		it.analytics.track(EventName.Page_Aliland_TaskList_Share_Click);
		it.initialData.dataProvider.shareLink({ link });
		// navigator.clipboard.writeText(link).then(
		// 	() => {
		// 		this.analytics.track(EventName.Page_Aliland_TaskList_Share_Click);
		// 		$('.clipboard').classList.add('_active');
		// 		setTimeout(() => {
		// 			$('.clipboard').classList.remove('_active');
		// 		}, 3000);
		// 	},
		// 	(err) => {
		// 		console.error('Async: Could not copy text: ', err);
		// 	}
		// );
	}
}
